import { useEffect } from "react";
import { NavLink, Switch, Route, Link } from "react-router-dom";

import Team from "./Team";
import Contact from "./Contact";
import TestimonialsGrid from "./TestimonialsGrid";
import Partners from "./Partners";
import Media from "./Media";

import CustomHelmet from "../../components/CustomHelmet";

const About: React.VFC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="About"
        name="About"
        link="/about"
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hand holding lightbulb"
      />
      <div className="home-layout">
        <p className="title">About</p>
        <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
          Our mission is to help test takers prepare for data-related certifications.
        </p>
        <div className="blog" style={{ marginTop: "-0.9em" }}>
          <p>
            We offer training for the{" "}
            <Link to="/resources/cdmp-fundamentals-exam">Certified Data Management Professional Exam</Link> through the{" "}
            <Link to="/products/cdmp-study-plan">90-Day Study Plan</Link>, as well as{" "}
            <Link to="/products/cdmp-Fundamentals-notes">Fundamentals Notes</Link> and{" "}
            <Link to="/products/cdmp-question-sets">Question Sets</Link>. You can receive all our best selling Data
            Strategy study materials via the <Link to="/products/cdmp-fundamentals-bundle">Fundamentals Bundle</Link>.
          </p>
          <p>
            Furthermore, we provide training on <Link to="/products#cippe">Data Privacy</Link>,{" "}
            <Link to="/products#cap">Data Analytics</Link>, and <Link to="/products#data-science">Data Science</Link>{" "}
            and we have a strong team ready to advance your professional development through{" "}
            <Link to="/products/career-coaching">Career Coaching</Link> and{" "}
            <Link to="/products/resume-review">Resume & LinkedIn Review</Link>.
          </p>
        </div>
        <br />

        <div className="buttons">
          <NavLink to="/about/team">
            <button className="btn btn-default">About the Team</button>
          </NavLink>
          <NavLink to="/about/testimonials">
            <button className="btn btn-default">Testimonials</button>
          </NavLink>
          <NavLink to="/about/partners">
            <button className="btn btn-default">Partners</button>
          </NavLink>
          <NavLink to="/about/media">
            <button className="btn btn-default">Media</button>
          </NavLink>
          <NavLink to="/about/contact">
            <button className="btn btn-default">Contact</button>
          </NavLink>
        </div>
        <div className="product-page-divider" style={{ margin: "-2em 0 2em 0" }} />

        <Switch>
          <Route exact path="/about">
            <Team />
          </Route>
          <Route exact path="/about/team">
            <Team />
          </Route>
          <Route exact path="/about/testimonials">
            <TestimonialsGrid />
          </Route>
          <Route exact path="/about/partners">
            <Partners />
          </Route>
          <Route exact path="/about/media">
            <Media />
          </Route>
          <Route exact path="/about/contact">
            <Contact />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default About;
