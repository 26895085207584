import { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { CheckoutContext } from "../../contexts/CheckoutContext";
import CustomHelmet from "../../components/CustomHelmet";

import VisualDescription from "../../components/product-pages/VisualDescription";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import { BestValueIcon } from "../../components/product-card/ProductIcons";

import testimonials from "../../data/testimonials";

import products from "../../data/products";
import payments from "../../data/payments";

import { getSelectedOptions } from "../../helpers/checkoutUtils";

const PRODUCT: productName = "Specialist Bundle";

const product = products.find((product) => product.name === PRODUCT) as productType;
const payment = payments.find((payment) => payment.product === PRODUCT);

interface specialistOptions {
  option: "dg" | "dq" | "dmd" | "mm" | "mrd" | "dii" | "dwbi";
}

export default function CDMPSpecialistBundle() {
  const history = useHistory();

  const [optionOne, setOptionOne] = useState<specialistOptions["option"]>("dg");
  const [optionTwo, setOptionTwo] = useState<specialistOptions["option"]>("dq");

  const checkoutContext = useContext(CheckoutContext) as checkoutContext;
  const { setConfirmationTable } = checkoutContext.checkout;

  function handleChangeOne(event) {
    setOptionOne(event.target.value as specialistOptions["option"]);
  }

  function handleChangeTwo(event) {
    setOptionTwo(event.target.value as specialistOptions["option"]);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleSubmit(event) {
    event.preventDefault();

    const table: confirmationTableRow[] = [
      {
        selected: true,
        paymentName: "specialist-bundle",
        type: "bundle",
        selectedOptions: getSelectedOptions(payment, [optionOne, optionTwo]),
        price: product.price as number,
        metadata: {
          count: 1,
          unit: "",
          abandonedCartList: payment.abandonedCartList,
          discountCode: "",
          discountValue: 0,
          discountAmount: 0,
          shippingAmt: 0,
          taxCode: "",
          taxRate: 0,
        },
      },
    ];
    setConfirmationTable(table);

    history.push("/products/specialist-bundle/checkout");
  }

  let testimonialsToShow = [];

  testimonialsToShow.push(
    testimonials.find((testimonial) => testimonial.id === "specialist-exam-guides-000"),
    testimonials.find((testimonial) => testimonial.id === "specialist-question-sets-000"),
    testimonials.find((testimonial) => testimonial.id === "specialist-exam-guides-002")
  );

  return (
    <>
      <CustomHelmet
        description={product.description}
        name={product.name}
        link={product.link}
        img={product.img}
        alt={product.alt}
      />

      <div className="product-layout">
        <h1 className="title product-title">{product.name}</h1>

        <div className="product-info thirds">
          <div id="product-left-side">
            <div style={{ position: "relative", top: 24 }}>{product.bestValue && <BestValueIcon />}</div>
            <img id="product-img" src={product.img} alt={product.alt} />
            <div className="order-form">
              <form>
                <label htmlFor="options">
                  <p style={{ fontWeight: 800 }}>Options:</p>
                  <select
                    name="exam"
                    value={optionOne}
                    onChange={handleChangeOne}
                    style={{ width: "100%", height: 25.195, marginBottom: 4 }}
                  >
                    <option value="dg">Data Governance</option>
                    <option value="dq">Data Quality</option>
                    <option value="dmd">Data Modeling & Design</option>
                    <option value="mm">Metadata Management</option>
                    <option value="mrd">Master & Reference Data Management</option>
                    <option value="dii">Data Integration & Interoperability</option>
                    <option value="dwbi">Data Warehousing & Business Intelligence</option>
                  </select>
                  <select
                    name="exam"
                    value={optionTwo}
                    onChange={handleChangeTwo}
                    style={{ width: "100%", height: 25.195 }}
                  >
                    <option value="dg" disabled={optionOne === "dg"}>
                      Data Governance
                    </option>
                    <option value="dq" disabled={optionOne === "dq"}>
                      Data Quality
                    </option>
                    <option value="dmd" disabled={optionOne === "dmd"}>
                      Data Modeling & Design
                    </option>
                    <option value="mm" disabled={optionOne === "mm"}>
                      Metadata Management
                    </option>
                    <option value="mrd" disabled={optionOne === "mrd"}>
                      Master & Reference Data Management
                    </option>
                    <option value="dii" disabled={optionOne === "dii"}>
                      Data Integration & Interoperability
                    </option>
                    <option value="dwbi" disabled={optionOne === "dwbi"}>
                      Data Warehousing & Business Intelligence
                    </option>
                  </select>
                </label>
                <br />
                <button className="btn btn-accent" type="submit" id="btn-purchase" onClick={handleSubmit}>
                  Purchase <span style={{ fontFamily: "Roboto", fontWeight: 400 }}>($120)</span>
                </button>
              </form>
            </div>
          </div>

          <div className="gray-box" id="product-description">
            <h4>Description</h4>
            <div className="blog">
              <p>
                Prepare for the{" "}
                <Link to="/resources/cdmp-specialist-exam" target="_blank" rel="noreferrer">
                  CDMP Specialist Exams
                </Link>{" "}
                with two{" "}
                <Link to="/products/specialist-guides" target="_blank" rel="noreferrer">
                  Specialist Guides
                </Link>{" "}
                and two{" "}
                <Link to="/products/specialist-question-sets" target="_blank" rel="noreferrer">
                  Specialist Question Sets
                </Link>
                .
              </p>
              <p>
                Each{" "}
                <Link to="/products/specialist-guides" target="_blank" rel="noreferrer">
                  Specialist Guide
                </Link>{" "}
                goes beyond the associated{" "}
                <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  <em>DMBOK</em>
                </a>{" "}
                chapter to provide a thorough review of the concepts tested on the Specialist Exam. Each guide contains
                a topic summary, recommendations for further reading, real world examples, best practices, and tips from{" "}
                <a href="https://www.facebook.com/groups/346145433213551" target="_blank" rel="noreferrer">
                  CDMP Study Group
                </a>
                .
              </p>
              <p>
                You will also receive two{" "}
                <Link to="/products/specialist-question-sets" target="_blank">
                  Specialist Question Sets
                </Link>
                , which each contain 50 distinct, never-before-seen questions on a specific topic. These questions were
                developed by our team at Data Strategy Professionals. In the answers section of each document, we have
                provided a detailed explanation for each question.
              </p>
            </div>
          </div>

          <div className="teal-box cdmp" id="product-testimonials">
            <h4>You'll receive:</h4>
            <VisualDescription product={PRODUCT} />
            <div style={{ textAlign: "center", marginTop: "3em" }}>
              <p>
                for the two{" "}
                <Link to="/resources/cdmp-specialist-exam" target="_blank" rel="noreferrer">
                  Specialist Exam
                </Link>{" "}
                topics of your choice
              </p>
            </div>
          </div>
        </div>
        <ProductTestimonials testimonialsToShow={testimonialsToShow} />
      </div>
    </>
  );
}
