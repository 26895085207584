import { useEffect } from "react";
import logo from "../img/helmet/products/logo_cdmp.png";

import CustomHelmet from "../components/CustomHelmet";

import { footerStyles } from "../helpers/footerStyles";

export default function CodeOfConduct() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Code of Conduct"
        name="Code of Conduct"
        link="/code-of-conduct"
        img={logo}
        alt="hand holding lightbulb"
      />
      <div className="home-layout">
        <p className="title" style={footerStyles.title}>
          Code of Conduct
        </p>
        <div className="blog">
          <p style={footerStyles.subtitle}>
            Data Strategy Professionals is a community that is supportive to all members.
          </p>
          <p>
            We are dedicated to creating a harassment-free experience for every community member, regardless of
            experience level, country of origin, race, ethnicity, gender, gender identity, sexual orientation, age,
            religion, socioeconomic status, physical appearance, caste, or creed.
          </p>
          <p>
            We do not tolerate harassment of community members in any form. A violation of these rules may result in
            sanction or permanent expulsion at the discretion of the organizer.
          </p>
          <p>
            If you experience any issues related to community conduct, please contact{" "}
            <a
              href="mailto:support@datastrategypros.com?subject=Code%20of%20Conduct%20concern"
              target="_blank"
              rel="noreferrer"
            >
              Support
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
}
