import {
  Drafts,
  MenuBook,
  LaptopChromebook,
  Help,
  EmojiObjects,
  LibraryBooks,
  LibraryAddCheck,
  Search,
  CheckBox,
  Explore,
  PeopleAlt,
  Star,
  Verified,
  LabelImportant,
  LinkedIn,
  Work,
  Videocam,
  Timer,
  EmojiEvents,
  BusinessCenter,
  Speed,
  Place,
} from "@mui/icons-material";

import VideocamIcon from "@mui/icons-material/Videocam";

import localPolice from "../img/icons/localpolice.webp";
import support from "../img/icons/support.webp";

import day1 from "../img/products/cdmp-study-plan/emails/day1.webp";
import notes from "../img/products/notes/ch3_sq.webp";
import q1 from "../img/products/exam-questions/Q1.webp";
import videoLesson from "../img/products/enterprise/video-lesson.webp";
import scr7 from "../img/products/cdmp-exam-readiness-check/scr0.webp";

const visualDescriptions: visualDescription[] = [
  {
    product: "CDMP Study Plan",
    icons: [
      <Drafts style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <LaptopChromebook style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `5-6 emails per week (or all at once with
        <strong>Immediate Access</strong>)`,
      `Guide to reading the 500+ page <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>`,
      `Bonus content like interview questions and case studies`,
    ],
    textDescription: `<p>
    Maximize your <strong>time, energy, and motivation</strong> to become a Data Strategist with this
    study plan composed of <strong>75 emails</strong>.
    </p>
    <p>At the end of 90 days, you'll be fully prepared to ace the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>
    CDMP Fundamentals Exam</a>! On a different timeline to achieve Data Strategy mastery? No problem! Select <strong>Immediate Access</strong>
    to receive access to all the content of the CDMP Study Plan.
    </p>
    <p>Whether you choose the 90-day or Immediate Access option, you'll receive access to all the content of the CDMP Study Plan through our Training Site.</p>
    <br/>`,
    partOf: "CDMP Fundamentals Bundle",
    partOfLink: "/products/cdmp-fundamentals-bundle",
  },
  {
    product: "CDMP Question Sets",
    icons: [
      <Help style={{ fontSize: 50 }} />,
      <LaptopChromebook style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      "<strong>50</strong> new, never-before-seen questions per Question Set",
      `Unlimited attempts in <strong>mock exam</strong> environment`,
      `Detailed <strong>explanation</strong> and <strong>reference</strong> to <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>`,
    ],
    textDescription: `
  <p>Immediately upon purchase, you'll receive access to the practice questions in an exam simulator on our Training Site.
    For your further review, you will also receive the practice questions and answers as a PDF document.</p>
  <p>In both the exam simulator and PDF, the correct answer is explained and the reference to the
    <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a> (chapter and section) is provided.
  </p>`,
    partOf: "CDMP Fundamentals Bundle",
    partOfLink: "/products/cdmp-fundamentals-bundle",
  },
  {
    product: "CDMP Fundamentals Notes",
    icons: [
      <LibraryBooks style={{ fontSize: 50 }} />,
      <LibraryAddCheck style={{ fontSize: 50 }} />,
      <img src={localPolice} style={{ height: 50 }} alt="badge icon" />,
    ],
    descriptions: [
      `30+ page pdf containing notes on the
    <a
      href="https://amzn.to/32oK8hH"
      target="_blank"
      rel="noreferrer"
    >
      <em>DMBOK</em>
    </a>`,
      "Instructions for testing",
      `Guidance on obtaining your badge and
      <a
        href="https://www.dama.org/cpages/dama-international-individual-membership"
        target="_blank"
        rel="noreferrer"
      >
        DAMA membership
      </a>`,
    ],
    textDescription: `<p>
    Fast-track your preparation for the 
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>
    with the CDMP
    Fundamentals Notes. This <strong>30+ page</strong> document covers
    the 14 chapters of the
    <a
      href="https://amzn.to/32oK8hH"
      target="_blank"
      rel="noreferrer"
    >
      <em>DMBOK</em>
    </a>
    tested on the exam.
  </p>
  <p>
    To advance your preparation for the
    <a href='https://www.datastrategypros.com/resources/specialist' target='_blank' rel='noreferrer'>Specialist Exams</a>,
    we offer specific <a href='https://www.datastrategypros.com/products/specialist-exam-guides' target='_blank' rel='noreferrer'>
      Specialist Guides</a>. You may also be interested in the
    <a href='https://www.datastrategypros.com/products/data-strategy-workbook' target='_blank' rel='noreferrer'>
      Data Strategy Workbook
    </a> and
    <a href='https://www.datastrategypros.com/products/document-checklist' target='_blank' rel='noreferrer'>
      Data Strategy Document Checklist
    </a>
    and <a href='https://www.datastrategypros.com/products/document-checklist' target='_blank' rel='noreferrer'>
    Document Checklist</a>
    to enhance the Data Management capabilities of your organization.
  </p>`,
    partOf: "CDMP Fundamentals Bundle",
    partOfLink: "/products/cdmp-fundamentals-bundle",
  },
  {
    product: "CDMP Exam Readiness Check",
    icons: [
      <Search style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
      <CheckBox style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      "Part one: exercises to <strong>identify areas of weakness</strong>",
      "Part two: <br/><strong>20 question</strong> mock exam",
      `Approximate <strong>exam score</strong>`,
    ],
    textDescription: "",
    partOf: "",
    partOfLink: "/products/cdmp-fundamentals-bundle",
  },
  {
    product: "Data Strategy Workbook",
    stack: false,
    icons: [
      <Explore style={{ fontSize: 50 }} />,
      <PeopleAlt style={{ fontSize: 50 }} />,
      <CheckBox style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      "<strong>Roadmap</strong> for creating Data Strategy at your organization",
      "Engage <strong>stakeholders</strong> for meaningful conversations",
      "<strong>20 exercises</strong> to improve every Data Management domain",
    ],
    textDescription: `
    <p>The exercises in this <strong>60+ page workbook</strong> will help you:</p>
    <ul>
      <li>
        Identify <strong>key objectives</strong> in your organization and highlight how data can help you
        achieve them
      </li>
      <li>
        Establish clear <strong>roles and responsibilities</strong> for Data Management and Data
        Governance functions
      </li>
      <li>
        Evaluate current data infrastructure and capabilities, identifying
        <strong>areas for improvement</strong>
      </li>
    </ul>
    `,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "Data Strategy Document Checklist",
    stack: false,
    icons: [
      <CheckBox style={{ fontSize: 50 }} />,
      <EmojiObjects style={{ fontSize: 50 }} />,
      <LibraryBooks style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      "<strong>Comprehensive list</strong> of Data Strategy documents across every domain in the data lifecycle",
      "<strong>Practical definitions</strong> to enhance Data Management capabilities at your organization",
      "Reference page number for additional reading in the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>",
    ],
    textDescription: `<p>Confused about how to implement the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>?
      Look no further than our <strong>Document Checklist</strong> as a guide to operationalize Data Management best practices.</p>
      <p>This guide contains 20 pages of content that provide detailed descriptions of the <strong>inputs</strong> and <strong>deliverables</strong>
      required to run a successful Data Management program.</p>
      <p>
        Support for any questions about the product at
        <a href="mailto:support@datastrategypros.com?subject=Data%20Strategy%20Workbook">
          support@datastrategypros.com
        </a>
      </p>`,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "Specialist Guides",
    stack: true,
    icons: [
      <LibraryBooks style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
      <LaptopChromebook style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Each Guide is a 15+ page pdf containing extensive notes on a specific
      <a
        href="https://amzn.to/32oK8hH"
        target="_blank"
        rel="noreferrer"
      >
        <em>DMBOK</em> chapter
      </a>`,
      "Practice questions and detailed explanations",
      "Additional resources such as further reading, real world examples, and best practices",
    ],
    textDescription: ``,
    // partOf: "Specialist Bundle",
    // partOfLink: "/products/specialist-bundle",
  },
  {
    product: "Specialist Question Sets",
    stack: true,
    icons: [
      <Help style={{ fontSize: 50 }} />,
      <EmojiObjects style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `<strong>50</strong> questions on a specific <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
      <em>DMBOK</em> chapter
    </a>`,
      "Detailed <strong>explanation</strong> of each correct answer",
      `Reference page number in 
      <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
        <em>DMBOK</em>
      </a>`,
    ],
    textDescription: ``,
    // partOf: "Specialist Bundle",
    // partOfLink: "/products/specialist-bundle",
  },
  {
    product: "CAP Study Plan",
    icons: [
      <Drafts style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <LaptopChromebook style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Daily emails (or all the content at once with <a href="https://www.datastrategypros.com/products/cap-study-plan-immediate-access/checkout">Immediate Access</a>)`,
      `Human-friendly <strong>explanations</strong> for challenging concepts`,
      `<strong>Extra resources</strong> for every technical vocab word`,
    ],
    textDescription: `
    <p>Prepare for the
    <a href='https://www.datastrategypros.com/resources/certified-analytics-professional' target='_blank' rel='noreferrer'>
      Certified Analytics Professional (CAP) Exam
    </a>
    with this <strong>65-day comprehensive email course</strong> based on the
    <a href="https://www.certifiedanalytics.org/preparing-exam" target="_blank" rel="noreferrer">
      CAP prep materials
    </a>
    from <a href="https://informs.org/" target="_blank" rel="noreferrer">INFORMS</a>.
    Following this study schedule maintains your focus
    on the most important topics and saves you valuable time.</p>`,
    partOf: "CAP Bundle",
    partOfLink: "/products/cap-bundle",
  },
  {
    product: "CAP Question Sets",
    icons: [
      <Help style={{ fontSize: 50 }} />,
      <EmojiObjects style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      "<strong>50</strong> new, never-before-seen questions per Question Set",
      "Detailed <strong>explanation</strong> of each correct answer",
      "Identified CAP <strong>domain</strong>",
    ],
    textDescription: `
    <p>Immediately upon purchase, you'll receive access to the practice questions in an exam simulator on our Training Site.
    For your further review, you will also receive the practice questions and answers as a PDF document.</p>
    <p>Each Question Set contains 50 distinct, never-before-seen questions,
    developed by our team at Data Strategy Professionals based on the
    <a href="https://www.certifiedanalytics.org/preparing-exam" target="_blank" rel="noreferrer">CAP prep materials</a> to help you
    get ready for the
    <a href='https://www.datastrategypros.com/resources/certified-analytics-professional' target='_blank' rel='noreferrer'>
      Certified Analytics Professional (CAP) Exam</a>.
    </p>`,
    partOf: "CAP Bundle",
    partOfLink: "/products/cap-bundle",
  },
  {
    product: "CAP Bundle",
    stack: true,
    icons: [
      <Drafts style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
      <img src={support} style={{ height: 50 }} alt="support" />,
    ],
    descriptions: [
      `Daily emails to guide your study of the
      <a href="https://www.certifiedanalytics.org/preparing-exam" target="_blank" rel="noreferrer">
        CAP prep materials
      </a>`,
      `<strong>100 practice questions</strong>
      with detailed explanations to test your knowledge of the seven domains of Data Analytics tested on the
      <a href='https://www.datastrategypros.com/resources/certified-analytics-professional' target='_blank' rel='noreferrer'>
      CAP Exam
      </a>`,
      `<p>
        Plus, support for any questions at
        <a href="mailto:support@datastrategypros.com?subject=CAP%20Product%20Bundle">
          support@datastrategypros.com
        </a>
      </p>`,
    ],
    prices: ["<strong>$89</strong>", "<strong>$50</strong>", ""],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "CIPP/E Bundle",
    stack: true,
    icons: [
      <Drafts style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
      <img src={support} style={{ height: 50 }} alt="support" />,
    ],
    descriptions: [
      `<strong>Daily emails for 90 days</strong> to guide your preparation for the CIPP/E exam`,
      `<strong>100 practice questions</strong> with detailed explanations to test your knowledge of GDPR`,
      `<p>
      Plus, support for any questions at
        <a href="mailto:support@datastrategypros.com?subject=CIPPE%20Product%20Bundle">
          support@datastrategypros.com
        </a>
      </p>`,
    ],
    prices: ["<strong>$89</strong>", "<strong>$36</strong>", ""],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "CDMP Fundamentals Bundle",
    stack: true,
    icons: [
      <Drafts style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
      <LibraryAddCheck style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `<strong>75+ emails</strong> delivered either over 90 days or all at once via the Immediate Access option`,
      `<strong>30+ pages</strong> of notes summarizing the key concepts of each chapter`,
      `<strong>150 practice questions</strong> with detailed explanations of each correct answer`,
      `<strong>Two-part online assessment</strong> to ensure you're ready for the exam`,
    ],
    prices: [
      "<strong><s>$115</s><br/>$110</strong>",
      "<strong><s>$45</s><br/>$40</strong>",
      "<strong><s>$75</s><br/>$70</strong>",
      "<strong><s>$15</s><br/>$10</strong>",
    ],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "Specialist Bundle",
    stack: true,
    icons: [<MenuBook style={{ fontSize: 50 }} />, <Help style={{ fontSize: 50 }} />],
    descriptions: [
      `Two +15 page <strong>Specialist Guides</strong>`,
      `Two <strong>Question Sets</strong>, each containing 50 practice questions `,
    ],
    // prices: ["<strong>$35</strong> x 2", "<strong>$25</strong> x 2"],
    prices: ["<strong>$70</strong>", "<strong>$50</strong>"],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "",
    icons: [],
    descriptions: [],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "CDMP Study Program",
    images: [day1, notes, q1, videoLesson, scr7],
    icons: [
      <Drafts style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
      <VideocamIcon style={{ fontSize: 50 }} />,
      <LibraryAddCheck style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `<a href="https://www.datastrategypros.com/products/cdmp-study-plan" target="_blank" rel="noreferrer">Study Plan</a>: 75+ emails delivered over 90 days as a guide to reading the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>`,
      `<a href="https://www.datastrategypros.com/products/cdmp-fundamentals-notes" target="_blank" rel="noreferrer">Fundamentals Notes</a>: 30+ pages summarizing the key concepts of each chapter`,
      `<a href="https://www.datastrategypros.com/products/cdmp-question-sets" target="_blank" rel="noreferrer">Question Sets</a>: 150 practice questions</strong> with detailed explanations`,
      `<strong>Video Lessons</strong>: 3+ hours of pre-recorded content spanning 14 chapters`,
      `<a href="https://www.datastrategypros.com/products/cdmp-exam-readiness-check" target="_blank" rel="noreferrer">Exam Readiness Check</a>: two-part online assessment</strong> to ensure readiness for the exam`,
    ],
    textDescription: `
    <p>
      Ensure you're ready to ace the
      <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank" rel="noreferrer">
        CDMP Fundamentals Exam
      </a>
      with this two part online assessment:
    </p>
    <ul>
      <li>
        <strong>Part 1</strong> walks you through various exercises to help you
        <strong>identify potential areas of weakness</strong>.
      </li>
      <li>
        <strong>Part 2</strong> is a <strong>mock exam</strong> composed of 20 multiple choice questions in
        the style of the CDMP Fundamentals Exam. At the end, you'll receive a
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          projected score
        </span>
        , as well as a pdf that contains an explanation of each correct answer along with the reference
        chapter, section, and page number so you can review the relevant section in the
        <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        .
      </li>
    </ul>
    <p>
  `,
    partOf: "CDMP Fundamentals Bundle",
    partOfLink: "/products/cdmp-fundamentals-bundle",
  },
  {
    product: "Enterprise",
    icons: [
      <VideocamIcon style={{ fontSize: 50 }} />,
      <EmojiObjects style={{ fontSize: 50 }} />,
      <LibraryAddCheck style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `<a href="https://www.datastrategypros.com/landing/enterprise-membership" target="_blank" rel="noreferrer">Enterprise Study Plan</a><br/>Access the tools needed to advance Data Strategy knowledge and skills for your team`,
      `<a href="https://www.datastrategypros.com/landing/enterprise-custom-training" target="_blank" rel="noreferrer">Custom Training</a><br/>Get everyone on the same page when it comes to dealing with data-related challenges`,
      `<a href="https://www.datastrategypros.com/landing/data-strategy-bootcamp"  target="_blank" rel="noreferrer">CDMP Bootcamp</a><br/>Intensive, in-person training on essential Data Strategy concepts`,
    ],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "Career Coaching",
    icons: [
      <Star style={{ fontSize: 50 }} />,
      <Verified style={{ fontSize: 50 }} />,
      <BusinessCenter style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `<strong>Gain confidence</strong>`,
      `Build your <strong>Data Management skills</strong>`,
      `Accelerate your <strong>job search</strong>`,
    ],
    textDescription: `
  <p>
    Are you looking for a <strong>clear pathway</strong> to an impressive <strong> career in data</strong>?
    Looking to <strong>transition</strong> into a data-related job? Receive guidance from one of our <strong>expert Career Coaches</strong>.
  </p>
  <p>We will help you:</p>
  <ul>
    <li><strong>Gain confidence</strong> to build your skills and seek out your ideal position</li>
    <li>Leverage best practices to grow your organization's <strong>Data Management capabilities</strong> and
      become recognized as a leader in your work</li>
    <li><strong>Prepare for certifications</strong> with ease, resolving any doubts or uncertainties you may
      hold</li>
  </ul>
  <p>
    Our Career Coaches are expert data practitioners that can offer friendly guidance on any of the
    challenges you're facing. <strong>Whether you're a newbie</strong> looking to land
    your first job, a <strong>career switcher</strong> trying to figure out if a data career is right for
    you or an <strong>experienced practitioner</strong> trying to solve tough Data Management challenges, we
    look forward to helping you!
  </p>`,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "Resume & LinkedIn Review",
    icons: [
      <LabelImportant style={{ fontSize: 50 }} />,
      <LinkedIn style={{ fontSize: 50 }} />,
      <Work style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `<strong>Action items</strong> to immediately improve your job search`,
      `Tips and tricks to enhance your <strong>LinkedIn presence</strong>`,
      `<strong>Job recommendations</strong> that meet your specifications`,
    ],
    textDescription: `
  
  <h4>Benefits</h4>
  <ul>
    <li>Clear instruction from an experienced professional on how to write <strong>your best resume</strong>
      and LinkedIn profile
    </li>
    <li><strong>1-2 page pdf</strong> that offers detailed guidance, including goals, action items, available
      opportunities, and next steps
    </li>
    <li>Tips and tricks to <strong>modernize your online presence</strong> for a
      <strong>significant boost</strong> to application success
    </li>
  </ul>
  `,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "Data Management Master Class",
    stack: false,
    icons: [
      <Videocam style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `230+ video lessons, plus additional resources`,
      `19 chapters spanning Data Management best practices`,
      `Practice questions to test your knowledge`,
    ],
    textDescription: `
    <p>
    The thorough curriculum will make you a <strong>more effective data practitioner</strong> with knowledge spanning the data lifecycle.
    Each chapter will contribute to your mastery of essential Data Management principles.
    At the conclusion of the course, you will be adept at implementing Data Strategy through your work.</p>
    `,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "CDMP Crash Course",
    stack: false,
    icons: [
      <Videocam style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `97 video lessons with detailed lesson text`,
      `7 chapters covering key content from the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>`,
      `21 practice questions to test your knowledge`,
    ],
    textDescription: `
    <p>This video course will accelerate your preparation for the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a> or
    <a href='https://www.datastrategypros.com/resources/cdmp-specialist-exam' target='_blank' rel='noreferrer'>CDMP Specialist Exams</a>.
    It covers the seven most important chapters of the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>.
    Collectively, these chapters constitute 70% of the content on the CDMP Fundamentals Exam.</p>
    <p>Purchase of the CDMP Crash Course entitles you to a $185 discount on
    <a href='https://www.datastrategypros.com/products/data-management-master-class' target='_blank' rel='noreferrer'>Data Management Master Class</a>,
    which includes content from all 14 chapters on the CDMP.</p>
    `,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "GDPR Essentials Course",
    stack: false,
    icons: [
      <Videocam style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <Help style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `87 video lessons with detailed descriptions`,
      `18 chapters spanning the history and implications of GDPR`,
      `Support for any questions at
      <a href="mailto:support@datastrategypros.com?subject=GDPR%20Essentials%20Course">
        support@datastrategypros.com
      </a>`,
    ],
    textDescription: `
    <p>Advance your understanding of the <strong>European data protection regime</strong> with this comprehensive course on General Data Protection Regulation (GDPR).
    Through 87 thoughtfully crafted <strong>video lessons</strong>, you'll learn how to navigate <strong>Data Privacy</strong> in the EU.</p>
    <p>This course is also excellent preparation for the <a href="https://iapp.org/train/cippe-training/" target="_blank" rel="noreferrer">
    CIPP/E exam</a> from  <a href="https://iapp.org/" target="_blank" rel="noreferrer">IAPP</a>.</p>
    `,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "",
    icons: [],
    descriptions: [],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "CDMP Tutoring",
    icons: [
      <EmojiEvents style={{ fontSize: 50 }} />,
      <Timer style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Replace uncertainty with <strong>confidence</strong>`,
      `<strong>Save time</strong> with effective preparation`,
      `<strong>Improve your understanding</strong> of the <em>DMBOK</em>`,
    ],
    textDescription: ``,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "CDMP Bootcamp (Sep 24 and 25)",
    icons: [
      <Speed style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <PeopleAlt style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Accelerate your preparation for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP exam</a></strong>`,
      `Learn quickly from our <strong>trusted curriculum</strong>`,
      `Engage with <strong>expert instructors</strong> and <strong>peers</strong>`,
    ],
    textDescription: `<p>Our CDMP Bootcamp provides an accelerated option to study each of the 14 chapters of the
    <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a> tested on the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.  Includes access to a <strong>private Slack channel</strong>.</p>
    <p>The two-day CDMP Bootcamp will take place on <strong>Friday, July 19 and Saturday, July 20 from 9am to 4pm ET over Zoom</strong>. We will review the most important concepts from the <em>DMBOK</em>, do extensive practice questions for each chapter, and provide guidance on how to approach the exam.</p>
    <p>Can't join us in person? No problem — check out our <a href='https://www.datastrategypros.com/products/cdmp-crash-course' target='_blank' rel='noreferrer'>CDMP Crash Course</a> with videos designed to prepare you for the test.</p>`,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "DAMA WI CDMP Bootcamp (Sep 24 and 25)",
    icons: [
      <Place style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <PeopleAlt style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Prepare for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP exam</a></strong> in Madison, WI`,
      `Learn quickly from our <strong>trusted curriculum</strong>`,
      `Engage with <strong>expert instructors</strong> and <strong>peers</strong>`,
    ],
    textDescription: `<p>Conducted in partnership with <a href='https://widama.org/' target='_blank' rel='noreferrer'>WI DAMA</a>,
    our in-person CDMP Bootcamp provides an accelerated option to study each of the 14 chapters of the
    <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a> tested on the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.</p>`,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "DAMA GA CDMP Bootcamp (Oct 4 and 5)",
    icons: [
      <Speed style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <PeopleAlt style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Accelerate your preparation with <strong>in-person</strong> or <strong>virtual</strong> instruction`,
      `Learn quickly from our <strong>trusted curriculum</strong>`,
      `Engage with <strong>expert instructors</strong> and <strong>peers</strong>`,
    ],
    textDescription: `<p>Conducted in partnership with <a href='https://www.dama-ga.org/' target='_blank' rel='noreferrer'>DAMA GA</a>,
    our CDMP Bootcamp provides an accelerated option to the
    <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a> for the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.`,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "DAMA WI CDMP Bootcamp (Sep 24 and 25)",
    icons: [
      <Place style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <PeopleAlt style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Prepare for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP exam</a></strong> in Madison, WI`,
      `Learn quickly from our <strong>trusted curriculum</strong>`,
      `Engage with <strong>expert instructors</strong> and <strong>peers</strong>`,
    ],
    textDescription: `<p>Conducted in partnership with <a href='https://widama.org/' target='_blank' rel='noreferrer'>WI DAMA</a>,
    our in-person CDMP Bootcamp provides an accelerated option to study each of the 14 chapters of the
    <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a> tested on the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.</p>`,
    partOf: null,
    partOfLink: null,
  },
  {
    product: "CDMP Small Group Sessions (Q4)",
    icons: [
      <Speed style={{ fontSize: 50 }} />,
      <MenuBook style={{ fontSize: 50 }} />,
      <PeopleAlt style={{ fontSize: 50 }} />,
    ],
    descriptions: [
      `Accelerate your preparation with <strong>virtual</strong> instruction`,
      `Learn quickly from our <strong>trusted curriculum</strong>`,
      `Engage with <strong>expert instruction</strong> and <strong>peers</strong>`,
    ],
    textDescription: `<p>Our virtual study sessions provide an accelerated option to prepare for the
      <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.</p>
      <p>The sessions will take place over Zoom, beginning <strong>Friday, Sep 20</strong>, and will occur <strong>once a week at 12pm ET</strong>
      for 13 weeks, concluding on <strong>Friday, Dec 13</strong>.</p>
      <p>Participants will receive access to the <a href="https://www.datastrategypros.com/products/cdmp-study-plan" target="_blank">CDMP Study Plan</a>
      ($115 value), <strong>140 practice questions</strong> ($70 value), and <strong>sesson recordings</strong>
      through our Training Site, as well as access to a private <strong>Slack channel</strong>.</p>
      <p><em>Note:  the last day to sign up will be Friday, Sep 13 at midnight ET in order to start everyone on the
      <a href="https://www.datastrategypros.com/products/cdmp-study-plan" target="_blank">CDMP Study Plan</a> at the same time.</em></p>`,
    partOf: null,
    partOfLink: null,
  },
];

// `Replace uncertainty with <strong>confidence</strong>`,
// `<strong>Save time</strong> with effective preparation`,
// `<strong>Improve your understanding</strong> of the <em>DMBOK</em>`,

export default visualDescriptions;
