import { Link } from "react-router-dom";
import QuickStart from "./QuickStart";

const CDMPQuickStart = () => {
  return <QuickStart title="CDMP Exam Quick Start" description={quickStartDescription} />;
};

export default CDMPQuickStart;

const quickStartDescription = (
  <div className="blog" style={{ maxWidth: 930 }}>
    <p>
      If you're getting ready for the CDMP, we recommend that you go ahead and{" "}
      <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
        buy the exam
      </a>{" "}
      from DAMA. When you do, you'll get access to their test bank of 200 practice questions. You'll see a few of these
      practice questions again on the exam. To get the best score possible, we recommend holding off on taking the exam
      until you're getting the practice tests 100% correct.
    </p>
    <p>
      The CDMP is an open book test, and we strongly recommend using the{" "}
      <a href="https://technicspub.com/dmbok2/" target="_blank" rel="noreferrer">
        ebook
      </a>{" "}
      copy of the <em>DMBOK</em>. Some questions ask about exact wording from the <em>DMBOK</em>, so being able to use{" "}
      <code>ctrl + f</code> will help you maximize your test score. To access the ebook, we recommend{" "}
      <a href="https://pdfexpert.com/" target="_blank" rel="noreferrer">
        PDF Expert
      </a>{" "}
      for Macs and{" "}
      <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">
        Adobe Reader
      </a>{" "}
      for Windows. Keep in you'll need a second device to view the ebook during the exam.
    </p>
    <p>
      Our{" "}
      <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
        CDMP Fundamentals Bundle
      </Link>{" "}
      includes our best selling study materials and is designed to facilitate self-study using the{" "}
      <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
        <em>DMBOK</em>
      </a>
      . If you prefer a video course, check out the{" "}
      <Link to="/products/cdmp-crash-course" target="_blank">
        CDMP Crash Course
      </Link>
      .
    </p>
    <p>
      Be sure to join the CDMP Study Group on{" "}
      <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
        Facebook
      </a>{" "}
      or{" "}
      <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
        LinkedIn
      </a>
      .
    </p>
    <p>
      And once you're done reading this article, check out <Link to="#related-posts">Related Posts</Link> for free
      practice questions and even more great study tips.
    </p>
  </div>
);
