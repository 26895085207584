import { useState } from "react";
import { Link } from "react-router-dom";

import PartnerProductPage from "../../components/product-pages/PartnerProductPage";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";
import CustomModal from "../../components/CustomModal";
import { Instructors } from "../../components/product-pages/Coaches";

import Shaulis from "../../img/people/jon_shaulis.webp";

import testimonials from "../../data/testimonials";

const PRODUCT = "DAMA WI CDMP Bootcamp (Sep 24 and 25)";

const DAMA_WI_CDMP_Bootcamp = () => {
  const [showCDMPLevelsModal, setShowCDMPLevelsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showCDMPLevelsModal && <CDMPLevelsModal setShowModal={setShowCDMPLevelsModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <PartnerProductPage PRODUCT={PRODUCT} tealBox={tealBox} rest={rest()} purchaseLink="https://widama.org/events" />
    </>
  );
};

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People Are Saying</h4>
    <div style={{ marginTop: "1em" }}>
      <SubtleTestimonialCardFlip
        testimonial={testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-006")}
        productPage
        fontSize={1.05}
      />
    </div>
  </div>
);

const rest = () => {
  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-000"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-002"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-005"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-004"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-007"),
  ];

  return (
    <>
      <Instructors instructors={["John"]} />
      <ProductTestimonials testimonialsToShow={testimonialsToShow} />

      <div className="white-outline-box">
        <h4 className="box-title">Features</h4>
        <div className="center-text-box two-col" id="features">
          <ul>
            <li>
              Instruction on each of the <strong>14 chapters</strong> of the Data Management Body of Knowledge, provided
              roughly in order of priority for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam">CDMP Fundamentals Exam</Link>
            </li>
            <li>
              <strong>Interactive approach</strong> to ensure you're learning best practices from your fellow Bootcamp
              students
            </li>
            <li>
              <strong>Practice questions</strong> throughout to assess your progress and identify any gaps in your
              knowledge
            </li>
            <li>
              <strong>Two mock exams</strong>, one on each day of the Bootcamp, to test your skills
            </li>
            <li>
              <strong>Study tips</strong> to ensure you're maximizing your time and energy as you prepare
            </li>
          </ul>
        </div>
      </div>

      <div className="teal-box">
        <div className="center-text-box oath" style={{ paddingTop: 20, paddingBottom: 10, zIndex: 1 }}>
          <p style={{ zIndex: 1 }}>
            "Since achieving my CDMP, hundreds of people have reached out to network, collaborate, comment, learn, and
            embolden their careers as data professionals. Nicole Janeway Bills of{" "}
            <strong>Data Strategy Professionals</strong> has become a pinnacle in this community. She has done so much
            more than just start a{" "}
            <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
              study group
            </a>
            , she has also compiled helpful resources and responded back to others in the community who have questions
            or need support."
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <p style={{ textAlign: "right" }}>
              —{" "}
              <a
                href="https://jonshaulis.com/index.php/2019/11/26/hurray-i-earned-my-cdmp/#Study_plan"
                target="_blank"
                rel="noreferrer"
              >
                Jon Shaulis
              </a>
              ,<br />
              <em>Enterprise Data Architect</em>
            </p>
            <img
              style={{ borderRadius: "50%", width: 60, height: 60, marginLeft: 20 }}
              src={Shaulis}
              alt="Jon Shaulis"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DAMA_WI_CDMP_Bootcamp;
