import { useEffect } from "react";
import { Link } from "react-router-dom";

import smiling from "../../../img/products/membership/smiling_sq.webp";

import CustomHelmet from "../../../components/CustomHelmet";
import ScheduleACall from "../../../components/ScheduleACall";

export default function DocumentDrafting() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Data Strategy document drafting and review services"
        name="Document Drafting & Review"
        link="/landing/document-drafting-and-review"
        img={smiling}
        alt="two smiling people at a conference table"
      />

      <div className="landing-page" style={{ minHeight: "88vh" }}>
        <div className="landing-page-inner">
          <Link to="/enterprise">
            <span className="landing-page-close-icon">x</span>
          </Link>
          <p className="title landing-page-title">Document Drafting & Review</p>
          <div className="landing-page-divider" style={{ marginTop: "-1.7em" }} />
          <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
            Our objective is to help you develop the best possible Data Strategy documents that unlock the value of data
            assets in support of your organization's mission.
          </p>
          <div className="blog" style={{ fontSize: "1.15em" }}>
            <p>
              We offer a number of different options to help develop data-related documents, policies, and frameworks.
              Our services include:
            </p>
            <ul>
              <li>Document drafting</li>
              <li>Document review</li>
              <li>Development of frameworks and graphics</li>
              <li>Presentations and trainings</li>
            </ul>
          </div>
          <ScheduleACall />
        </div>
      </div>
    </>
  );
}
