export default function CustomModal({ image, alt, handleShowClick }) {
  return (
    <div style={{ width: "100vw", height: "100vh", position: "fixed", top: 0, zIndex: 1001 }} onClick={handleShowClick}>
      <dialog style={{ cursor: "pointer" }}>
        <figure>
          <img src={image} alt={alt} onClick={handleShowClick} />
        </figure>
      </dialog>
    </div>
  );
}
