import { Link } from "react-router-dom";

import gartner from "../img/blog/data-valuation/gartner.webp";
import kantar from "../img/blog/data-valuation/kantar.webp";
import chicago from "../img/blog/data-valuation/chicago.webp";

import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import Contents from "../components/blog/Contents";

const POSTNAME = "valuation";

const Valuation: React.VFC = () => {
  return <Post postName="valuation" rest={<Rest />} />;
};

export default Valuation;

const Rest = () => {
  const contents: Content[] = [
    {
      anchor: "market-based",
      text: "Market-based methods",
      subContents: [
        {
          anchor: "kantar",
          text: "Kantar Brand Study",
        },
      ],
    },
    {
      anchor: "economic",
      text: "Economic methods",
    },
    {
      anchor: "dimensional",
      text: "Dimensional methods",
    },
    {
      anchor: "conclusion",
      text: "Conclusion",
    },
  ];

  return (
    <>
      <p>
        Quantifying the value of data can help an organization to appropriately prioritize data assets, communicate
        return on investment to stakeholders, and garner support for data-related work. However, Data Valuation can be a
        challenging initiative. It is not straightforward to apply traditional valuation approaches to information
        assets.
      </p>
      <PostImage postName={POSTNAME} />
      {/* <p>
            Data has unique characteristics that contribute to the difficulty of quantifying its value. Notably, there
            is both a contextual and temporal component to data's value. These unique characteristics make it difficult
            to apply traditional asset valuation strategies. 
          </p> */}
      <p>
        In fact, there is no standard method for calculating the value of data.{" "}
        <a href="https://www.ifrs.org/issued-standards/list-of-standards/ias-38-intangible-assets/">
          International Accounting Standard 38
        </a>{" "}
        states that any electronic data cannot be capitalized on an auditable financial statement.
      </p>
      <p>
        However, undertaking a Data Valuation initiative can provide an organization with a powerful lens to better
        understand the costs and benefits associated with information assets.
      </p>
      <Contents contents={contents} />
      <p>
        According to researchers Mike Fleckenstein, Ali Obaidi, and Nektaria Tryfona writing for{" "}
        <a href="https://hdsr.mitpress.mit.edu/pub/1qxkrnig/release/1" target="_blank" rel="noreferrer">
          <em>Harvard Data Science Review</em>
        </a>
        , Data Valuation approaches can be grouped into three categories. <a href="#market-based">Market-based</a>{" "}
        methods are focused on the impact of data on the income, costs, or stock value of a company.{" "}
        <a href="#economic">Economic</a> methods assess the value of data in terms of spurring economic development and
        promoting the public good. <a href="#dimensional">Dimensional</a> methods take into account intrinsic
        characteristics of the data to inform the estimate of value.
      </p>
      <p>
        These three approaches can be used in combination to create thought experiments that provide insight into the
        value of data.
      </p>
      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        Market-based methods are focused on the impact of data on the income, costs, or stock value of a company. These
        methods are often used in the context of mergers and acquisitions, where the value of a company is assessed
        based on its data assets.
      </p>
      <p>
        For example, a company that has a large customer database may be valued higher than a company with a smaller
        database. Similarly, a company that has collected a large amount of unique data on its competitors may be valued
        at a premium.
      </p>
      <p>
        Market-based methods can be useful for assessing the value of data in the context of a specific transaction, but
        they may not be as useful for assessing the value of data in a broader context.
      </p>
      <h4 id={contents[0].subContents[0].anchor}>{contents[0].subContents[0].text}</h4>
      <p>
        In the "
        <a
          href="https://edmcouncil.org/announcement/new-data-roi-report-data-as-an-asset/ "
          target="_blank"
          rel="noreferrer"
        >
          Measure, Manage, and Create Value
        </a>
        , " researchers at{" "}
        <a href="https://edmcouncil.org/" target="_blank" rel="noreferrer">
          EDM Council
        </a>{" "}
        used the{" "}
        <a
          href="https://www.kantar.com/north-america/inspiration/brands/what-are-the-most-valuable-global-brands-in-2021"
          target="_blank"
          rel="noreferrer"
        >
          Kantar Brand Study
        </a>{" "}
        as the basis for a market-based approach to data valuation.
      </p>
      <p>
        The Kantar Brand Study is an annual look at the top 100 global brands since 2006. Kantar found that 34.5% of
        unattributed intangible value (i.e., the difference between the market value and the book value of a company) is
        represented by brand value.
      </p>
      <figure>
        <img src={kantar} alt="table of data on valuable brands from 2021" />
        <figcaption>
          Data from{" "}
          <a
            href="https://www.kantar.com/north-america/inspiration/brands/what-are-the-most-valuable-global-brands-in-2021"
            target="_blank"
            rel="noreferrer"
          >
            Kantar
          </a>
          . Analysis from "
          <a
            href="https://edmcouncil.org/announcement/new-data-roi-report-data-as-an-asset/ "
            target="_blank"
            rel="noreferrer"
          >
            Measure, Manage, and Create Value
          </a>
          " report by{" "}
          <a href="https://edmcouncil.org/" target="_blank" rel="noreferrer">
            EDM Council
          </a>
          .
        </figcaption>
      </figure>
      <p>
        In evaluating the implications of the study, the EDM Council assumed that the remaining 65.5% of unattributed
        intangible value could be represented by the following categories: data, innovation, talent, or other factors.
        Taking into account a naive even split between these four categories, the authors hypothesized that 16.25% of
        unattributed intangible value might be attributable to data.
      </p>

      <h2 id={contents[1].anchor}>{contents[1].text}</h2>
      <p>
        Economic methods assess the value of data in terms of spurring economic development and promoting the public
        good. These methods are often used in the context of government policy, where the value of data is assessed
        based on its potential to drive economic growth and improve the quality of life for citizens.
      </p>

      <figure>
        <img src={chicago} alt="park in Chicago with the city skyline in the background" />
        <figcaption>
          Photo by <a href="https://www.pexels.com/@galerieb/">Nancy Bourque</a> on{" "}
          <a href="https://www.pexels.com/photo/river-near-city-buildings-under-cloudy-sky-1209978/">Unsplash</a>
        </figcaption>
      </figure>

      <p>
        For example, the National Recreation and Parks Association (
        <a href="https://www.nrpa.org" target="_blank" rel="noreferrer">
          NRPA
        </a>
        ) uses data-driven benchmarking to inform the process of planning and assessing where parks need to be located
        and which existing parks need to be improved. The{" "}
        <a href="https://www.nrpa.org/publications-research/ParkMetrics/" target="_blank" rel="noreferrer">
          Park Metrics
        </a>{" "}
        benchmarking tool contributed to more than{" "}
        <a
          href="https://www.nrpa.org/contentassets/f568e0ca499743a08148e3593c860fc5/2023-economic-impact-report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          $201 billion in economic activity
        </a>{" "}
        associated with local parks and recreation agencies in the United States in 2021. Working backward from that
        monetary figure to calculate the value generated by Park Metrics would be an example of the economic valuation
        strategy in action.
      </p>

      <p>
        Economic methods can be useful for assessing the value of data in the context of public policy, but they may not
        be as useful for assessing the value of data in a commercial context.
      </p>

      <h2 id={contents[2].anchor}>{contents[2].text}</h2>
      <p>
        Dimensional methods take into account intrinsic characteristics of the data to inform the estimate of value.
        These methods are often used in the context of internal data management, where the value of data is assessed
        based on its quality, timeliness, relevance, popularity, scarcity, contribution to regulatory compliance, etc.
      </p>
      <p>
        For example, a company that has a large amount of high-quality data on its customers may be able to use that
        data to improve customer service, reduce customer churn, and increase customer lifetime value, leading to
        economic benefits for the company.
      </p>
      <p>
        Dimensional methods can be useful for assessing the value of data in the context of internal data management,
        but they may not be as useful for assessing the value of data in a commercial context.
      </p>
      <p>
        For most internal projects, the dimensional approach may be the most useful because it takes into account
        intrinsic characteristics (e.g., data quality, timeliness, relevance, popularity, scarcity, contribution to
        regulatory compliance, etc.) to inform an estimate of the data's value.
      </p>
      <figure>
        <img src={gartner} alt="" />
        <figcaption>
          From "
          <a
            href="https://www.gartner.com/smarterwithgartner/why-and-how-to-value-your-information-as-an-asset"
            target="_blank"
            rel="noreferrer"
          >
            Why and How to Value Your Information as an Asset
          </a>
          " by
          <a href="https://www.gartner.com/en" target="_blank" rel="noreferrer">
            {" "}
            Gartner
          </a>
        </figcaption>
      </figure>
      <p>
        <a
          href="https://www.gartner.com/smarterwithgartner/why-and-how-to-value-your-information-as-an-asset"
          target="_blank"
          rel="noreferrer"
        >
          Gartner
        </a>{" "}
        provides an alternative framing of Data Valuation by grouping dimensional and market-based strategies into
        foundational and financial measures, providing formulas for each of six information valuation methods. For more
        information, as well as formulas associated with each strategy, check out the excellent book{" "}
        <a href="https://amzn.to/45prW57" target="_blank" rel="noreferrer">
          <em>Infonomics</em>
        </a>{" "}
        by Doug Laney.
      </p>
      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <p>Consider these steps to get started with a Data Valuation initiative:</p>
      <ul>
        <li>Connect data assets to specific business outcomes</li>
        <li>Perform Data Valuation on a ranked list of data assets</li>
        <li>Estimate the gap between realized, probable, and potential value</li>
        <li>Track the value of data assets over time</li>
        <li>
          Utilize several different strategies to arrive at value estimates, keeping in mind that different strategies
          will be convincing to different stakeholders
        </li>
      </ul>
      <p>
        While traditional asset valuation methods fall short due to data's unique characteristics, integrating a variety
        of Data Valuation approaches (such as market-based, economic, and dimensional methods) can help an organization
        approach data as an asset.
      </p>
      <p>
        To explore this topic in more detail, you may be interested in our{" "}
        <Link to="/products/data-management-master-class">Data Management Master Class</Link>, which includes a chapter
        on Data Valuation.
      </p>
    </>
  );
};
