export default function TestimonialCardHorizontal({
  testimonial,
  fontSize = 1,
  img,
  quotes = true,
  productPage = false,
}) {
  const testimonialText = productPage ? testimonial.plainText : testimonial.content;

  const colors = ["#1a5e52", "#1a485e", "#2b769a", "#6f8ce8", "#13334f", "#5f7496"];

  return (
    <div
      style={{ width: "100%", background: "white", borderRadius: 4, height: "content", marginBottom: 70 }}
      className="box-shadow"
    >
      <div style={{ maxWidth: 400, display: "flex", justifyContent: "center" }}>
        {img ? (
          <img
            src={img}
            alt="customer"
            style={{
              objectFit: "cover",
              width: 100,
              height: 100,
              borderRadius: "50%",
              marginTop: -60,
              left: "calc(50% - 50px)",
              border: "4px solid white",
            }}
          />
        ) : (
          <div
            style={{
              background: colors[Math.floor(Math.random() * colors.length)],
              objectFit: "cover",
              width: 100,
              height: 100,
              borderRadius: "50%",
              marginTop: -60,
              border: "3px solid white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "white", fontSize: "2em", fontWeight: 600 }}>{testimonial.name[0]}</p>
          </div>
        )}
      </div>

      <div style={{ padding: 20, paddingTop: 20 }}>
        <p
          dangerouslySetInnerHTML={{
            __html: quotes ? `"${testimonialText}"` : testimonialText,
          }}
          style={{ fontSize: `${fontSize}em` }}
        />
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <div>
            <p style={{ textAlign: "right" }}>
              — <span style={{ fontWeight: productPage ? 900 : 400 }}>{testimonial.name}</span>,
            </p>
            <p
              style={{
                textAlign: "right",
                fontStyle: "italic",
              }}
            >
              {testimonial.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
