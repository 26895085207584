import { useState } from "react";
import { Link } from "react-router-dom";

import day1 from "../../img/products/cdmp-study-plan/emails/day1.webp";
import day2 from "../../img/products/cdmp-study-plan/emails/day2.webp";
import day7 from "../../img/products/cdmp-study-plan/emails/day7.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescriptionContainer from "../../components/product-pages/ExtendedDescriptionContainer";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import BuyNowButton from "../../components/product-pages/BuyNowButton";
import FreeTrial from "../../components/product-pages/FreeTrial";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";
import CustomModal from "../../components/CustomModal";

import Shaulis from "../../img/people/jon_shaulis.webp";

import testimonials from "../../data/testimonials";

const PRODUCT = "CDMP Study Plan";

const CDMPStudyPlan = () => {
  const [showCDMPLevelsModal, setShowCDMPLevelsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showCDMPLevelsModal && <CDMPLevelsModal setShowModal={setShowCDMPLevelsModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription(setShowCDMPLevelsModal)}
        rest={rest(handleShowClick)}
      />
    </>
  );
};

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People Are Saying</h4>
    <div style={{ marginTop: "1em" }}>
      <SubtleTestimonialCardFlip
        testimonial={testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-005")}
        productPage
        fontSize={1.05}
      />
    </div>
  </div>
);

const extendedDescription = (setShowCDMPLevelsModal) => {
  const items = [
    {
      title: "Learn More ",
      description: (
        <div className="extended-description">
          <p>
            In the 90-day version of the Study Plan, each week, you'll receive <strong>5-6 emails</strong> covering each
            of the 14 chapters of the <em>Data Management Body of Knowledge</em> (
            <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            ) tested on the{" "}
            <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank" rel="noreferrer">
              Fundamentals Exam
            </a>
            .
          </p>
          <p>
            If you prefer to study at a different pace, you can select{" "}
            <a href="https://www.datastrategypros.com/products/cdmp-study-plan-immediate-access/checkout">
              Immediate Access
            </a>
            .
          </p>
          <p>
            In both the 90-day and Immediate Access Study Plans, you'll receive access to all the content through your
            own unique login to our Training Site.
          </p>
          <p>
            Our scientifically-backed approach leverages the <strong>80/20 principle</strong> to help you study
            efficiently and retain key concepts for the exam (and your Data Management career).
          </p>
          <p>
            Because becoming a Data Strategist is about more than a test score, the CDMP Study Plan provides{" "}
            <strong>real world examples</strong>, <strong>case studies</strong>, and <strong>job prep resources</strong>{" "}
            that will help you advance your career.
          </p>
          <p>
            If you have any questions about the product, please check out the Frequently Asked Questions below or reach
            out to us at <a href="mailto:support@datastrategypros.com">support@datastrategypros.com</a>.
          </p>
          <h4>Benefits</h4>
          <ul>
            <li>Vocabulary and key concepts for each chapter</li>
            <li>Practice questions and exam preparedness checklist</li>
            <li>
              External resources such as thought-provoking articles and videos to advance your knowledge beyond the{" "}
              <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>
            </li>
            <li>Career advancement materials including case studies and interview questions</li>
          </ul>
        </div>
      ),
    },
    {
      title: "Frequently Asked Questions",
      description: (
        <div className="extended-description faq">
          <p>
            <strong>I purchased the Study Plan but I don't see the emails. What should I do?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            If you purchased the 90-day Study Plan, 5-6 emails per week will be sent to the email address you enter
            during checkout. If you would like your emails sent to a different email address, please contact{" "}
            <a href="mailto:support@datastrategypros.com?subject=CDMP%20Study%20Plan%20email%20change%20request">
              Customer Support
            </a>
            .
          </p>

          <p>
            <strong>
              I purchased the 90-day / Immediate Access version of the Study Plan, and I would like to switch. Is that
              possible?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Sure, that's no problem. Please contact{" "}
            <a href="mailto:support@datastrategypros.com?subject=CDMP%20Study%20Plan%20change%20request">
              Customer Support
            </a>{" "}
            and we can switch you to the other version.
          </p>

          <p>
            <strong>
              I've already gone through the 90-day Study Plan once, and now I would like to restart. Can you make that
              happen?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Yes, that's no problem. Please contact{" "}
            <a href="mailto:support@datastrategypros.com?subject=CDMP%20Study%20Plan%20change%20request">
              Customer Support
            </a>{" "}
            and we can put you into the 90-day Study Plan for another round of emails.
          </p>

          <p>
            <strong>
              I purchased the 90-day version of the Study Plan, and I would like to pause my emails for a time. Can you
              help me with this?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>Unfortunately, we don't have a way to pause your emails.</p>

          <p>
            <strong>How does the Immediate Access Study Plan work?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            In the Immediate Access Study Plan, you will not receive emails. Instead, you will get access to the content
            through your unique login to our Training Site so that you can go through the content at your own pace.
          </p>

          <p>
            <strong>How does the Training Site work?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            In both the 90-day and Immediate Access Study Plans, you'll receive access to all the content through your
            own unique login to our Training Site. Login information will be provided by email shortly after purchase.
          </p>

          <p>
            <strong>
              Is this product the same as the{" "}
              <Link to="/products/cdmp-fundamentals-notes" target="_blank">
                CDMP Fundamentals Notes
              </Link>
              ?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            No, these products are distinct in terms of their content, format, and purpose. The{" "}
            <strong>Study Plan</strong> offers 90 days worth of emails providing a structured guide to reading the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            . The{" "}
            <Link to="/products/cdmp-fundamentals-notes" target="_blank">
              Fundamentals Notes
            </Link>
            , by contrast, are a cheatsheet for learning the content on the Exam.
          </p>

          <p>
            <strong>Which chapters are covered in the Study Plan?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The <strong>CDMP Study Plan</strong> covers chapters 1-14 of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            . While all the chapters make for good reading material to deepen your understanding of Data Strategy, only
            chapters 1-14 are tested on the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP Fundamentals Exam
            </Link>
            .
          </p>

          <p>
            <strong>What score should I be aiming to get on the CDMP Fundamentals Exam?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            If you're content with the{" "}
            <span className="fake-link-1" onClick={() => setShowCDMPLevelsModal(true)}>
              Associate certification
            </span>
            , you'll need to score at least a 60%. If you would like to continue to the{" "}
            <span className="fake-link-1" onClick={() => setShowCDMPLevelsModal(true)}>
              Practitioner
            </span>{" "}
            or{" "}
            <span className="fake-link-1" onClick={() => setShowCDMPLevelsModal(true)}>
              Master
            </span>{" "}
            certifications, you must pass with at least a 70% or 80% respectively, and then pass two{" "}
            <Link to="/resources/cdmp-specialist-exam" target="_blank">
              Specialist Exams
            </Link>{" "}
            at those same levels. We offer the{" "}
            <Link to="/products/cdmp-exam-readiness-check" target="_blank">
              CDMP Exam Readiness Check
            </Link>{" "}
            to help you gauge whether you're ready to take the test and attain your goal score.
          </p>

          <p>
            <strong>
              Has the content of the CDMP Study Plan been updated to reflect the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em> v2 Revised
              </a>
              ?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The{" "}
            <a href="https://www.dama.org/cpages/changes-to-dama-dmbok-2" target="_blank" rel="noreferrer">
              revisions
            </a>{" "}
            to the <em>DMBOK</em> will be incorporated into the Study Plan before the changes to the CDMP Fundamentals
            Exam take place in October 2024.
          </p>

          <p>
            <strong>What is the CDMP Study Plan Guarantee?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            In the unlikely event that you do not attain a score of 60%+ on the exam, please email our{" "}
            <a href="mailto:support@datastrategypros.com?subject=CDMP%20Study%20Plan%20Refund">Customer Support team</a>{" "}
            with proof of your score, and we will refund your purchase of the Study Plan.
          </p>

          <p>
            <strong>
              Is the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              included in my purchase of the Study Plan?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            No, you are responsible for purchasing the <em>DMBOK</em> either as a{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              hard copy
            </a>{" "}
            or an{" "}
            <a href="https://technicspub.com/dmbok/" target="_blank" rel="noreferrer">
              ebook
            </a>
            .
          </p>

          <p>
            <strong>
              Can I use the Study Plan without purchasing the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>
              ?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            It is our very strong recommendation that you have a copy of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>{" "}
            on hand for the open book exam.
          </p>
          <p>
            <strong>
              How many hours a day should I budget to study for the exam while following the CDMP Study Plan?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            We recommend one to two hours of study per day over 90 days. You may be able to prepare for the exam more
            quickly if you have background knowledge of Data Management knowledge areas on the test.
          </p>

          <p>
            <strong>
              Can I access the{" "}
              <a href="https://technicspub.com/dmbok/" target="_blank" rel="noreferrer">
                ebook version
              </a>{" "}
              of the <em>DMBOK</em> during the exam?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Yes, but it must be on a separate device, which should be disconnected from the internet. Some test takers
            prefer the ebook because they're able to use ctrl + f to find information more quickly during the exam. For
            more test taking tips, check out the{" "}
            <Link to="/products/cdmp-fundamentals-notes" target="_blank">
              CDMP Fundamentals Notes
            </Link>
            .
          </p>

          <p>
            <strong>What else can I do to prepare for the CDMP?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            We also offer{" "}
            <Link to="/products/cdmp-question-sets" target="_blank">
              CDMP Question Sets
            </Link>{" "}
            to test your knowledge of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            . You can buy each of the three{" "}
            <Link to="/products/cdmp-question-sets" target="_blank">
              Question Sets
            </Link>{" "}
            on their own or together. We also offer the{" "}
            <Link to="/products/cdmp-exam-readiness-check" target="_blank">
              CDMP Exam Readiness Check
            </Link>{" "}
            to help you gauge whether you're ready to take the test and attain your goal score. You can buy all of these
            study materials together in the{" "}
            <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
              CDMP Fundamentals Bundle
            </Link>
            . You may also be interested in the{" "}
            <Link to="/products/cdmp-crash-course" target="_blank">
              CDMP Crash Course
            </Link>{" "}
            and{" "}
            <Link to="/products/data-management-master-class" target="_blank">
              Data Management Master Class
            </Link>{" "}
            video courses, which are not included in the CDMP Fundamentals Bundle, but are also helpful for test prep,
            particularly for preparing quickly and understanding difficult exam materials.
          </p>

          <p>
            <strong>What's next after I finish the CDMP Fundamentals Exam?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Be sure to share your achievement with our team via{" "}
            <a href="mailto:info@datastrategypros.com" target="_blank" rel="noreferrer">
              email
            </a>{" "}
            or on social media (
            <a href="https://www.facebook.com/DataStrategyPros" target="_blank" rel="noreferrer">
              Facebook
            </a>{" "}
            or{" "}
            <a href="https://www.linkedin.com/company/data-strategy-professionals/" target="_blank" rel="noreferrer">
              LinkedIn
            </a>
            ) so we can celebrate your accomplishment with you 🎉 Then check out our{" "}
            <Link to="/products/specialist-bundle" target="_blank">
              CDMP Specialist Bundle
            </Link>{" "}
            to prepare for the{" "}
            <Link to="/resources/cdmp-specialist-exam" target="_blank">
              Specialist Exams
            </Link>
            .
          </p>
          <p>
            <strong>I have another question about the exam that is not listed here.</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            We suggest you check out the official{" "}
            <a href="https://cdmp.info/faqs/" target="_blank" rel="noreferrer">
              CDMP FAQs
            </a>{" "}
            or{" "}
            <a href=" https://cdmp.info/general-help/" target="_blank" rel="noreferrer">
              reach out to DAMA
            </a>{" "}
            for any questions not listed here.
          </p>
        </div>
      ),
    },
  ];
  return <ExtendedDescriptionContainer items={items} />;
};

const rest = (handleShowClick) => {
  const testimonialsToShowOne = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-007"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-006"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-009"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-010"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-012"),
  ];

  const testimonialsToShowTwo = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-000"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-008"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-011"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-017"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-014"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-015"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-016"),
    testimonials.find((testimonial) => testimonial.id === "general-009"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-018"),
  ];

  return (
    <>
      <div
        className="white-outline-box"
        style={{
          maxWidth: 1400,
          marginLeft: "auto",
          marginRight: "auto",
          padding: "30px 40px 32px 40px",
        }}
      >
        <h4 className="box-title">CDMP Study Plan Guarantee</h4>
        <p>
          Our team at Data Strategy Professionals is so confident you'll pass the exam with this plan, that{" "}
          <strong>if you need to retake, we will refund your purchase of the Study Plan</strong>. You can get in touch
          with <a href="mailto:support@datastrategypros.com?subject=CDMP%20Study%20Plan%20Refund">Customer Support</a>{" "}
          for more information.
        </p>
      </div>

      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Screenshots
        </h4>
        <div className="image-display-tri">
          <img
            src={day1}
            alt="email inbox showing emails from 90-day email plan"
            onClick={handleShowClick}
            style={{ cursor: "pointer" }}
          />

          <img
            src={day2}
            alt="day one email"
            onClick={handleShowClick}
            style={{
              objectFit: "scale-down",
              objectPosition: "0 0",
              cursor: "pointer",
            }}
          />

          <img src={day7} alt="data management email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        </div>
      </div>

      <FreeTrial product={PRODUCT} />

      <ProductTestimonials testimonialsToShow={testimonialsToShowOne} />

      <BuyNowButton margins="60px" paymentName={"cdmp-study-plan"} freeTrial={true} />

      <div className="white-outline-box">
        <h4 className="box-title">Features</h4>
        <div className="center-text-box two-col" id="features">
          <ul>
            <li>
              <strong>Study schedule</strong> to finish the{" "}
              <em>
                <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  DMBOK
                </a>
              </em>{" "}
              in 12 weeks
            </li>
            <li>
              Leverage the <strong>80/20 principle</strong> to focus on the important content
            </li>
            <li>
              <strong>Review schedule</strong> to make sure you revisit your notes and lock content into your long term
              memory
            </li>
            <li>
              <strong>Vocabulary and key concepts</strong> for each of the 14 chapters tested on the exam
            </li>
            <li>
              <strong>Study tips</strong> to ensure you're maximizing your time and energy
            </li>
            <li>
              <strong>Practice questions </strong>to test your memory and fix any gaps in your knowledge of Data
              Strategy
            </li>
            <li>
              <strong>Exam preparedness checklist</strong> to make sure you're ready for the test
            </li>
            <li>
              <strong>External resources</strong> such as thought-provoking articles, case studies, and interview
              questions
            </li>
            <li>
              <strong>Support for any questions</strong> in the{" "}
              <a href="https://www.facebook.com/groups/346145433213551" target="_blank" rel="noreferrer">
                CDMP Study Group
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="teal-box">
        <div className="center-text-box oath" style={{ paddingTop: 20, paddingBottom: 10, zIndex: 1 }}>
          <p style={{ zIndex: 1 }}>
            "Since achieving my CDMP, hundreds of people have reached out to network, collaborate, comment, learn, and
            embolden their careers as data professionals. Nicole Janeway Bills of{" "}
            <strong>Data Strategy Professionals</strong> has become a pinnacle in this community. She has done so much
            more than just start a{" "}
            <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
              study group
            </a>
            , she has also compiled helpful resources and responded back to others in the community who have questions
            or need support.
          </p>
          <p>
            "I personally reviewed every email, link, and reference throughout the <strong>CDMP Study Plan</strong> and
            can verify it is a labor of love. The <strong>painstaking detail</strong> in the guide is apparent as is its
            value. If you've chosen to pursue your CDMP, then I'm sure you know it's about much{" "}
            <strong>more than a test score</strong>. Nicole recognizes this, and the study plan also features interview
            questions and thought-provoking external resources like videos, articles, and books related to each chapter.
          </p>
          <p>
            "The <strong>study schedule</strong> is designed to take advantage of primacy and spaced repetition so that
            you can maximize your time and energy while studying. By following this approach, you'll put the most
            important chapters first and <strong>prioritize your review</strong>. The plan also features nine{" "}
            <strong>science-backed study tips</strong> that will help you attain the CDMP and whatever certification you
            choose to pursue next.
          </p>
          <p>
            "Nicole is a knowledgeable and strong resource in the data community, and this guide is definitely a great
            alternative to trying to piece together a study method yourself."
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <p style={{ textAlign: "right" }}>
              —{" "}
              <a
                href="https://jonshaulis.com/index.php/2019/11/26/hurray-i-earned-my-cdmp/#Study_plan"
                target="_blank"
                rel="noreferrer"
              >
                Jon Shaulis
              </a>
              ,<br />
              <em>Enterprise Data Architect</em>
            </p>
            <img
              style={{ borderRadius: "50%", width: 60, height: 60, marginLeft: 20 }}
              src={Shaulis}
              alt="Jon Shaulis"
            />
          </div>
        </div>
      </div>

      <BuyNowButton margins="60px" paymentName={"cdmp-study-plan"} freeTrial={true} />
      <ProductTestimonials testimonialsToShow={testimonialsToShowTwo} showTitle={false} />
    </>
  );
};

export default CDMPStudyPlan;
