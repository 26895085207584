import membership from "../img/products/membership/cheers_sq.webp";
import certifications from "../img/blog/best-certifications.webp";

const leadMagnets = [
  {
    name: "Data Standards",
    description: "Access the tools you need to ace the CDMP Fundamentals Exam and advance your Data Strategy career.",
    img: membership,
    alt: "office workers making a cheer",
    link: "/landing/ksa-data-standards",
    category: "data-strategy",
  },
  {
    name: "Best Certifications",
    description:
      "Aim higher with your career ambitions for 2024. This list of seven top certifications span a variety of data-related domains.",
    img: certifications,
    alt: "person writing in a notebook",
    link: "/landing/best-certifications",
    category: "data-strategy",
  },
];

export default leadMagnets;
