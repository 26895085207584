import { useMediaQuery } from "@mui/material";

import PartnerCard from "../../components/about/PartnerCard";

import partners from "../../data/partners";

const Partners: React.VFC = () => {
  const oneCol = useMediaQuery("(max-width: 640px)");
  const twoCol = useMediaQuery("(max-width: 960px)");
  const threeCol = useMediaQuery("(max-width: 1600px)");

  const colCount = oneCol ? 1 : twoCol ? 2 : threeCol ? 3 : 4;

  const testimonialWidth = oneCol
    ? "calc(100% - 4px)"
    : twoCol
    ? "calc(50% - 12px)"
    : threeCol
    ? "calc(33% - 12px)"
    : "calc(25% - 16px)";

  return (
    <div style={{ overflow: "hidden" }}>
      <p
        style={{
          textAlign: "center",
          fontSize: "1.3em",
          marginTop: 12,
          fontFamily: "Raleway",
          marginBottom: 20,
        }}
      >
        <strong>Partners</strong>
      </p>
      <div
        className="center-text-box"
        style={{
          padding: "60px 6px 0 6px",
          gap: 20,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          height: `calc(2200px / ${colCount})`,
          overflow: "hidden",
        }}
      >
        {partners.map((partner) => (
          <div style={{ width: testimonialWidth }}>
            <PartnerCard partner={partner} />
          </div>
        ))}
        <div />
      </div>
    </div>
  );
};

export default Partners;
