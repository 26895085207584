import { useState } from "react";
import { useMediaQuery } from "@mui/material";

import NameForm from "../sign-up-forms/NameFormContainer";
import EmailForm from "../sign-up-forms/EmailFormContainer";

import ThanksAnimation from "../sign-up-forms/ThanksAnimation";

type LeadMagnetSignupProps = {
  leadMagnetName: string;
  emailList: number;
};

const LeadMagnetSignup = ({ leadMagnetName, emailList }: LeadMagnetSignupProps) => {
  const desktop = useMediaQuery("(min-width:640px)");

  let source = leadMagnetName;
  if (leadMagnetName === "our top certifications report") {
    source = "Best Certifications page";
  }

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch("/.netlify/functions/sendinblue-email-list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        email: email,
        emailList: emailList,
        source,
      }),
    });
    // .then((data) => {
    //   console.log(data);
    // })
    // .catch((error) => {
    //   console.error("Error:", error);
    // });

    setSubmitted(true);
  };

  return (
    <div style={{ margin: "auto", width: desktop ? 500 : "100%" }}>
      <div className="blog">
        <h2 style={{ marginTop: 0, marginBottom: 4 }}>Sign up to receive {leadMagnetName}</h2>
      </div>
      <br />
      <div style={{ maxWidth: 600, marginBottom: "2em" }}>
        {!submitted ? (
          <form onSubmit={handleSubmit}>
            <div>
              <NameForm setFirstName={setFirstName} />
              <EmailForm setEmail={setEmail} setValidEmail={setValidEmail} />
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn btn-accent"
                type="submit"
                style={{
                  width: "200px",
                  padding: "10px 20px",
                  margin: "4px 0",
                }}
                disabled={!firstName || !validEmail}
              >
                Sign Up
              </button>
            </div>
          </form>
        ) : (
          <ThanksAnimation firstName={firstName} email={email} />
        )}
      </div>
    </div>
  );
};

export default LeadMagnetSignup;
