const payments: paymentType[] = [
  {
    name: "data-management-master-class",
    product: "Data Management Master Class",
    type: "course",
    options: [
      {
        optionsLabel: "courseDetails",
        content: [
          {
            label: "data-management-master-class",
            description:
              "This <strong>video course</strong> provides foundational knowledge for Data Strategists across 19 knowledge areas.",
            emailList: 124,
            courseId: ["002-DSE"],
          },
        ],
      },
    ],
    addOns: [
      "data-strategy-workbook",
      "document-checklist",
      "cdmp-fundamentals-bundle",
      "t-shirt",
      "poster",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "career-coaching",
      "resume-review",
    ],
    abandonedCartList: 103,
    price: 44900,
  },
  {
    name: "data-strategy-workbook",
    product: "Data Strategy Workbook",
    type: "download",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "data-strategy-workbook",
            emailList: 43,
            description:
              "Improve the Data Strategy capabilities at your organization through this set of 20+ exercises.",
          },
        ],
      },
    ],
    abandonedCartList: 103,
    addOns: [
      "document-checklist",
      "data-management-master-class",
      "cdmp-fundamentals-bundle",
      "t-shirt",
      "poster",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "career-coaching",
      "resume-review",
    ],
    price: 4900,
  },
  {
    name: "document-checklist",
    product: "Data Strategy Document Checklist",
    type: "download",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "document-checklist",
            emailList: 94,
            description: "A comprehensive list of Data Strategy documents, inputs, and deliverables.",
          },
        ],
      },
    ],
    abandonedCartList: 103,
    addOns: [
      "data-strategy-workbook",
      "data-management-master-class",
      "cdmp-fundamentals-bundle",
      "t-shirt",
      "poster",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "career-coaching",
      "resume-review",
    ],
    price: 1800,
  },
  {
    name: "data-management-master-class-free-trial",
    product: "Data Management Master Class Free Trial",
    type: "course",
    description: `This <strong>video course</strong> offers free access to chapter 2 of the Data Management Master Class
      to provide you with a comprehensive understanding of Data Management Maturity Assessments
      (<a href='https://www.datastrategypros.com/resources/data-management-maturity-assessment-dmma' target='_blank' rel='noreferrer'>DMMA</a>).`,
    options: [
      {
        optionsLabel: "courseDetails",
        content: [
          {
            label: "data-management-master-class-free-trial",
            emailList: 120,
            courseId: ["000-DMMA"],
          },
        ],
      },
    ],
    addOns: [
      "cippe-bundle",
      "gdpr-mind-maps",
      "data-management-master-class",
      "t-shirt",
      "poster",
      "cdmp-fundamentals-bundle",
      "cap-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
    ],
    abandonedCartList: 103,
    price: 0,
  },
  {
    name: "cdmp-bootcamp",
    product: "CDMP Bootcamp (July 19 and 20)",
    type: "course",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-bootcamp",
            displayText: "CDMP Bootcamp",
            description: "Get one-on-one tutoring for the CDMP Fundamentals Exam.",
            emailList: 125,
            price: 35000,
          },
        ],
      },
    ],
    abandonedCartList: 89,
    addOns: [
      "cdmp-fundamentals-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
  },
  {
    name: "cdmp-small-group",
    product: "CDMP Small Group Sessions (Q4)",
    type: "course",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-small-groupp",
            displayText: "CDMP Bootcamp",
            description:
              "Thirteen weeks of Small Group Sessions for the CDMP Fundamentals Exam. Includes access to 13 study sessions, CDMP Study Plan, 140 practice questions, session recordings, and private Slack channel.",
          },
        ],
      },
    ],
    price: 35000,
    abandonedCartList: 89,
    addOns: [
      "cdmp-fundamentals-bundle",
      "data-management-master-class",
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
  },
  {
    name: "cdmp-tutoring",
    product: "CDMP Tutoring",
    type: "service",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-tutoring-single",
            displayText: "Single session",
            description: "Get one-on-one tutoring for the CDMP Fundamentals Exam.",
            emailList: 122,
            price: 8500,
          },
          {
            label: "cdmp-tutoring-package",
            displayText: "Three session package",
            description:
              "Get one-on-one tutoring for the CDMP Fundamentals Exam in the form of this three session package.",
            emailList: 123,
            price: 22500,
          },
        ],
      },
    ],
    abandonedCartList: 89,
    addOns: [
      "cdmp-fundamentals-bundle",
      "cdmp-crash-course",
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
  },
  {
    name: "cdmp-crash-course",
    product: "CDMP Crash Course",
    type: "course",
    options: [
      {
        optionsLabel: "courseDetails",
        content: [
          {
            label: "cdmp-crash-course",
            displayText: "CDMP Crash Course",
            description:
              "Thank you for your purchase of this video course to prepare you for the CDMP Fundamentals Exam.",
            courseId: ["cdmp-crash-course"],
          },
        ],
      },
    ],
    abandonedCartList: 89,
    price: 18500,
    addOns: [
      "cdmp-fundamentals-bundle",
      "specialist-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
  },
  {
    name: "cdmp-fundamentals-bundle",
    product: "CDMP Fundamentals Bundle",
    type: "bundle",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-study-plan-90-days",
            displayText: "CDMP Study Plan 90 Days",
            emailList: 7,
            description: `Maximize your time, energy, and motivation to become a Data Strategist.
              Over <strong>90 days</strong>, you can expect 5-6 emails each week to help you prepare for the CDMP Fundamentals Exam.`,
            courseId: ["001-CDMP-QS", "002-CDMP-QS", "003-CDMP-QS", "cdmp-study-plan"],
          },
          {
            label: "cdmp-study-plan-immediate-access",
            displayText: "CDMP Study Plan Immediate Access",
            emailList: 16,
            description: `Maximize your time, energy, and motivation to become a Data Strategist.
              You'll receive <strong>Immediate Access</strong> to the entire CDMP Study Plan via email.`,
            courseId: ["001-CDMP-QS", "002-CDMP-QS", "003-CDMP-QS", "cdmp-study-plan"],
          },
        ],
      },
    ],
    emailList: 44,
    abandonedCartList: 89,
    addOns: [
      "data-management-master-class",
      "specialist-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
    price: 23000,
  },
  {
    name: "cdmp-study-plan",
    product: "CDMP Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-study-plan-90-days",
            displayText: "90 Days",
            emailList: 7,
            description:
              "Maximize your time, energy, and motivation to become a Data Strategist.\n        Over <strong>90 days</strong>, you can expect 5-6 emails each week to help you prepare for the CDMP Fundamentals Exam.",
            courseId: ["cdmp-study-plan"],
          },
          {
            label: "cdmp-study-plan-immediate-access",
            displayText: "Immediate Access",
            emailList: 16,
            description:
              "Maximize your time, energy, and motivation to become a Data Strategist.\n        You'll receive <strong>Immediate Access</strong> to the entire CDMP Study Plan via email.",
            courseId: ["cdmp-study-plan"],
          },
        ],
      },
    ],
    abandonedCartList: 89,
    type: "sendinblue",
    addOns: [
      "cdmp-fundamentals-notes",
      "cdmp-question-set",
      "cdmp-exam-readiness-check",
      "cdmp-crash-course",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "t-shirt",
      "poster",
    ],
    price: 11500,
  },
  {
    name: "cdmp-study-plan-continuation",
    product: "CDMP Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-study-plan-continuation",
            displayText: "CDMP Study Plan Continuation",
            description:
              "As a thank you for participating in the free trial of the CDMP Study Plan,\n        we're offering you this special price to continue with the email series.  Best of luck on your Data Strategy journey!",
            emailList: 4,
            courseId: ["cdmp-study-plan"],
          },
          {
            label: "cdmp-study-plan-immediate-access",
            displayText: "CDMP Study Plan Immediate Access",
            description:
              "Maximize your time, energy, and motivation to become a Data Strategist.\n        You'll receive <strong>Immediate Access</strong> to the entire CDMP Study Plan via email.",
            emailList: 16,
            courseId: ["cdmp-study-plan"],
          },
        ],
      },
    ],
    type: "sendinblue",
    addOns: [
      "cdmp-fundamentals-notes",
      "cdmp-question-set",
      "cdmp-exam-readiness-check",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "t-shirt",
      "poster",
    ],
    price: 10500,
    abandonedCartList: 89,
  },
  {
    name: "cdmp-study-plan-free-trial",
    product: "CDMP Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-study-plan-free-trial",
            description:
              "Maximize your time, energy, and motivation to become a Data Strategist with this <strong>free trial</strong> of the CDMP Study Plan.",
            emailList: 6,
          },
        ],
      },
    ],
    type: "sendinblue-free-trial",
    price: 0,
    abandonedCartList: 89,
  },
  {
    name: "cdmp-exam-readiness-check",
    product: "CDMP Exam Readiness Check",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-exam-readiness-check",
            description: "Ensure you're ready to ace the CDMP Fundamentals Exam with this two part assessment.",
            emailList: 53,
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "cdmp-study-plan",
      "cdmp-fundamentals-notes",
      "cdmp-question-set",
      "cdmp-crash-course",
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "t-shirt",
      "poster",
    ],
    price: 1500,
    abandonedCartList: 89,
  },
  {
    name: "cdmp-fundamentals-notes",
    product: "CDMP Fundamentals Notes",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cdmp-fundamentals-notes",
            description:
              "Fast-track your preparation for CDMP exams with the <strong>CDMP Fundamentals Notes</strong>.\n        This 30+ page document contains information about the 14 chapters of the <em>DMBOK</em> covered on the exam.",
            emailList: 45,
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "cdmp-study-plan",
      "cdmp-question-set",
      "cdmp-exam-readiness-check",
      "cdmp-crash-course",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "t-shirt",
      "poster",
    ],
    price: 4500,
    abandonedCartList: 89,
  },
  {
    name: "cdmp-question-set",
    product: "CDMP Question Sets",
    options: [
      {
        optionsLabel: "courseDetails",
        content: [
          {
            label: "cdmp-question-sets",
            displayText: "All three sets",
            price: 7500,
            description: "Thank you for your purchase of <strong>all three</strong> CDMP Question Sets.",
            courseId: ["001-CDMP-QS", "002-CDMP-QS", "003-CDMP-QS"],
            emailList: 49,
          },
          {
            label: "cdmp-question-set-1",
            displayText: "CDMP Question Set #1",
            price: 2500,
            description: "Thank you for your purchase of <strong>CDMP Question Set #1</strong>.",
            courseId: ["001-CDMP-QS"],
            emailList: 46,
          },
          {
            label: "cdmp-question-set-2",
            displayText: "CDMP Question Set #2",
            price: 2500,
            description: "Thank you for your purchase of <strong>CDMP Question Set #2</strong>.",
            courseId: ["002-CDMP-QS"],
            emailList: 47,
          },
          {
            label: "cdmp-question-set-3",
            displayText: "CDMP Question Set #3",
            price: 2500,
            description: "Thank you for your purchase of <strong>CDMP Question Set #3</strong>.",
            courseId: ["003-CDMP-QS"],
            emailList: 48,
          },
        ],
      },
    ],
    type: "course",
    addOns: [
      "cdmp-study-plan",
      "cdmp-fundamentals-notes",
      "cdmp-exam-readiness-check",
      "cdmp-crash-course",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "t-shirt",
      "poster",
    ],
    abandonedCartList: 89,
  },
  {
    name: "data-science-email-bootcamp",
    product: "Data Science Email Bootcamp",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "data-science-email-bootcamp-12-weeks",
            displayText: "12 Week Email Bootcamp",
            description:
              "Level up with 3-4 emails per week over 12 weeks providing the resources, articles, videos,\n      and tutorials that will help you advance your Data Science career.",
            emailList: 9,
          },
          {
            label: "data-science-email-bootcamp-immediate-access",
            displayText: "Immediate Access",
            description:
              "You'll receive <strong>Immediate Access</strong> to the resources, articles, videos,\n      and tutorials that will help you advance your Data Science career.",
            emailList: 17,
          },
        ],
      },
    ],
    type: "sendinblue",
    addOns: [
      "cds-question-set",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 2900,
  },
  {
    name: "data-science-email-bootcamp-free-trial",
    product: "Data Science Email Bootcamp",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "data-science-email-bootcamp-free-trial",
            description:
              "Check out the Data Science Email Bootcamp with this <strong>seven day free trial</strong>\n        that provides the resources, articles, videos, and tutorials that will help you advance your Data Science career.",
            emailList: 10,
          },
        ],
      },
    ],
    type: "sendinblue",
    price: 0,
  },
  {
    name: "cap-study-plan",
    product: "CAP Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cap-study-plan-65-days",
            displayText: "65 Days",
            description:
              "Maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.\n        Every day for <strong>65 days</strong>, you'll receive an email to keep you on track with your studies.",
            emailList: 22,
          },
          {
            label: "cap-study-plan-immediate-access",
            displayText: "Immediate Access",
            description:
              "Maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.\n        You'll receive <strong>75+ emails</strong> to keep you on track with your studies.",
            emailList: 23,
          },
        ],
      },
    ],
    type: "sendinblue",
    addOns: [
      "cap-question-set",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 8900,
    abandonedCartList: 91,
  },
  {
    name: "cap-study-plan-continuation",
    product: "CAP Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cap-study-plan-continuation",
            displayText: "CAP Study Plan Continuation",
            description:
              "Continue your progress in the <strong>CAP Study Plan</strong> to\n        maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.\n        Every day for <strong>65 days</strong>, you'll receive an email to keep you on track with your studies.",
            emailList: 29,
          },
          {
            label: "cap-study-plan-immediate-access",
            displayText: "CAP Study Plan Immediate Access",
            description:
              "Maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.\n        You'll receive <strong>75+ emails</strong> to keep you on track with your studies.",
            emailList: 23,
          },
        ],
      },
    ],
    type: "sendinblue",
    addOns: [
      "cap-question-set",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 7900,
    abandonedCartList: 91,
  },
  {
    name: "cap-study-plan-free-trial",
    product: "CAP Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cap-study-plan-free-trial",
            description:
              "Get access to the <strong>first seven days</strong> of the <strong>CAP Study Plan</strong>\n        to maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.\n        Every day for <strong>65 days</strong>, you'll receive an email to keep you on track with your studies.",
            emailList: 26,
          },
        ],
      },
    ],
    type: "sendinblue-free-trial",
    price: 0,
    abandonedCartList: 91,
  },
  {
    name: "cippe-study-plan",
    product: "CIPP/E Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cippe-study-plan-90-days",
            displayText: "CIPP/E Study Plan 90 Days",
            description:
              "Learn the <strong>essentials of GDPR</strong> with daily emails\n      over <strong>90 days</strong> that prepare you to ace the CIPP/E exam from IAPP.",
            emailList: 24,
          },
          {
            label: "cippe-study-plan-immediate-access",
            displayText: "CIPP/E Study Plan Immediate Access",
            description:
              "Learn the <strong>essentials of GDPR</strong> with <strong>immediate access</strong> to study materials \n      that prepare you to ace the CIPP/E exam from IAPP.",
            emailList: 25,
          },
        ],
      },
    ],
    type: "sendinblue",
    addOns: [
      "cippe-question-set",
      "gdpr-mind-maps",
      "cdmp-fundamentals-bundle",
      "cap-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 8900,
    abandonedCartList: 92,
  },
  {
    name: "cippe-study-plan-continuation",
    product: "CIPP/E Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cippe-study-plan-continuation",
            displayText: "CIPP/E Study Plan Continuation",
            description:
              "Continue your progress learning the <strong>essentials of GDPR</strong> with daily emails\n      over <strong>90 days</strong> that prepare you to ace the CIPP/E exam from IAPP.",
            emailList: 30,
          },
          {
            label: "cippe-study-plan-immediate-access",
            displayText: "CIPP/E Study Plan Immediate Access",
            description:
              "Learn the <strong>essentials of GDPR</strong> with <strong>immediate access</strong> to study materials \n      that prepare you to ace the CIPP/E exam from IAPP.",
            emailList: 25,
          },
        ],
      },
    ],
    type: "sendinblue",
    addOns: [
      "cippe-question-set",
      "gdpr-mind-maps",
      "cdmp-fundamentals-bundle",
      "cap-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 7900,
    abandonedCartList: 92,
  },
  {
    name: "cippe-study-plan-free-trial",
    product: "CIPP/E Study Plan",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cippe-study-plan-free-trial",
            description:
              "Get access to the <strong>first week</strong> of the comprehensive CIPP/E Study Plan,\n      your guide to the <strong>essentials of GDPR</strong>.",
            emailList: 31,
          },
        ],
      },
    ],
    type: "sendinblue-free-trial",
    price: 0,
    abandonedCartList: 92,
  },
  {
    name: "gdpr-mind-maps",
    product: "GDPR Mind Maps and Checklists",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "gdpr-mind-maps",
            description: "Master the European Data Privacy regime with these mind maps and checklists.",
            emailList: 70,
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "cippe-bundle",
      "cdmp-fundamentals-bundle",
      "cap-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
    ],
    price: 3000,
    abandonedCartList: 92,
  },
  {
    name: "specialist-bundle",
    product: "Specialist Bundle",
    type: "bundle",
    description: `Prepare for the CDMP Specialist Exams with your choice of <strong>two Specialist Guides</strong> and <strong>two Question Sets</strong>.`,
    options: [
      {
        optionsLabel: "optionOne",
        content: [
          {
            label: "dg",
            displayText: "Data Governance",
          },
          {
            label: "dq",
            displayText: "Data Quality",
          },
          {
            label: "dmd",
            displayText: "Data Modeling & Design",
          },
          {
            label: "mrd",
            displayText: "Master & Reference Data",
          },
          {
            label: "mm",
            displayText: "Metadata",
          },
          {
            label: "dii",
            displayText: "Data Integration & Interoperability",
          },
          {
            label: "dwbi",
            displayText: "Data Warehousing & Business Intelligence",
          },
        ],
      },
      {
        optionsLabel: "optionTwo",
        content: [
          {
            label: "dg",
            displayText: "Data Governance",
          },
          {
            label: "dq",
            displayText: "Data Quality",
          },
          {
            label: "dmd",
            displayText: "Data Modeling & Design",
          },
          {
            label: "mrd",
            displayText: "Master & Reference Data",
          },
          {
            label: "mm",
            displayText: "Metadata",
          },
          {
            label: "dii",
            displayText: "Data Integration & Interoperability",
          },
          {
            label: "dwbi",
            displayText: "Data Warehousing & Business Intelligence",
          },
        ],
      },
    ],
    abandonedCartList: 90,
    addOns: [
      "data-strategy-workbook",
      "document-checklist",
      "t-shirt",
      "poster",
      "career-coaching",
      "resume-review",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
    price: 12000,
  },
  {
    name: "specialist-guide",
    product: "Specialist Guides",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "dg-guide",
            displayText: "Data Governance Guide",
            description: "Thank you for your purchase of the <strong>Data Governance Specialist Guide</strong>.",
            emailList: 54,
          },
          {
            label: "dq-guide",
            displayText: "Data Quality Guide",
            description: "Thank you for your purchase of the <strong>Data Quality Specialist Guide</strong>.",
            emailList: 55,
          },
          {
            label: "dmd-guide",
            displayText: "Data Modeling & Design Guide",
            description:
              "Thank you for your purchase of the <strong>Data Modelling & Design Specialist Guide</strong>.",
            emailList: 56,
          },
          {
            label: "mrd-guide",
            displayText: "Master & Reference Data Guide",
            description:
              "Thank you for your purchase of the <strong>Master & Reference Data Specialist Guide</strong>.",
            emailList: 58,
          },
          {
            label: "mm-guide",
            displayText: "Metadata Guide",
            description: "Thank you for your purchase of the <strong>Metadata Specialist Guide</strong>.",
            emailList: 57,
          },
          {
            label: "dii-guide",
            displayText: "Data Integration & Interoperability Guide",
            description:
              "Thank you for your purchase of the <strong>Data Integration & Interoperability Specialist Guide</strong>.",
            emailList: 59,
          },
          {
            label: "dwbi-guide",
            displayText: "Data Warehousing & Business Intelligence Guide",
            description:
              "Thank you for your purchase of the <strong>Data Warehousing & Business Intelligence Specialist Guide</strong>.",
            emailList: 60,
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "data-strategy-workbook",
      "document-checklist",
      "cdmp-fundamentals-bundle",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
    price: 3500,
    abandonedCartList: 90,
  },
  {
    name: "specialist-question-set",
    product: "Specialist Question Sets",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "dg-questions",
            displayText: "Data Governance Questions",
            description: "Thank you for your purchase of the <strong>Data Governance Specialist Question Set</strong>.",
            emailList: 61,
            courseId: ["DG-QS-001"],
          },
          {
            label: "dq-questions",
            displayText: "Data Quality Questions",
            description: "Thank you for your purchase of the <strong>Data Quality Specialist Question Set</strong>.",
            emailList: 62,
            courseId: ["DQ-QS-001"],
          },
          {
            label: "dmd-questions",
            displayText: "Data Modeling & Design Questions",
            description:
              "Thank you for your purchase of the <strong>Data Modelling & Design Specialist Question Set</strong>.",
            emailList: 63,
            courseId: ["DMD-QS-001"],
          },
          {
            label: "mrd-questions",
            displayText: "Master & Reference Data Questions",
            description:
              "Thank you for your purchase of the <strong>Master & Reference Data Specialist Question Set</strong>.",
            emailList: 65,
            courseId: ["MRD-QS-001"],
          },
          {
            label: "mm-questions",
            displayText: "Metadata Questions",
            description: "Thank you for your purchase of the <strong>Metadata Specialist Question Set</strong>.",
            emailList: 64,
            courseId: ["MM-QS-001"],
          },
          {
            label: "dii-questions",
            displayText: "Data Integration & Interoperability Questions",
            description:
              "Thank you for your purchase of the <strong>Data Integration & Interoperability Specialist Question/strong>.",
            emailList: 66,
            courseId: ["DII-QS-001"],
          },
          {
            label: "dwbi-questions",
            displayText: "Data Warehousing & Business Intelligence Questions",
            description:
              "Thank you for your purchase of the <strong>Data Warehousing & Business Intelligence Specialist Question Set</strong>.",
            emailList: 67,
            courseId: ["DWBI-QS-001"],
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
    ],
    price: 2500,
    abandonedCartList: 90,
  },
  {
    name: "career-coaching",
    product: "Career Coaching",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "career-coaching-single",
            displayText: "Single session",
            description:
              "Reach your professional potential with insights from our Coach. At the conclusion of a 1-hour Zoom call, you will receive a documented action plan to help you accelerate your career.",
            emailList: 68,
            price: 13500,
          },
          {
            label: "career-coaching-package",
            displayText: "Three session package",
            description:
              "Reach your professional potential through a three session package of Career Coaching sessions.",
            emailList: 121,
            price: 37500,
          },
        ],
      },
    ],
    type: "service",
    addOns: [
      "resume-review",
      "data-strategy-workbook",
      "document-checklist",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "t-shirt",
      "poster",
    ],
    abandonedCartList: 93,
  },
  {
    name: "resume-review",
    product: "Resume & LinkedIn Review",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "resume-review",
            description:
              "Our Career Coach will review your <strong>resume</strong> and <strong>LinkedIn profile</strong>\n        to provide you with the best advice for the next step in your career journey.",
            emailList: 69,
          },
        ],
      },
    ],
    type: "service",
    addOns: [
      "career-coaching",
      "data-strategy-workbook",
      "document-checklist",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "t-shirt",
      "poster",
    ],
    price: 8500,
    abandonedCartList: 93,
  },
  {
    name: "mug",
    product: "Data Strategist Mug",
    type: "physical",
    description:
      "Thank you for your purchase of the <strong>Data Strategist mug</strong>.\n            Enjoy your favorite hot beverage with this iconic ceramic mug.",
    options: [
      {
        optionsLabel: "color",
        content: [
          {
            label: "white",
            displayText: "White",
          },
          {
            label: "black",
            displayText: "Black",
          },
        ],
      },
      {
        optionsLabel: "size",
        content: [
          {
            label: "11-oz",
            displayText: "11 oz",
            price: 750,
          },
          {
            label: "15-oz",
            displayText: "15 oz",
            price: 1000,
          },
        ],
      },
    ],
    addOns: [
      "t-shirt",
      "poster",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "career-coaching",
      "resume-review",
    ],
    taxCode: "txcd_99999999",
    abandonedCartList: 104,
  },
  {
    name: "cap-bundle",
    product: "CAP Bundle",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cap-study-plan-65-days",
            displayText: "CAP Study Plan 65 Days",
            description:
              "Maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.\n        Every day for <strong>65 days</strong>, you'll receive an email to keep you on track with your studies.",
            courseId: ["001-CAP-QS", "002-CAP-QS"],
            emailList: 22,
          },
          {
            label: "cap-study-plan-immediate-access",
            displayText: "CAP Study Plan Immediate Access",
            description:
              "Maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.\n        You'll receive <strong>75+ emails</strong> to keep you on track with your studies.",
            courseId: ["001-CAP-QS", "002-CAP-QS"],
            emailList: 23,
          },
        ],
      },
    ],
    emailList: 50,
    type: "bundle",
    addOns: [
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 13900,
    abandonedCartList: 91,
  },
  {
    name: "cap-question-set",
    product: "CAP Question Sets",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cap-question-sets",
            displayText: "Both Question Sets",
            price: 5000,
            description: "Thank you for your purchase of <strong>both CAP Question Sets</strong>.",
            courseId: ["001-CAP-QS", "002-CAP-QS"],
            emailList: 73,
          },
          {
            label: "cap-question-set-1",
            displayText: "CAP Question Set #1",
            price: 2500,
            description:
              "Thank you for your purchase of <strong>CAP Question Set #1</strong>.\n        You'll receive <strong>50 questions</strong> with <strong>detailed explanations</strong>.",
            courseId: ["001-CAP-QS"],
            emailList: 71,
          },
          {
            label: "cap-question-set-2",
            displayText: "CAP Question Set #2",
            price: 2500,
            description:
              "Thank you for your purchase of <strong>CAP Question Set #2</strong>.\n        You'll receive <strong>50 questions</strong> with <strong>detailed explanations</strong>.",
            courseId: ["002-CAP-QS"],
            emailList: 72,
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "cap-study-plan",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 1800,
    abandonedCartList: 91,
  },
  {
    name: "cds-question-set",
    product: "Data Science Practice Questions",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cds-question-sets",
            displayText: "Both Question Sets",
            price: 3600,
            description: "Thank you for your purchase of the <strong>Data Science Practice Questions</strong>.",
            emailList: 76,
          },
          {
            label: "cds-question-set-1",
            displayText: "Practice Questions #1",
            price: 1800,
            description: "Thank you for your purchase of the <strong>Data Science Practice Questions</strong>.",
            emailList: 74,
          },
          {
            label: "cds-question-set-2",
            displayText: "Practice Questions #2",
            price: 1800,
            description: "Thank you for your purchase of the <strong>Data Science Practice Questions</strong>.",
            emailList: 75,
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "data-science-email-bootcamp",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
  },
  {
    name: "cippe-bundle",
    product: "CIPP/E Bundle",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cippe-study-plan-90-days",
            displayText: "CIPP/E Study Plan 90 Days",
            description:
              "Learn the <strong>essentials of GDPR</strong> with daily emails\n      over <strong>90 days</strong> that prepare you to ace the CIPP/E exam from IAPP.",
            emailList: 24,
          },
          {
            label: "cippe-study-plan-immediate-access",
            displayText: "CIPP/E Study Plan Immediate Access",
            description:
              "Learn the <strong>essentials of GDPR</strong> with <strong>immediate access</strong> to study materials \n      that prepare you to ace the CIPP/E exam from IAPP.",
            emailList: 25,
          },
        ],
      },
    ],
    emailList: 51,
    type: "bundle",
    addOns: [
      "gdpr-mind-maps",
      "cdmp-fundamentals-bundle",
      "cap-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 12500,
    abandonedCartList: 92,
  },
  {
    name: "cippe-question-set",
    product: "CIPP/E Question Sets",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "cippe-question-sets",
            displayText: "Both Question Sets",
            description: "Thank you for your purchase of <strong>both CIPP/E Question Sets</strong>.",
            emailList: 79,
          },
          {
            label: "cippe-question-set-1",
            displayText: "CIPP/E Question Set #1",
            description: "Thank you for your purchase of the <strong>CIPP/E Question Sets</strong>.",
            emailList: 77,
          },
          {
            label: "cippe-question-set-2",
            displayText: "CIPP/E Question Set #2",
            description: "Thank you for your purchase of the <strong>CIPP/E Question Sets</strong>.",
            emailList: 78,
          },
        ],
      },
    ],
    type: "download",
    addOns: [
      "cippe-study-plan",
      "gdpr-mind-maps",
      "cdmp-fundamentals-bundle",
      "cap-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 1800,
    abandonedCartList: 92,
  },
  {
    name: "gdpr-essentials-course",
    product: "GDPR Essentials Course",
    type: "course",
    description: "This <strong>video course</strong> will provide you with a comprehensive understanding of GDPR.",
    options: [
      {
        optionsLabel: "courseDetails",
        content: [
          {
            label: "gdpr-essentials-course",
            courseId: ["001-GDPR"],
          },
        ],
      },
    ],
    addOns: [
      "cippe-bundle",
      "gdpr-mind-maps",
      "data-management-master-class",
      "t-shirt",
      "poster",
      "cdmp-fundamentals-bundle",
      "cap-bundle",
      "data-science-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
    ],
    abandonedCartList: 92,
    price: 24900,
  },

  {
    name: "data-science-bundle",
    product: "Data Science Bundle",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "data-science-email-bootcamp-12-weeks",
            displayText: "Email Bootcamp 12 Weeks",
            description:
              "Level up with 3-4 emails over 12 weeks providing the resources, articles, videos,\n      and tutorials that will help you advance your Data Science career.",
            emailList: 9,
          },
          {
            label: "data-science-email-bootcamp-immediate-access",
            displayText: "Email Bootcamp Immediate Access",
            description:
              "You'll receive <strong>Immediate Access</strong> to the resources, articles, videos,\n      and tutorials that will help you advance your Data Science career.",
            emailList: 17,
          },
        ],
      },
    ],
    emailList: 52,
    type: "bundle",
    addOns: [
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-strategy-workbook",
      "document-checklist",
      "career-coaching",
      "resume-review",
      "t-shirt",
      "poster",
    ],
    price: 6500,
  },
  {
    name: "enterprise-study-program",
    product: "CDMP Study Program",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "enterprise-study-program",
            description: "Your team will receive access instruction on <strong>Data Strategy best practices</strong>.",
            emailList: 80,
          },
        ],
      },
    ],
    type: "service",
    price: 39900,
    abandonedCartList: 39,
  },
  {
    name: "poster",
    product: "Foundations of Data Strategy Poster",
    type: "physical",
    description:
      "This <strong>24 x 36 inch poster</strong> covers the focus areas of Data Governance, Data Quality,\n            Data Architecture & Modeling, Master & Reference Data, and Data Warehousing & Business Intelligence.",
    options: [
      {
        optionsLabel: "color",
        content: [
          {
            label: "teal",
            displayText: "Teal",
          },
          {
            label: "gray",
            displayText: "Gray",
          },
        ],
      },
      {
        optionsLabel: "size",
        content: [
          {
            label: "regular",
            displayText: "18 x 24 inch",
            price: 1200,
          },
          {
            label: "large",
            displayText: "24 x 36 inch",
            price: 2400,
          },
        ],
      },
    ],
    addOns: [
      "t-shirt",
      "mug",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "career-coaching",
      "resume-review",
    ],
    taxCode: "txcd_99999999",
    abandonedCartList: 104,
  },
  {
    name: "t-shirt",
    product: "Data Strategist T-Shirt",
    type: "physical",
    description: "Thank you for your purchase of the <strong>Data Strategist t-shirt</strong>.",
    options: [
      {
        optionsLabel: "type",
        content: [
          {
            label: "regular",
            displayText: "Regular",
          },
          {
            label: "v-neck",
            displayText: "V-neck",
          },
        ],
      },
      {
        optionsLabel: "color",
        content: [
          {
            label: "light-gray",
            displayText: "Light gray",
          },
          {
            label: "dark-gray",
            displayText: "Dark gray",
          },
        ],
      },
      {
        optionsLabel: "size",
        content: [
          {
            label: "small",
            displayText: "Small",
          },
          {
            label: "medium",
            displayText: "Medium",
          },
          {
            label: "large",
            displayText: "Large",
          },
          {
            label: "x-large",
            displayText: "X-Large",
          },
          {
            label: "xx-large",
            displayText: "2X-Large",
          },
          {
            label: "xxx-large",
            displayText: "3X-Large",
          },
        ],
      },
    ],
    addOns: [
      "poster",
      "mug",
      "cdmp-fundamentals-bundle",
      "cippe-bundle",
      "cap-bundle",
      "data-science-bundle",
      "career-coaching",
      "resume-review",
    ],
    taxCode: "txcd_99999999",
    price: 2000,
    abandonedCartList: 104,
  },
  {
    name: "test-product1",
    product: "Test Product",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "test-product1",
            displayText: "Test Product",
            description:
              "This test bundle will clean your gut biome, make your home spotless, and win friends and influence people. Plus, it smells great!",
          },
        ],
      },
    ],
    type: "physical",
    price: 50,
    abandonedCartList: 104,
  },
  {
    name: "test-product2",
    product: "Test Product",
    options: [
      {
        optionsLabel: "emailList",
        content: [
          {
            label: "test-product2",
            displayText: "Test Product",
            description: "This test bundle can keep 50 liters of water in a 45 liter bag.",
          },
        ],
      },
    ],
    type: "physical",
    price: 50,
    abandonedCartList: 104,
  },
];

export default payments;
