const emailLists: emailListType[] = [
  {
    name: "data-management-master-class-free-trial",
    payment: "data-management-master-class-free-trial",
    description: `Free access to chapter 2 of the Data Management Master Class <strong>video course</strong>, which will
    provide you with a comprehensive understanding of Data Management Maturity Assessments
    (<a href='https://www.datastrategypros.com/resources/data-management-maturity-assessment-dmma' target='_blank' rel='noreferrer'>DMMA</a>).`,
    text: `
    <ul>
      <li>7 video lessons introducing the concept of Data Management Maturity Assessments
      (<a href='https://www.datastrategypros.com/resources/data-management-maturity-assessment-dmma' target='_blank' rel='noreferrer'>DMMA</a>),
      followed by 7 video lessons comparing different DMMA approaches</li>
      <li>Links to additional resources to further your knowledge</li>
      <li>Practice questions to check your understanding</li>
    </ul>`,
  },
  {
    name: "data-strategy-workbook",
    payment: "data-strategy-workbook",
    description: "Improve the Data Strategy capabilities at your organization through this set of 20+ exercises.",
  },
  {
    name: "document-checklist",
    payment: "document-checklist",
    description: "A comprehensive list of Data Strategy documents.",
  },
  {
    name: "cdmp-tutoring-single",
    payment: "cdmp-tutoring",
    description: "Enhance your understanding of the DMBOK with insights from CDMP Tutor.",
  },
  {
    name: "cdmp-tutoring-package",
    payment: "cdmp-tutoring",
    description:
      "Enhance your understanding of the DMBOK with insights from CDMP Tutor through this three session package.",
  },
  {
    name: "cdmp-exam-readiness-check",
    payment: "cdmp-exam-readiness-check",
    description: "Ensure you're ready to ace the CDMP Fundamentals Exam with this two part assessment.",
  },
  {
    name: "cdmp-fundamentals-bundle",
    payment: "cdmp-fundamentals-bundle",
    description: `Prepare for the CDMP Fundamentals Exam with all our best selling study materials.`,
  },
  {
    name: "cdmp-study-plan-90-days",
    payment: "cdmp-study-plan",
    description: `Maximize your time, energy, and motivation to become a Data Strategist.
      Over <strong>90 days</strong>, you can expect 5-6 emails each week to help you prepare for the CDMP Fundamentals Exam.`,
  },

  {
    name: "cdmp-study-plan-immediate-access",
    payment: "cdmp-study-plan",
    description: `Maximize your time, energy, and motivation to become a Data Strategist.
      You'll receive <strong>Immediate Access</strong> to the entire CDMP Study Plan via email.`,
  },
  {
    name: "cdmp-study-plan-continuation",
    payment: "cdmp-study-plan-continuation",
    description: `As a thank you for participating in the free trial of the CDMP Study Plan,
      we're offering you this special price to continue with the email series.  Best of luck on your Data Strategy journey!`,
  },
  {
    name: "cdmp-study-plan-free-trial",
    payment: "cdmp-study-plan-free-trial",
    description:
      "Maximize your time, energy, and motivation to become a Data Strategist with this <strong>free trial</strong> of the CDMP Study Plan.",
    text: `<p>Over the next seven days, you will learn...</p>
    <ul>
      <li>Essential information about test taking to ensure you're prepared for the
        <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>
      </li>
      <li>How to structure your reading to finish the
          <em>
            <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              DMBOK
            </a>
          </em>
        in 12 weeks
      </li>
      <li>Fundamentals of Data Management, ch. 1 of the <em>DMBOK</em>,
        including vocabulary and key concepts
      </li>
      <li>Study tips to ensure you're maximizing your time and energy
      </li>
      <li>How to broaden your knowledge of Data Management through thought-provoking articles, case studies, and interview questions
      </li>
    </ul>`,
  },
  {
    name: "data-science-email-bootcamp-12-weeks",
    payment: "data-science-email-bootcamp",
    description: `Level up with 3-4 emails over 12 weeks providing the resources, articles, videos,
    and tutorials that will help you advance your Data Science career.`,
  },
  {
    name: "data-science-email-bootcamp-immediate-access",
    payment: "data-science-email-bootcamp",
    description: `You'll receive <strong>Immediate Access</strong> to the resources, articles, videos,
    and tutorials that will help you advance your Data Science career.`,
  },
  {
    name: "data-science-email-bootcamp-free-trial",
    payment: "data-science-email-bootcamp-free-trial",
    description: `Check out the Data Science Email Bootcamp with this <strong>seven day free trial</strong>
      that provides the resources, articles, videos, and tutorials that will help you advance your Data Science career.`,
    text: `<p>Over the next seven days, you will learn...</p>
    <ul>
    <li>The responsibilities of a Data Scientist</li>
    <li>The mathematical concepts you need to be familiar with</li>
    <li>Which computer science languages you need to know</li>
    <li>How to use Python and SQL to analyze data</li>
    <li>
      What Data Scientists need to understand about
      <a href='https://www.datastrategypros.com/resources/foundations' target='_blank' rel='noreferrer'>
        Data Strategy
      </a>
    </li>
    <li>Essential concepts in Machine Learning</li>
    <li>Tips and tricks to advance your career</li>
  </ul>
  `,
  },
  {
    name: "cap-study-plan-65-days",
    payment: "cap-study-plan",
    description: `Maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.
      Every day for <strong>65 days</strong>, you'll receive an email to keep you on track with your studies.`,
  },
  {
    name: "cap-study-plan-immediate-access",
    payment: "cap-study-plan",
    description: `Maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.
      You'll receive <strong>75+ emails</strong> to keep you on track with your studies.`,
  },
  {
    name: "cap-study-plan-continuation",
    payment: "cap-study-plan-continuation",
    description: `Continue your progress in the <strong>CAP Study Plan</strong> to
      maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.
      Every day for <strong>65 days</strong>, you'll receive an email to keep you on track with your studies.`,
  },
  {
    name: "cap-study-plan-free-trial",
    payment: "cap-study-plan-free-trial",
    description: `Get access to the <strong>first seven days</strong> of the <strong>CAP Study Plan</strong>
      to maximize your time, energy, and motivation to become a <strong>Data Analyst</strong>.
      Every day for <strong>65 days</strong>, you'll receive an email to keep you on track with your studies.`,
    text: `<p>Over the next seven days, you will learn...</p>
    <ul>
      <li>Essential information about test taking to ensure you're prepared for the
        <a href='https://www.datastrategypros.com/resources/certified-analytics-professional' target='_blank' rel='noreferrer'>CAP exam</a>
      </li>
      <li>How to structure your reading to finish the official CAP Study Guide from INFORMS in 65 days
      </li>
      <li>How to set up your first seven days of reading and studying the most important and most technical topic on the CAP exam, Data
      </li>
      <li>How to identify and prioritize data needs and resources, identify means of data collection and acquisition,
        recognize the statistical distribution of a dataset, differentiate between various types of regressions to better understand your data,
        and use transformations to improve data quality and process efficiency
      </li>
      <li>How to supplement your reading with hand-selected external resources such as specific YouTube videos and blog articles that
        explain each technical vocabulary term in the most memorable and easy-to-understand way
      </li>
    </ul>`,
  },
  {
    name: "cippe-study-plan-90-days",
    payment: "cippe-study-plan",
    description: `Learn the <strong>essentials of GDPR</strong> with daily emails
    over <strong>90 days</strong> that prepare you to ace the CIPP/E exam from IAPP.`,
  },
  {
    name: "cippe-study-plan-immediate-access",
    payment: "cippe-study-plan",
    description: `Learn the <strong>essentials of GDPR</strong> with <strong>immediate access</strong> to study materials 
    that prepare you to ace the CIPP/E exam from IAPP.`,
  },
  {
    name: "cippe-study-plan-continuation",
    payment: "cippe-study-plan-continuation",
    description: `Continue your progress learning the <strong>essentials of GDPR</strong> with daily emails
    over <strong>90 days</strong> that prepare you to ace the CIPP/E exam from IAPP.`,
  },
  {
    name: "cippe-study-plan-free-trial",
    payment: "cippe-study-plan-free-trial",
    description: `Get access to the <strong>first week</strong> of the comprehensive CIPP/E Study Plan,
    your guide to the <strong>essentials of GDPR</strong>.`,
    text: `<p>Over the next seven days, you will learn...</p>
    <ul>
      <li>Essential information about test taking to ensure you're prepared for the CIPP/E Exam
      </li>
      <li>How to structure your studies to comprehensively cover the material you need to know to pass the exam
      </li>
      <li>How to make effective flashcards to study for the CIPP/E
      </li>
      <li>How to save time by devoting your focus to the most important content
      </li>
      <li>An introduction to GDPR, including the rationale for Data Protection, the history of Human Rights laws,
        and fundamental data protection concepts such as sensitive, pseudonymous, and anonymous data
      </li>
    </ul>`,
  },
  {
    name: "gdpr-mind-maps",
    payment: "gdpr-mind-maps",
    description: `Master the European Data Privacy regime with these mind maps and checklists.`,
  },
  {
    name: "cdmp-fundamentals-notes",
    payment: "cdmp-fundamentals-notes",
    description: `Fast-track your preparation for CDMP exams with the <strong>CDMP Fundamentals Notes</strong>.
      This 30+ page document contains information about the 14 chapters of the <em>DMBOK</em> covered on the exam.`,
  },
  {
    name: "cdmp-question-set-1",
    payment: "cdmp-question-set",
    description: "Thank you for your purchase of <strong>CDMP Question Set #1</strong>.",
  },
  {
    name: "cdmp-question-set-2",
    payment: "cdmp-question-set",
    description: "Thank you for your purchase of <strong>CDMP Question Set #2</strong>.",
  },
  {
    name: "cdmp-question-set-3",
    payment: "cdmp-question-set",
    description: "Thank you for your purchase of <strong>CDMP Question Set #3</strong>.",
  },
  {
    name: "cdmp-question-sets",
    payment: "cdmp-question-set",
    description: "Thank you for your purchase of <strong>all three</strong> CDMP Question Sets.",
  },
  {
    name: "specialist-bundle",
    payment: "specialist-bundle",
    description: `Prepare for the CDMP Specialist Exams with your choice of <strong>two Specialist Guides</strong> and <strong>two Question Sets</strong>.`,
  },
  {
    name: "dg-guide",
    payment: "specialist-guide",
    description: "Thank you for your purchase of the <strong>Data Governance Specialist Guide</strong>.",
  },
  {
    name: "dq-guide",
    payment: "specialist-guide",
    description: "Thank you for your purchase of the <strong>Data Quality Specialist Guide</strong>.",
  },
  {
    name: "dmd-guide",
    payment: "specialist-guide",
    description: "Thank you for your purchase of the <strong>Data Modelling & Design Specialist Guide</strong>.",
  },
  {
    name: "mm-guide",
    payment: "specialist-guide",
    description: "Thank you for your purchase of the <strong>Metadata Specialist Guide</strong>.",
  },
  {
    name: "mrd-guide",
    payment: "specialist-guide",
    description: "Thank you for your purchase of the <strong>Master & Reference Data Specialist Guide</strong>.",
  },
  {
    name: "dii-guide",
    payment: "specialist-guide",
    description:
      "Thank you for your purchase of the <strong>Data Integration & Interoperability Specialist Guide</strong>.",
  },
  {
    name: "dwbi-guide",
    payment: "specialist-guide",
    description:
      "Thank you for your purchase of the <strong>Data Warehousing & Business Intelligence Specialist Guide</strong>.",
  },
  {
    name: "dg-questions",
    payment: "specialist-question-set",
    description: "Thank you for your purchase of the <strong>Data Governance Specialist Question Set</strong>.",
  },
  {
    name: "dq-questions",
    payment: "specialist-question-set",
    description: "Thank you for your purchase of the <strong>Data Quality Specialist Question Set</strong>.",
  },
  {
    name: "dmd-questions",
    payment: "specialist-question-set",
    description: "Thank you for your purchase of the <strong>Data Modelling & Design Specialist Question Set</strong>.",
  },
  {
    name: "mm-questions",
    payment: "specialist-question-set",
    description: "Thank you for your purchase of the <strong>Metadata Specialist Question Set</strong>.",
  },
  {
    name: "mrd-questions",
    payment: "specialist-question-set",
    description: "Thank you for your purchase of the <strong>Master & Reference Data Specialist Question Set</strong>.",
  },
  {
    name: "dii-questions",
    payment: "specialist-question-set",
    description:
      "Thank you for your purchase of the <strong>Data Integration & Interoperability Specialist Question/strong>.",
  },
  {
    name: "dwbi-questions",
    payment: "specialist-question-set",
    description:
      "Thank you for your purchase of the <strong>Data Warehousing & Business Intelligence Specialist Question Set</strong>.",
  },
  {
    name: "career-coaching-single",
    payment: "career-coaching",
    description:
      "Reach your professional potential with insights from our Coach. At the conclusion of a 1-hour Zoom call, you will receive a documented action plan to help you accelerate your career.",
  },
  {
    name: "career-coaching-package",
    payment: "career-coaching",
    description:
      "Reach your professional potential with insights from our Coach. Three sessions to help you accelerate your career.",
  },
  {
    name: "resume-review",
    payment: "resume-review",
    description: `Our Career Coach will review your <strong>resume</strong> and <strong>LinkedIn profile</strong>
      to provide you with the best advice for the next step in your career journey.`,
  },
  {
    name: "document-review",
    payment: "document-review",
    description: `One of our experienced Data Strategy consultants will review your Data Strategy documents and provide detailed feedback.`,
  },

  {
    name: "cap-question-set-1",
    payment: "cap-question-set",
    description: `Thank you for your purchase of a <strong>CAP Question Set</strong>.
      You'll receive <strong>50 questions</strong> with <strong>detailed explanations</strong>.`,
  },
  {
    name: "cap-question-set-2",
    payment: "cap-question-set",
    description: `Thank you for your purchase of a <strong>CAP Question Set</strong>.
      You'll receive <strong>50 questions</strong> with <strong>detailed explanations</strong>.`,
  },

  {
    name: "cap-question-sets",
    payment: "cap-question-set",
    description: "Thank you for your purchase of <strong>both CAP Question Sets</strong>.",
  },
  {
    name: "cds-question-set-1",
    payment: "cds-question-set",
    description: "Thank you for your purchase of the <strong>Data Science Practice Questions</strong>.",
  },
  {
    name: "cds-question-set-2",
    payment: "cds-question-set",
    description: "Thank you for your purchase of the <strong>Data Science Practice Questions</strong>.",
  },
  {
    name: "cds-question-sets",
    payment: "cds-question-set",
    description: "Thank you for your purchase of the <strong>Data Science Practice Questions</strong>.",
  },
  {
    name: "cippe-question-set-1",
    payment: "cippe-question-set",
    description: "Thank you for your purchase of the <strong>CIPP/E Question Sets</strong>.",
  },
  {
    name: "cippe-question-set-2",
    payment: "cippe-question-set",
    description: "Thank you for your purchase of the <strong>CIPP/E Question Sets</strong>.",
  },
  {
    name: "cippe-question-sets",
    payment: "cippe-question-set",
    description: "Thank you for your purchase of <strong>both CIPP/E Question Sets</strong>.",
  },
  {
    name: "enterprise-study-program",
    payment: "enterprise-study-program",
    description: `Your team will receive access instruction on <strong>Data Strategy best practices</strong>.`,
  },
  {
    name: "cippe-bundle",
    payment: "cippe-bundle",
    description: `Prepare for the CIPP/E exam with a <strong>90-day email course</strong>
    and access to <strong>100 practice questions</strong> about the European Data Privacy legislation.`,
  },
  {
    name: "cap-bundle",
    payment: "cap-bundle",
    description: `Prepare for the CAP exam with a <strong>65-day email course</strong>
    and access to <strong>100 practice questions</strong> about Data Analytics.`,
  },
  {
    name: "data-science-bundle",
    payment: "data-science-bundle",
    description: `Advance your understanding of Data Science with an <strong>email course</strong> and <strong>100 practice questions</strong>.`,
  },
  {
    name: "test-product1",
    payment: "test-product1",
    description: `This test bundle will clean your gut biome, make your home spotless, and win friends and influence people. Plus, it smells great!`,
  },
  {
    name: "test-product2",
    payment: "test-product2",
    description: `This test bundle can keep 50 liters of water in a 45 liter bag.`,
  },
];

export default emailLists;
