const advice: Advice[] = [
  {
    id: "cap-000",
    article: "CAP",
    group: "CAP",
    text: `<p>"I passed the CAP exam from INFORMS yesterday. I was exhausted afterward!! It's a three-hour exam. Keep in
      mind that you can take one bathroom break, with the timer still counting down, but you cannot return to any
      question that you've seen before you take your break.</p>
    <p>"Only about 20% of the questions have easy answers, and the rest are very challenging. There are a lot of
      questions that ask things like 'What is the <em>best</em>...' or 'What is the <em>first</em> thing to do...'</p>
    <p>"Overall, quite a challenge!"</p>`,
    personName: "Kathryn Lesh",
  },
  {
    id: "cdmp-000",
    article: "Fundamentals",
    group: "CDMP",
    text: `<p>"I passed the fundamentals exam today with a grade of 86% and wanted to thank the
          <a href="https://www.facebook.com/groups/346145433213551" target="_blank" rel="noreferrer">CDMP Study Group</a>
          for all of your help, advice, and resources offered.
        </p>
        <p>"Below is how I prepped for the exam:</p>
        <ul>
          <li>Read the book cover to cover once, really taking my time to grasp concepts and not rushing through things.
            In other words, it was very slow reading.</li>
          <li>I then started doing practice exams and questions. The
            <a href="https://www.datastrategypros.com/products/cdmp-question-sets" target="_blank">Question Sets</a>
            were really helpful. This helped me identify where my weak points were.</li>
          <li>I then re-read the chapters which are more heavily weighted and my weak point areas.</li>
          <li>I kept doing the practice exam and Question Sets until I was consistently scoring 90-95%.</li>
          <li>I also listened to the
          <a href="https://www.youtube.com/playlist?list=PLGd_Hl6NWLZfPu01s6FVfHBxf2rB2awG_" target="_blank" rel="noreferrer">CDMP Discussion Groups</a>
            organized by Data Strategy Professionals. It always helps when you hear from people and their real life
            experiences. Try switching music for these instead while you're working out! (This is what I did).</li>
          <li>In my opinion, you need to find a balance between knowing when you are ready and confident to take the exam,
            and delaying it for too long. Trust your instincts and go for it when you feel confident. Delaying things
            for too long isn't always the best option, even if it's just to be on the safe side.</li>
        </ul>
        <p>"All the best and, hopefully, this will be useful info for others out there."</p>`,
    personName: "Zohaib Aref",
  },
  {
    id: "cdmp-001",
    group: "CDMP",
    text: `<p>"I passed the CDMP Fundamentals Exam with a score of 86%. Your
          <a to="https://www.datastrategypros.com/products/cdmp-question-sets" target="_blank">practice exams</a>
          have been a tremendous help.</p>
        <p>"Last year, I purchased the
          <a href="https://www.datastrategypros.com/products/cdmp-study-plan" target="_blank">90 day Study Plan</a>, and I thought 90 days was too ambitious. But it was not, after I gave myself a deadline by booking the exam,
          it was just fine.</p>
        <p>"The exam was not easy and honestly the extra 20 minutes I got as a non-native English speaker was very
          welcome. I am going to take a break for now and consider the <a href="https://www.datastrategypros.com/resources/cdmp-specialist-exam" target="_blank">Specialist Exams</a>
          I want to do."</p>`,
    personName: "Rob Egelink",
  },
  {
    id: "cdmp-002",
    article: "Fundamentals",
    group: "CDMP",
    text: `<p>"Anyone who wants to utilize AI in their organization should really consider getting the CDMP certification.
          It's not an easy exam, but it is worth it."</p>`,
    personName: "Benjamin Magsamen",
  },
  {
    id: "cdmp-003",
    article: "Fundamentals",
    group: "CDMP",
    text: `<p>
          "I started Data Strategy Professionals'
          <a href="https://www.datastrategypros.com/products/cdmp-study-plan" target="_blank">CDMP Study Plan</a>
          at the end of September. I read approx 1-2 chapters a week going through the
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>
          sequentially and did the CDMP practice test from DAMA every week. For answers where I was uncertain or got
          them wrong, I copied them into a notes file in Word. I also copied the contents of each of the emails I
          received from the Study Plan into that same Word doc. I typically reviewed that Word file before each
          practice exam.
        </p>
        <p>
          "I followed along with the email plan and read the chapters in priority order by % of questions on the test. I
          experimented with Quizlet as some folks have put flash cards on there but had mixed results with the quality.
        </p>
        <p>
          "On the day of the exam I got my equipment set up and ran through the Honorlock setup and did another practice
          test where I scored 39/40. I took a 20-minute break and went for a walk to clear my head, then started the
          test.
        </p>
        <p>
          "I'm not going to lie, I was concerned after the first 20 questions. I felt ill-prepared but just flagged
          those I didn't know immediately to come back to. I gave myself about 15 seconds per question the first time
          through then went back through and spent at most 1 minute each and referenced the <em>DMBOK</em>. And cleared
          the flags of those I answered. Then went back through and answered the rest the best I could and finished with
          20 seconds to spare.
        </p>
        <p>
          "All in all, the <a href="https://www.datastrategypros.com/products/cdmp-study-plan" target="_blank">Study Plan</a>
          is great and keeps content top of mind."
        </p>`,
    personName: "Jeff Johnson",
  },
  {
    id: "cdmp-004",
    article: "Fundamentals",
    group: "CDMP",
    text: `<p>Thanks to Data Strategy Professionals, I completed Data Management Fundamentals at Master level with 91%.
    I used your 3 <a href="https://www.datastrategypros.com/products/cdmp-question-sets" target="_blank">Question Sets</a> as practice exams,
    and they were very helpful to hone my skills before the exam.</p>
    <p>Few things from an exam point of view that can be helpful for others:</p>
    <ol>
    <li>Honorlock Chrome extension checks for two monitors, so be sure to set up your ebook on a second device</li>
    <li>Review the book after every practice test to cover those blindspots</li>
    <li>Save time on obvious questions without double checking the ebook and use the time for non-book-related conceptual understanding questions</li>
    <li>Reading and revising the book three times sped up my answering time, thereby saving me time for answering complex questions</li>
    <li>Plan for 20 minutes of revision during the exam</li>
    <li>Passing is not tough if you use practice tests and review the book</li>
    </ol>`,
    personName: "Gopi Palamadai",
  },
  {
    id: "cdmp-005",
    article: "Fundamentals",
    group: "CDMP",
    text: `<p>"Why should you go for certifications?</p>
    <p>I had an interesting conversation today about seeking my Master CDMP accreditation. My co-worker asked me why… what difference does it make?</p>
    <p>I have a few reasons:<br/>
    👉 I love to learn<br/>
    👉 I love to share what I have learned<br/>
    👉 It doesn't hurt to have it<br/>
    👉 It demonstrates you have some fundamental knowledge of the subject area, but no it doesn't mean you have practical application experience in all cases</p>
    <p>Why not? I don't value anyone differently because of a certification but it does help when you know about the various certifications and what it takes to get them.
    In an environment of many individuals in a job market, where you don’t have references or personal experience, seeing those certifications gives you a little more insight
    in to what the candidate has experienced in regards to at least learning about the topic."</p>`,
    personName: "Amy Bamburg",
  },
  {
    id: "cdmp-006",
    article: "Fundamentals",
    group: "CDMP",
    text: `<p>Five tips to prepare the CDMP exam:</p>
        <ol style={{ marginLeft: -3 }}>
          <li>In case English is not your native language, enroll in the ESL version of the exam. This gives you 20
            minutes more, which is valuable time for an open book test.</li>
          <li>Use a PDF version of the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>.
            This is the easiest way to search quickly through the text.</li>
          <li>Use a second device that can search quickly through the pdf. (On my Mac, this is much faster than on my
            Windows machine.)</li>
          <li>The exams on the CDMP site are quite representative for the questions in the exam. Some of the questions you
            will get in the exam. My scores for these test exams were more or less the same as my final score.</li>
          <li>Don't spend money on bad products.</li></ol>`,
    personName: "Olaf Penne",
  },
  {
    id: "specialist-000",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"I took the Data Governance and the Business Intelligence Specialist Exams. I actually think that the
          Governance test is harder to study for because they pull questions from about four chapters, which I didn't
          know until the test. The other exams seem to pull just from the one chapter."</p>`,
    personName: "Katie Reynolds",
  },
  {
    id: "specialist-001",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"On my quest for CDMP Data Governance Specialist, I spend a lot of time listening to podcasts, for example
          <a href="https://metadama.buzzsprout.com/" target="_blank" rel="noreferrer">MetaDama</a>, and reviewing other content. 
          I've read <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>
          chapters related to the exam. Happy to pass on first try, as I've heard it's a very hard exam."</p>`,
    personName: "Bjarte Tolleshaug",
  },
  {
    id: "specialist-002",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"I am happy to share that I successfully cleared the CDMP Specialist Exam (Master & Reference Data
          Management)... I used the following resources to help prepare for the exam.</p>
        <ul>
          <li><a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a></li>
          <li><a href="https://amzn.to/3vecyaY" target="_blank" rel="noreferrer"><em>Navigating the Labyrinth</em></a> by Laura Sebastian-Coleman</li>
          <li><a href="https://amzn.to/3Ex0XHy" target="_blank" rel="noreferrer"><em>Mastering your Data</em></a> by Andy Graham</li>
          <li><a href="https://amzn.to/3plztjz" target="_blank" rel="noreferrer"><em>Master Data Management</em></a> by David Loshin</li>
          <li>Leaned on the hard lessons learnt over the many years working in this space 🙂"</li>
        </ul>`,
    personName: "Mayukh Datta Roy",
  },
  {
    id: "specialist-003",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"For the Metadata exam, I relied on the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>
            chapter 12 and also every section that mentions metadata in <em>DMBOK</em> chapters 1 to 14. I believe that
            this material is sufficient, and this is what I would recommend to working data professionals.</p>
          <p>"I tried to maintain a consistent daily study schedule, and I found it helpful to connect the study material
            to work and practical matters to make it fun and engaging."</p>`,
    personName: "Albert Chiwara",
  },
  {
    id: "specialist-004",
    article: "Specialist",
    group: "CDMP",
    text: `<p>
          "One helpful tip if you have the pdf version of the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>:
          while in the document, select "Print to PDF" then specify the page range of the chapter you're studying.
          That way, when you take the exam, you can have just that chapter open so you're not scrolling through hundreds
          of pages!"</p>`,
    personName: "Jeff Johnson",
  },
  {
    id: "specialist-005",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"The practice exams that come with the test purchase are the best. And you can retake them as many times as
          you want. Each time you retake, at least half the questions are newly generated.</p>
        <p>"There are a few questions on the exams that are not anywhere in the book. Skip them and go back to them at
          the end (you can mark questions for review). By the time you go back, you may be able to figure out the answer
          or have found something in another question that helped.</p>
        <p>"Definitely don't count on looking every question up. You won't have time. Of the 100 I probably looked up 20
          or so. I went through the test questions twice.</p>
        <p>"I took the Fundamentals Exam one day and the two Specialist Exams the next – although that was brain numbing
          so you may want to spread them across three days."</p>`,
    personName: "Katrina Waring Castillo",
  },
  {
    id: "specialist-006",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"My approach for the Specialist Exams was:</p>
        <ul>
          <li>Select topics that aligned with current or past work experience</li>
          <li>Took the Specialist Exams shortly after the first exam; many questions for the Specialist Exams were sourced
            from the Fundamentals Exam
          </li>
          <li>Reread the related chapters</li>
          <li>Retook the practice exam</li>
          <li>Used your <Link to="/products/specialist-guides" target="_blank">Study Guides</Link> and
            <Link to="/products/specialist-question-sets" target="_blank">practice exams</Link> as reference</li>
          <li>Studied, studied, studied</li>
          <li>Carved out time each week to prep for the exam"</li>
        </ul>`,
    personName: "Ryan Balazs",
  },
  {
    id: "specialist-007",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"Whoo hoo... just finished the third and final exam and got my Practitioner certificate... The Specialist
          Exams are a bit scenario based, and a few questions were repeated with different context. But overall the
          experience is good. Time spent: 5 weeks – 3 to 4 hours a day."</p>`,
    personName: "Mahendar Muthu",
  },
  {
    id: "specialist-008",
    article: "Specialist",
    group: "CDMP",
    text: `<p>"No matter how confident you are in your answers, don't submit the exam before 90 minutes have passed. Use any
          extra time to check your answers. A small mistake could be the difference between being recognized at the
          Practitioner or Master level."</p>`,
    personName: "Peter Vennel",
  },
];

export default advice;
