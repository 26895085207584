import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import CustomHelmet from "../CustomHelmet";
import ByLine from "./ByLine";
import PostAuthorCard from "./PostAuthorCard";
import RelatedPostsCarousel from "./related-posts-carousel/RelatedPostsCarousel";

import posts from "../../data/posts";

const Post: React.FC<{
  postName: postNickname;
  rest: any;
}> = ({ postName, rest }) => {
  const post = posts.find((post) => post.nickname === postName);

  const location = useLocation();

  useEffect(() => {
    console.log("POST", post);

    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomHelmet
        description={post.subtitle}
        author={post.author.map((author) => author.name).join(", ")}
        name={post.title}
        link={post.link}
        img={post.image.previewImage ?? post.image.img}
        alt={post.image.alt}
      />
      <div className="home-layout">
        <div className="blog">
          <h1>{post.title}</h1>
          <h3 dangerouslySetInnerHTML={{ __html: post.subtitle }} />
          <ByLine author={post.author} postDate={post.postDate} updateDate={post.updateDate} />
          {rest}
        </div>
        <PostAuthorCard authors={post.author} />
        <RelatedPostsCarousel postNicknames={post.related} />
      </div>
    </>
  );
};

export default Post;
