import { useState } from "react";
import { Link } from "react-router-dom";

import q1 from "../../img/products/exam-questions/Q1.webp";
import cover from "../../img/products/exam-questions/cover-exam-questions.webp";
import a1 from "../../img/products/exam-questions/A1.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import CustomModal from "../../components/CustomModal";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";

import testimonials from "../../data/testimonials";
import BuyNowButton from "../../components/product-pages/BuyNowButton";

const PRODUCT = "CDMP Question Sets";

export default function CDMPExamQuestions() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>
            Research has shown{" "}
            <a href="http://pdf.poojaagarwal.com/McDaniel_Agarwal_etal_2011_JEP.pdf" target="_blank" rel="noreferrer">
              time
            </a>{" "}
            and{" "}
            <a href="http://pdf.poojaagarwal.com/Roediger_Agarwal_etal_2011_JEPA.pdf" target="_blank" rel="noreferrer">
              time again
            </a>{" "}
            that <strong>testing ourselves</strong> is one of the most effective ways to{" "}
            <strong>achieve mastery</strong>.
          </p>

          <p>
            Use this strategy to prepare effectively for the{" "}
            <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank" rel="noreferrer">
              CDMP Fundamentals Exam
            </a>{" "}
            by working through these Question Sets based on the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            . Each Question Set contains 50 multiple choice questions in the style of the CDMP exams. In the answers
            section, you'll find a detailed explanation for each question.
          </p>

          <p>
            These Question Sets use{" "}
            <a href="http://uweb.cas.usf.edu/~drohrer/pdfs/Taylor&Rohrer2010ACP.pdf" target="_blank" rel="noreferrer">
              interleaving
            </a>{" "}
            (i.e., mixed practice) to make the long-lasting effects of studying with these practice questions even
            stronger. You'll return to them throughout your test prep until you're <strong>totally ready</strong> to
            take the exam!
          </p>
        </div>
      }
    />
    <ExtendedDescription
      title="Frequently Asked Questions"
      description={
        <div className="extended-description faq">
          <p>
            <strong>Where did the CDMP practice questions come from?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            These questions were written by our team at Data Strategy Professionals. Each Question Set contains 50 new
            and different practice questions in the style of the{" "}
            <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
              CDMP exam
            </a>{" "}
            by{" "}
            <a href="https://www.dama.org/" target="_blank" rel="noreferrer">
              DAMA
            </a>
            .
          </p>

          <p>
            <strong>Which chapters are covered in the Question Sets?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Each Question Set contains a random mix of questions from chapters 1-14 of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            .
          </p>

          <p>
            <strong>What is included in my purchase of the CDMP Question Sets?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The CDMP Question Sets include an introduction with test preparation instructions as well as the topic and a
            detailed explanation for each answer. You will also receive access to the practice questions in the exam
            simulator on our Training Site and as a PDF.
          </p>

          <p>
            <strong>
              What else can I do to prep for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam">CDMP Fundamentals Exam</Link>?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Check out the{" "}
            <Link to="/products/cdmp-study-plan" target="_blank" rel="noopener noreferrer">
              CDMP Study Plan
            </Link>
            ,{" "}
            <Link to="/products/cdmp-fundamentals-notes" target="_blank" rel="noopener noreferrer">
              CDMP Fundamentals Notes
            </Link>
            , and{" "}
            <Link to="/products/cdmp-exam-readiness-check" target="_blank" rel="noopener noreferrer">
              CDMP Exam Readiness Check
            </Link>{" "}
            or everything altogether via the{" "}
            <Link to="/products/cdmp-fundamentals-bundle">CDMP Fundamentals Bundle</Link>.
          </p>
        </div>
      }
    />
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People are Saying</h4>
    <div style={{ marginTop: "2em" }}>
      <SubtleTestimonialCardFlip
        testimonial={testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-002")}
      />
    </div>
  </div>
);

const rest = (handleShowClick) => {
  const testimonialsToShowOne = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-004"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-006"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-003"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-000"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-005"),
  ];

  const testimonialsToShowTwo = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-007"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-008"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-009"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-010"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-011"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-012"),
  ];

  return (
    <>
      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Photos
        </h4>
        <div className="image-display-tri">
          <figure>
            <img
              src={q1}
              alt="exam questions"
              onClick={handleShowClick}
              style={{ maxHeight: "360px", cursor: "pointer" }}
            />
          </figure>

          <figure>
            <img
              src={cover}
              alt="person reviewing question set"
              onClick={handleShowClick}
              style={{
                maxHeight: "360px",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          </figure>

          <figure>
            <img src={a1} alt="answers" onClick={handleShowClick} style={{ maxHeight: "360px", cursor: "pointer" }} />
          </figure>
        </div>
      </div>
      <ProductTestimonials testimonialsToShow={testimonialsToShowOne} />
      <BuyNowButton paymentName={"cdmp-question-set" as paymentName} />
      <ProductTestimonials testimonialsToShow={testimonialsToShowTwo} showTitle={false} />
    </>
  );
};
