import { useEffect } from "react";
import { Link } from "react-router-dom";

import smiling from "../../../img/products/membership/smiling_sq.webp";

import CustomHelmet from "../../../components/CustomHelmet";
import ScheduleACall from "../../../components/ScheduleACall";
import SmallGroupBox from "./SmallGroupBox";
import EightyDivider from "../../../components/EightyDivider";
import SmallGroupFAQs from "./SmallGroupFAQs";
import SmallGroupTestimonials from "./SmallGroupTestimonials";

const SmallGroup: React.VFC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="CDMP training for Study Groups including practice questions and video lessons"
        name="CDMP Small Group Study Materials"
        link="/landing/document-drafting-and-review"
        img={smiling}
        alt="two smiling people at a conference table"
      />

      <div className="landing-page" style={{ minHeight: "88vh" }}>
        <div className="landing-page-inner">
          <Link to="/enterprise">
            <span className="landing-page-close-icon">x</span>
          </Link>
          <h1 className="title landing-page-title">CDMP Small Group Study Materials</h1>
          <div className="landing-page-divider" style={{ marginTop: "-1.7em" }} />
          <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
            Learn about Data Strategy for the CDMP Fundamentals Exam with our materials for study groups.
          </p>
          <div className="blog">
            <p>
              For each of the 14{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              chapters tested on the{" "}
              <Link to="/resources/cdmp-fundamentals/exam" target="_blank">
                CDMP Fundamentals Exam
              </Link>
              , we provide <strong>short video introductions</strong> to each chapter and{" "}
              <strong>practice questions</strong> for your group. Participants will have access to the materials via our
              Training Site. In addition, we'll share a <strong>recommended study schedule</strong> for your group. The
              total cost is <strong>$280 per cohort</strong>.
            </p>
          </div>
          <ScheduleACall />
          <SmallGroupBox />
          <EightyDivider />
          <SmallGroupFAQs />
          <EightyDivider />
          <SmallGroupTestimonials />
        </div>
      </div>
    </>
  );
};

export default SmallGroup;
