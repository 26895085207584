import { useState } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
// import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import BuyNowButton from "../../components/product-pages/BuyNowButton";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";
import CustomModal from "../../components/CustomModal";
import AdditionalWays from "../../components/product-pages/AdditionalWays";
import { Instructors } from "../../components/product-pages/Coaches";

import Shaulis from "../../img/people/jon_shaulis.webp";

import testimonials from "../../data/testimonials";

const PRODUCT = "CDMP Bootcamp (July 19 and 20)";
const PAYMENT = "cdmp-bootcamp";

const CDMPBootcamp = () => {
  const [showCDMPLevelsModal, setShowCDMPLevelsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showCDMPLevelsModal && <CDMPLevelsModal setShowModal={setShowCDMPLevelsModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription()}
        rest={rest()}
      />
    </>
  );
};

const tealBox = <AdditionalWays />;

const extendedDescription = () => {
  return (
    <div className="extended-description-container">
      <ExtendedDescription
        title="Frequently Asked Questions"
        description={
          <div className="extended-description faq">
            <p>
              <strong>Who is this Bootcamp for?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              This Bootcamp is designed for prospective certificants who wish to quickly and efficiently prepare for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP Fundamentals Exam
              </Link>
              . Participants must be able to dedicate the time from 9am to 4pm ET on Friday, July 19 and Saturday, July
              20. The content will not be made available after the Bootcamp.
            </p>

            <p>
              <strong>What is included in the Bootcamp?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              The Bootcamp provides instruction on each of the 14 chapters of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>Data Management Body of Knowledge</em>
              </a>
              , provided roughly in order of priority for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP Fundamentals Exam
              </Link>
              . The interactive approach ensures you're learning best practices from your fellow Bootcamp students.
              Participants will be able to try practice questions throughout the two-day training. At the end of each
              day, there will be a mock exam composed of 50 new, never-before-seen questions to test your skills. All
              participants will get access to an exclusive Slack channel to share study tips and ask questions of the
              instructors.
            </p>

            <p>
              <strong>What is not included in the Bootcamp?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              The Bootcamp does not include the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>Data Management Body of Knowledge</em>
              </a>{" "}
              or{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                CDMP Fundamentals Exam
              </a>
              . We recommend purchasing the <em>DMBOK</em> as a{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                hardcopy
              </a>{" "}
              or{" "}
              <a href="https://technicspub.com/dmbok2/" target="_blank" rel="noreferrer">
                ebook
              </a>
              . The{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                CDMP exam
              </a>{" "}
              can be purchased as a standard 90-minute exam or 110-minute exam (ESL version) for non-native English
              speakers.
            </p>

            <p>
              <strong>Is the Bootcamp adequate preparation to pass the CDMP exam?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              While the Bootcamp is designed to provide a structured review of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>
              , two days of preparation may not be sufficient for all participants to pass the exam. Unless you have
              extensive Data Management experience through your job, we recommend taking the time to thoroughly review
              the <em>DMBOK</em>.
            </p>

            <p>
              <strong>
                Do I have to read the <em>DMBOK</em> before the Bootcamp, or can I just attend the Bootcamp and then
                read the <em>DMBOK</em>?
              </strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              You can attend the Bootcamp without reviewing the <em>DMBOK</em> beforehand. However, we recommend
              reviewing the <em>DMBOK</em> before the Bootcamp to get the most out of the experience. At minimum, please
              review chapters 3, 5, 8, 10, 11, 12, and 13, which collectively comprise 70% of the content on the{" "}
              <Link to="/resources/cdmp-fundamentals-exam">CDMP exam</Link>.
            </p>

            <p>
              <strong>What else can I do to prepare for the Bootcamp?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              We recommend joining the CDMP Study Group on{" "}
              <a
                href="https://www.facebook.com/groups/346145433213551"
                target="_blank"
                rel="noreferrer"
                style={{ whiteSpace: "nowrap" }}
              >
                Facebook
              </a>{" "}
              or{" "}
              <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
              . These groups are a great place to ask questions, share study tips, and network with others. We also
              recommend that you{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                purchase the exam
              </a>{" "}
              before the Bootcamp, so you have access to the official practice questions provided by DAMA. Finally, you
              may benefit from checking out some of our{" "}
              <a href="https://www.youtube.com/@datastrategypros" target="_blank" rel="noreferrer">
                video content on YouTube
              </a>{" "}
              to prepare for the test.
            </p>

            <p>
              <strong>Will the materials be available after the Bootcamp?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              No, unfortunately, you will not be able to access the training materials after the Bootcamp. Therefore, it
              is very important to make sure you can attend on Friday, July 19 and Saturday, July 20 from 9am to 4pm ET.
            </p>

            <p>
              <strong>What if I have an unexpected conflict and cannot attend the Bootcamp?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              If unable to attend, we can move your registration to a future Bootcamp.
            </p>

            <p>
              <strong>How does the Slack channel work?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              All participants will be granted access to a Slack channel where they can ask questions of the instructors
              and network with other participants. The Slack channel will be available before the Bootcamp and two weeks
              afterward. Participants are also encouraged to connect on LinkedIn for continued networking.
            </p>

            <p>
              <strong>Do you provide the slides and the questions from the Bootcamp?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Unfortunately, we are not able to provide the slides or the questions used during the Bootcamp. We
              encourage participants to take thorough notes throughout the sessions. That said, participants will have
              lifetime access to the two Mock Exams in the exam simulator (100 questions total).
            </p>
          </div>
        }
      />
    </div>
  );
};

const rest = () => {
  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-000"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-002"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-005"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-004"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-006"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-007"),
  ];

  return (
    <>
      <Instructors />
      <ProductTestimonials testimonialsToShow={testimonialsToShow} />

      <div className="white-outline-box">
        <h4 className="box-title">Features</h4>
        <div className="center-text-box two-col" id="features">
          <ul>
            <li>
              Instruction on each of the <strong>14 chapters</strong> of the Data Management Body of Knowledge, provided
              roughly in order of priority for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam">CDMP Fundamentals Exam</Link>
            </li>
            <li>
              <strong>Interactive approach</strong> to ensure you're learning best practices from your fellow Bootcamp
              students
            </li>
            <li>
              <strong>Practice questions</strong> throughout to assess your progress and identify any gaps in your
              knowledge
            </li>
            <li>
              <strong>Two mock exams</strong>, one on each day of the Bootcamp, to test your skills
            </li>
            <li>
              <strong>Study tips</strong> to ensure you're maximizing your time and energy as you prepare
            </li>
            <li>
              Access to an <strong>exclusive Slack channel</strong> to ask questions of the instructors and network with
              participants
            </li>
          </ul>
        </div>
      </div>

      <div className="teal-box">
        <div className="center-text-box oath" style={{ paddingTop: 20, paddingBottom: 10, zIndex: 1 }}>
          <p style={{ zIndex: 1 }}>
            "Since achieving my CDMP, hundreds of people have reached out to network, collaborate, comment, learn, and
            embolden their careers as data professionals. Nicole Janeway Bills of{" "}
            <strong>Data Strategy Professionals</strong> has become a pinnacle in this community. She has done so much
            more than just start a{" "}
            <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
              study group
            </a>
            , she has also compiled helpful resources and responded back to others in the community who have questions
            or need support."
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <p style={{ textAlign: "right" }}>
              —{" "}
              <a
                href="https://jonshaulis.com/index.php/2019/11/26/hurray-i-earned-my-cdmp/#Study_plan"
                target="_blank"
                rel="noreferrer"
              >
                Jon Shaulis
              </a>
              ,<br />
              <em>Enterprise Data Architect</em>
            </p>
            <img
              style={{ borderRadius: "50%", width: 60, height: 60, marginLeft: 20 }}
              src={Shaulis}
              alt="Jon Shaulis"
            />
          </div>
        </div>
      </div>

      <BuyNowButton margins="60px" paymentName={PAYMENT as paymentName} />
    </>
  );
};

export default CDMPBootcamp;
