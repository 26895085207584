import email from "../../img/icons/color-forwardtofriend-128.webp";

const Contact: React.VFC = () => {
  return (
    <>
      <div style={{ marginBottom: "1em" }}>
        <p className="title" style={{ fontSize: "1.2em" }}>
          Contact
        </p>
        <div className="blog">
          <p>
            Need <strong>help</strong> with something or have a <strong>question</strong> about one of our products?
          </p>
          <p>
            Use the chat widget in the bottom right corner of the screen or send an email to{" "}
            <a href="mailto:support@datastrategypros.com">Customer Support</a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default Contact;
