import Card from "../../components/Card.js";
import team from "../../data/team";

const Team: React.VFC = () => {
  return (
    <>
      {team.map((member) => (
        <>
          <Card person={member} />
          <br />
        </>
      ))}
      <br />
    </>
  );
};

export default Team;
