import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import report from "../../img/lead-magnets/cert/scr1.webp";

const BestCertifications: React.VFC = () => {
  const desktop = useMediaQuery("(min-width:790px)");
  return (
    <div className="teal-box blog" style={{ maxWidth: 1000, minHeight: 260 }}>
      <Link to="/landing/best-certifications" target="_blank">
        <img
          src={report}
          alt="report cover"
          style={{
            width: 200,
            height: 200,
            float: "left",
            marginRight: desktop ? 40 : 20,
            marginBottom: desktop ? 0 : 16,
          }}
        />
      </Link>
      <div>
        <h2 style={{ marginTop: 0 }}>
          <Link to="/landing/best-certifications" target="_blank">
            Grab your free copy of our report today
          </Link>
        </h2>
        <p>
          If you haven't already, check out our detailed writeup on the seven top certifications across a variety of
          data-related domains. Aim higher with your career ambitions for 2024 and pursue the proven qualifications that
          will help you demonstrate your value.{" "}
          <Link to="/landing/best-certifications">Check out our comprehensive report</Link> to learn how to attain the
          credentials to break into a new field or accelerate your career trajectory.
        </p>
      </div>
    </div>
  );
};

export default BestCertifications;
