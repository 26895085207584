export default function TestimonialCard({ testimonial, fontSize = 1, quotes = true, productPage = false }) {
  const testimonialText = productPage ? testimonial.plainText : testimonial.content;
  return (
    <div className="visual-description-item">
      <p
        className="testimonial-text"
        dangerouslySetInnerHTML={{
          __html: quotes ? `"${testimonialText}"` : testimonialText,
        }}
        style={{ fontSize: `${fontSize}em`, position: "relative" }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <div>
          <p style={{ textAlign: "right" }}>
            — <span>{testimonial.name}</span>
            {testimonial.title && ", "}
          </p>
          <p
            style={{
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            {testimonial.title}
          </p>
        </div>
        {testimonial.img && <img className="testimonial-img" src={testimonial.img} alt="" />}
      </div>
    </div>
  );
}
