import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import VisualDescription from "../../components/product-pages/VisualDescription";
import TestimonialBox from "../../components/product-pages/TestimonialBox";

import testimonials from "../../data/testimonials";

const PRODUCT = "Specialist Question Sets";

export default function SpecialistQuestions() {
  return <MultiPriceProductPage PRODUCT={PRODUCT} description={description} tealBox={tealBox} rest={rest} />;
}
const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Progress your understanding of Data Strategy with these sets of <strong>50 practice questions</strong> that
        focus on <strong>each of the seven chapters</strong> of the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        tested on the{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist Exams
        </Link>
        . These Question Sets contain a mix of questions from the{" "}
        <Link to="/products/cdmp-question-sets" target="_blank">
          CDMP Question Sets
        </Link>{" "}
        and new questions developed to enhance your preparedness for the Specialist Exams.
      </p>
      <p>
        Immediately upon purchase, you'll receive a pdf document via email containing 1) 50 multiple choice practice
        questions and 2) answers section with relevant{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        page number and detailed explanation of correct answer.
      </p>
      <p>
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          CDMP Specialist Exams
        </Link>{" "}
        offer data practitioners the opportunity to advance their credentials and knowledge of specific Data Strategy
        topics. Successful completion of two Specialist Exams is required in order to attain recognition at the{" "}
        <strong>Practitioner or Master level</strong>.
      </p>
      <p>
        To further develop your understanding of these topics, don't miss our{" "}
        <Link to="/products/specialist-guides" target="_blank">
          Specialist Guides
        </Link>
        .
      </p>
      <p>
        <i>
          Note: this product is available as part of the{" "}
          <Link to="/products/specialist-bundle" target="_blank">
            Specialist Bundle
          </Link>
        </i>
      </p>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials" style={{ marginBottom: 0, paddingBottom: 0 }}>
    <h4>Features</h4>
    <VisualDescription product={PRODUCT} />
  </div>
);

const rest = (
  <TestimonialBox
    testimonialsToShow={testimonials.filter((testimonial) => testimonial.id.includes("specialist-question-sets"))}
  />
);
