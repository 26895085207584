import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CustomHelmet from "../../components/CustomHelmet";
import CustomModal from "../../components/CustomModal";
import LeadMagnetSignup from "../../components/lead-magnet/LeadMagnetSignup";

import scr1 from "../../img/lead-magnets/cert/scr1.webp";
import scr2 from "../../img/lead-magnets/cert/scr2.webp";
import scr3 from "../../img/lead-magnets/cert/scr3.webp";

import logo from "../../img/helmet/products/logo_cdmp.png";

import leadMagnets from "../../data/leadMagnets";

const leadMagnet = leadMagnets.find((leadMagnet) => leadMagnet.name === "Best Certifications");

const BestCertifications: React.VFC = () => {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <CustomHelmet
        description={leadMagnet.description}
        name={leadMagnet.name}
        link={leadMagnet.link}
        img={logo}
        alt={leadMagnet.alt}
      />

      <div className="landing-page" style={{ minHeight: "88vh" }}>
        <div className="landing-page-inner">
          <Link to="/resources">
            <span className="landing-page-close-icon">x</span>
          </Link>
          <p className="title landing-page-title">Best Data-Related Certifications</p>
          <div className="landing-page-divider" style={{ marginTop: "-1.7em" }} />
          <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
            Aim higher with your <strong>career ambitions</strong> for 2024. This list of seven{" "}
            <strong>top certifications</strong> span a variety of data-related domains from Data Management to Data
            Engineering. Check out our <strong>comprehensive report</strong> to learn how to attain the credentials to
            break into a new field or accelerate your career trajectory.
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "40px auto" }}>
              <LeadMagnetSignup leadMagnetName="our top certifications report" emailList={96} />
            </div>
          </div>
          {/* <div className="blog">
            <h4 style={{ fontSize: "1.2em" }}>Get your free Data Management guide today </h4>
            <p>
              In this 10 page pdf, you will discover:
              <ul>
                <li>Explanations of each of the 15 Data Management Standards</li>
                <li>Best practices to ensure your data remains accessible, accurate, and secure</li>
                <li>
                  Proven strategies to ensure compliance with KSA National Data Management Office (NDMO) guidelines
                </li>
              </ul>
              This guide will help you <strong>save time</strong>, <strong>boost productivity</strong>, and{" "}
              <strong>stay ahead</strong> of regulatory compliance requirements.{" "}
            </p>
          </div> */}
          <h4 className="box-title" style={{ fontSize: "1.2em", textAlign: "center" }}>
            Screenshots
          </h4>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "3em",
              marginBottom: 70,
            }}
          >
            <img
              src={scr1}
              alt="cover"
              onClick={handleShowClick}
              style={{ cursor: "pointer" }}
              className="box-shadow"
            />
            <img src={scr2} alt="page" onClick={handleShowClick} style={{ cursor: "pointer" }} className="box-shadow" />
            <img src={scr3} alt="page" onClick={handleShowClick} style={{ cursor: "pointer" }} className="box-shadow" />
          </div>

          <div className="blog">
            <h4 style={{ fontSize: "1.2em", marginTop: 40 }}>We can't wait to get to know you!!</h4>
            <p>
              At Data Strategy Professionals, we offer a professional community for data practitioners. We'll help you
              build your skills, so you can help your organization connect data to value.
            </p>
            <p>
              Join thousands of professionals who have transformed the way they work with data. By signing up today, you
              will also receive twice-monthly emails packed with:
            </p>
            <ul>
              <li>Access to exclusive events, resources, and special offers</li>
              <li>Updates on the latest trends and best practices for data practitioners</li>
              <li>Tips and insights related to Data Management, Data Privacy, Data Analytics and more</li>
            </ul>
          </div>
          <p>Your privacy is our priority. We will never share or sell your information. Unsubscribe at any time.</p>
        </div>
      </div>
    </>
  );
};

export default BestCertifications;
