import { useState } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import BuyNowButton from "../../components/product-pages/BuyNowButton";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";
import CustomModal from "../../components/CustomModal";
import AdditionalWays from "../../components/product-pages/AdditionalWays";
import { Instructors } from "../../components/product-pages/Coaches";

import Shaulis from "../../img/people/jon_shaulis.webp";

import testimonials from "../../data/testimonials";

const PRODUCT = "CDMP Small Group Sessions (Q4)";

const Q4_CDMP_Small_Group = () => {
  const [showCDMPLevelsModal, setShowCDMPLevelsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showCDMPLevelsModal && <CDMPLevelsModal setShowModal={setShowCDMPLevelsModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription()}
        rest={rest()}
      />
    </>
  );
};

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People Are Saying</h4>
    <div style={{ marginTop: "1em" }}>
      <SubtleTestimonialCardFlip
        testimonial={testimonials.find((testimonial) => testimonial.id === "enterprise-custom-training-000")}
        productPage
        fontSize={1.05}
      />
    </div>
  </div>
);

const extendedDescription = () => {
  return (
    <div className="extended-description-container">
      <ExtendedDescription
        title="Learn More"
        description={
          <div className="extended-description blog">
            <p>
              The <strong>CDMP Small Group Sessions (Q4)</strong> includes:
            </p>
            <ul>
              <li>
                Weekly <strong>1-hour Zoom session</strong> on Friday at 12pm ET with instructor-led presentation over{" "}
                <strong>13 weeks</strong>
              </li>
              <li>
                <Link to="/products/cdmp-study-plan" target="_blank">
                  CDMP Study Plan
                </Link>{" "}
                ($115 value) delivered by email over 13 weeks as well as lifetime access to the content Study Plan
                content in our Training Site
              </li>
              <li>
                Lifetime access to <strong>140 practice questions</strong> ($70 value) delivered via mock exam simulator
                on our Training Site
              </li>
              <li>
                Access to <strong>session recordings</strong> and <strong>private Slack channel</strong> for
                participants for the duration of the program plus four weeks (i.e., until Friday, Jan 10, 2025)
              </li>
            </ul>
          </div>
        }
      />
      <ExtendedDescription
        title="Frequently Asked Questions"
        description={
          <div className="extended-description faq">
            <p>
              <strong>Who are the CDMP Small Group Sessions designed for?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              This study program is designed for prospective certificants who wish to efficiently prepare for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP Fundamentals Exam
              </Link>{" "}
              over 13 weeks.
            </p>

            <p>
              <strong>What is included in the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Participants will receive access to the{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              ($115 value), 140 practice questions ($70 value), session recordings, and a private Slack channel.
            </p>

            <p>
              <strong>What is not included in the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              The Bootcamp does not include the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>Data Management Body of Knowledge</em>
              </a>{" "}
              or{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                CDMP Fundamentals Exam
              </a>
              . We recommend purchasing the <em>DMBOK</em> as a{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                hardcopy
              </a>{" "}
              or{" "}
              <a href="https://technicspub.com/dmbok2/" target="_blank" rel="noreferrer">
                ebook
              </a>
              . The{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                CDMP exam
              </a>{" "}
              can be purchased as a standard 90-minute exam or 110-minute exam (ESL version) for non-native English
              speakers.
            </p>

            <p>
              <strong>Are the Small Group Sessions adequate preparation to pass the CDMP exam?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Yes! The Small Group Sessions are designed to provide a structured review of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              over 90 days, which is the recommended duration of preparation for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP exam
              </Link>
              .
            </p>

            <p>
              <strong>
                Do I have to read the <em>DMBOK</em> before the Small Group Sessions start?
              </strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Nope! While it's fine if you are familiar with the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>
              , this is not a prerequisite for the program given that we will be reviewing the <em>DMBOK</em> over the
              course of the program, using the emails of the{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              as a guide. We will discuss each of the fourteen chapters in a structured presentation as part of the
              weekly Zoom session.
            </p>

            <p>
              <strong>What can I do to prepare for the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              We recommend joining the CDMP Study Group on{" "}
              <a
                href="https://www.facebook.com/groups/346145433213551"
                target="_blank"
                rel="noreferrer"
                style={{ whiteSpace: "nowrap" }}
              >
                Facebook
              </a>{" "}
              or{" "}
              <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
              . These groups are a great place to ask questions, share study tips, and network with others. We also
              recommend that you go ahead and{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                purchase the exam
              </a>
              , so you have access to the official practice questions provided by DAMA. Finally, you may benefit from
              checking out some of our{" "}
              <a href="https://www.youtube.com/@datastrategypros" target="_blank" rel="noreferrer">
                video content on YouTube
              </a>{" "}
              to prepare for the test.
            </p>

            <p>
              <strong>Will the materials be available after the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Participants will have lifetime access to the CDMP Study Plan and practice questions. Participants will
              have access to the session recordings and Slack channel for four weeks following the conclusion of the
              Small Group Sessions on Friday, Dec 13 (i.e., the last day to access the session recordings and Slack
              channel will be Fri, Jan 10, 2025).
            </p>

            <p>
              <strong>What if I have an unexpected conflict and cannot attend a session?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              No problem, the sessions will be recorded and made available to participants through our Training Site.
            </p>

            <p>
              <strong>What is the format of the weekly Small Group Session?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              The study sessions will be conducted via Zoom every Friday between Sep 20 and Dec 13 at 12pm ET. Each
              session will include an instructor-led presentation, practice questions, and the opportunity to discuss
              the chapter as a group. The sessions are interactive and provide an opportunity to network with other
              participants.
            </p>

            <p>
              <strong>How does the CDMP Study Plan work?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Participants will receive the{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              by email starting Saturday, Sep 14. The CDMP Study Plan includes 5-6 emails per week that provide guidance
              on what to read each week in order to prepare for the study session on Friday. The Study Plan also
              includes study tips, practice questions, and other resources to help you prepare for the exam.
            </p>

            <p>
              <strong>How does the Training Site work?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Each participant will receive a unique login to our Training Site, where you'll be able to access the
              content of the CDMP Study Plan, practice questions (mock exam simulator), and session recordings.
            </p>

            <p>
              <strong>How does the Slack channel work?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              All participants will be granted access to a Slack channel where they can ask questions of the instructor
              and network with other participants. The Slack channel will be available before the start of the program
              and for four weeks afterward. Participants are also encouraged to connect on LinkedIn for continued
              networking.
            </p>
          </div>
        }
      />
    </div>
  );
};

const rest = () => {
  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-small-group-000"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-005"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-small-group-001"),
  ];

  return (
    <>
      <Instructors instructors={["John"]} />
      <ProductTestimonials testimonialsToShow={testimonialsToShow} />

      <div className="white-outline-box">
        <h4 className="box-title">Features</h4>
        <div className="center-text-box two-col" id="features">
          <ul>
            <li>
              <strong>Weekly 1-hour Zoom session</strong> with instructor-led presentation over{" "}
              <strong>13 weeks</strong>
            </li>
            <li>
              Instruction on each of the <strong>14 chapters</strong> of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>
              , provided roughly in order of priority
            </li>
            <li>
              <strong>Access</strong> to our best selling{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              that includes 75+ emails over the duration of the CDMP Small Group Sessions
            </li>
            <li>
              <strong>140 practice questions</strong> to help you assess your progress each week
            </li>
            <li>
              Access to <strong>session recordings</strong> and private Slack channel for participants
            </li>
            <li>
              <strong>Interactive approach</strong> to ensure you're networking and learning with your peers
            </li>

            <li>
              <strong>Study tips</strong> to ensure you're maximizing your time and energy as you prepare
            </li>
          </ul>
        </div>
      </div>

      <div className="teal-box">
        <div className="center-text-box oath" style={{ paddingTop: 20, paddingBottom: 10, zIndex: 1 }}>
          <p style={{ zIndex: 1 }}>
            "Since achieving my CDMP, hundreds of people have reached out to network, collaborate, comment, learn, and
            embolden their careers as data professionals. Nicole Janeway Bills of{" "}
            <strong>Data Strategy Professionals</strong> has become a pinnacle in this community. She has done so much
            more than just start a{" "}
            <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
              study group
            </a>
            , she has also compiled helpful resources and responded back to others in the community who have questions
            or need support."
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <p style={{ textAlign: "right" }}>
              —{" "}
              <a
                href="https://jonshaulis.com/index.php/2019/11/26/hurray-i-earned-my-cdmp/#Study_plan"
                target="_blank"
                rel="noreferrer"
              >
                Jon Shaulis
              </a>
              ,<br />
              <em>Enterprise Data Architect</em>
            </p>
            <img
              style={{ borderRadius: "50%", width: 60, height: 60, marginLeft: 20 }}
              src={Shaulis}
              alt="Jon Shaulis"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Q4_CDMP_Small_Group;
