import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Email } from "@mui/icons-material";

import { CheckoutContext } from "../../contexts/CheckoutContext";

import payments from "../../data/payments";
import BackButton from "../../components/BackButton";

interface CheckPurchasesProps {
  setStep: (step: number) => void;
  paymentName: paymentName;
  email: string;
  setFadeLoader: (fadeLoader: boolean) => void;
}

const CheckPurchases: React.FC<CheckPurchasesProps> = ({ setStep, paymentName, email, setFadeLoader }) => {
  const [status, setStatus] = useState<"checking" | "warning" | "continue">("checking");

  const history = useHistory();

  const payment = payments.find((payment) => payment.name === paymentName) as paymentType;

  // for all products that are not physical or service, we want to prevent the user from accidentally buying the same thing twice
  const blockSecondPayment =
    payment.type !== "physical" &&
    payment.type !== "service" &&
    !payment.name.includes("question") &&
    !payment.name.includes("guide") &&
    payment.name !== "specialist-bundle";

  const possibleAddOns = payment?.addOns;

  const checkoutContext = useContext(CheckoutContext);
  const { setPastPurchases, setPotentialAddOns } = checkoutContext.purchasesAndAddons;

  useEffect(() => {
    setFadeLoader(status === "checking");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    async function checkPurchases() {
      try {
        const response = await fetch("/.netlify/functions/check-purchases", {
          method: "POST",
          body: JSON.stringify({ email }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        await response.json().then((data) => {
          const purchaseList = data.purchaseList;
          setPastPurchases(purchaseList);

          if (blockSecondPayment && purchaseList.includes(payment.name)) {
            setStatus("warning");
          } else {
            setStatus("continue");
            setPotentialAddOns(possibleAddOns?.filter((addOn) => !purchaseList.includes(addOn)));
            if (possibleAddOns?.filter((addOn) => !purchaseList.includes(addOn)).length > 0) {
              setStep(1);
            } else if (payment.type === "physical") {
              setStep(2);
            } else {
              setStep(3);
            }
          }
        });
      } catch (error) {
        console.error("Failed to fetch purchases:", error);
        // Handle errors here, such as setting an error state
      }
    }

    checkPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <div style={{ textAlign: "center", height: 415, position: "relative" }}>
      {status === "warning" && (
        <>
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <BackButton handleClick={() => history.goBack()} />
          </div>
          <div style={{ textAlign: "center", paddingTop: "3em" }}>
            <Email sx={{ fontSize: 90 }} />
            <div style={{ height: "2em" }} />
            <p>
              There is already a purchase of <strong>{payment.product}</strong> associated with the email address{" "}
              <strong>{email}</strong>. Please double check your inbox (including <em>spam</em> and <em>promotions</em>{" "}
              folders).
            </p>
            <br />
            <p>
              For additional support, please contact{" "}
              <a href="mailto:support@datastrategypros.com">support@datastrategypros.com</a>.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckPurchases;
