import { useHistory } from "react-router-dom";
import ByLine from "./ByLine";

const NewPostCard: React.VFC<{ post: Post }> = ({ post }) => {
  const history = useHistory();

  return (
    <div
      className="card"
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        cursor: "pointer",
      }}
      onClick={() => history.push(post.link)}
    >
      <img
        key={post.nickname}
        src={post.image.img}
        alt={post.image.alt}
        style={{
          margin: 0,
          padding: 15,
          height: 230,
          width: "100%",
          objectFit: "cover",
        }}
        onClick={() => history.push(post.link)}
      />
      <div style={{ padding: 15 }}>
        <p className="title card-title" style={{ marginBottom: "0" }}>
          {post.title}
        </p>
        <ByLine author={post.author} postDate={post.postDate} updateDate={post.updateDate} resourcesPage />
        <div style={{ marginTop: 10, lineHeight: 1.25 }}>
          <span
            dangerouslySetInnerHTML={{
              __html: post.text || post.subtitle,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewPostCard;
