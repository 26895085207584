import { Link } from "react-router-dom";

import Post from "../components/blog/Post";

import wef from "../img/blog/jobs.webp";

import PostImage from "../components/blog/PostImage";
import Contents from "../components/blog/Contents";

const POSTNAME = "projections";

const Projections: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default Projections;

const Rest = () => {
  const contents: Content[] = [
    {
      anchor: "impact",
      text: "Impact of New Technologies",
    },
    {
      anchor: "projections",
      text: "Projections",
    },
    {
      anchor: "complexities",
      text: "Complexities",
    },
    {
      anchor: "conclusion",
      text: "Conclusion",
    },
  ];

  return (
    <>
      <p>
        The past year has seen an explosion in the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.theguardian.com/technology/2023/feb/02/chatgpt-100-million-users-open-ai-fastest-growing-app"
        >
          use
        </a>{" "}
        and{" "}
        <a target="_blank" rel="noreferrer" href="https://openai.com/research/gpt-4">
          capabilities
        </a>{" "}
        of AI, especially Large Language Models (LLMs) such as OpenAI's{" "}
        <a target="_blank" rel="noreferrer" href="https://openai.com/research/gpt-4">
          GPT-4
        </a>
        , Anthropic's{" "}
        <a target="_blank" rel="noreferrer" href="https://www.anthropic.com/index/introducing-claude">
          Claude
        </a>
        , Google's{" "}
        <a target="_blank" rel="noreferrer" href="https://bard.google.com/">
          Bard
        </a>
        , and Facebook's{" "}
        <a target="_blank" rel="noreferrer" href="https://about.fb.com/news/2023/07/llama-2/">
          Llama-2
        </a>
        . In this writeup, we aim to help you understand what the AI gold rush means for the Data Management job market.
      </p>

      <figure>
        <img src={wef} alt="future of jobs report" style={{ width: "100%", marginTop: "20px" }} />
        <figcaption>
          <a
            href="https://www.weforum.org/publications/the-future-of-jobs-report-2023/"
            target="_blank"
            rel="noreferrer"
          >
            World Economic Forum, Future of Jobs Report, 2023
          </a>
        </figcaption>
      </figure>

      <Contents contents={contents} />

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        AI capabilities are becoming more impressive with each new release, so it's worth considering:{" "}
        <strong>how replaceable is the work of Data Management practitioners?</strong> Right now, AI tools are more an
        asset than a threat to Data Management practitioners' job security; it seems like AI is{" "}
        <strong>strengthening the demand for Data Strategy work</strong>. One industry observer notes:
        <div className="teal-box blog" style={{ maxWidth: "1000px" }}>
          <p>
            Companies are doubling down or investing in Data Quality, MDM, and Data Integration… I was blown away by how
            many times I heard "we're just beginning our journey on… Data Management."
          </p>
          <p style={{ textAlign: "right", marginTop: "1em", marginBottom: "0px" }}>
            – <strong>Malcolm Hawker</strong>,
            <br />
            Head of Data Strategy, Profisee (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=AUVrKairMus&amp;list=PL9muY8gev9NM9nKpJ-jHPcg85b_8QOcbb&amp;index=39"
            >
              source
            </a>
            )
          </p>
        </div>
      </p>

      <p>Here's a list of the ways AI supports various Data Management roles:</p>
      <ul>
        <li>
          <strong>Data Governance</strong> –{" "}
          <a target="_blank" rel="noreferrer" href="https://atlan.com/ai/?ref=/what-is-data-governance/">
            automatically draft documentation
          </a>{" "}
          for business terms
        </li>
        <li>
          <strong>Data Architecture</strong> –{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://atlan.com/ai-data-governance/#how-can-ai-help-in-shifting-data-governance-to-the-left"
          >
            data asset discovery
          </a>{" "}
          with complete context
        </li>
        <li>
          <strong>Metadata Management</strong> – anomaly detection based on{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://atlan.com/ai-data-governance/#how-can-ai-help-in-shifting-data-governance-to-the-left"
          >
            automated data lineage
          </a>
        </li>
        <li>
          <strong>Data Modeling</strong> –{" "}
          <a target="_blank" rel="noreferrer" href="https://mostly.ai/">
            generate synthetic
          </a>{" "}
          data for training, testing and validating models when actual data is limited, sensitive, or biased
        </li>
        <li>
          <strong>Data Quality</strong> –{" "}
          <a target="_blank" rel="noreferrer" href="https://www.refuel.ai/blog-posts/llm-labeling-technical-report">
            data labeling
          </a>{" "}
          at or above a human level of accuracy (with much greater efficiency)
        </li>
        <li>
          <strong>Data Science</strong> –{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/pulse/gpt-4-effect-data-strategies-mark-ramsey-ph-d-/"
          >
            summarizing or extracting
          </a>{" "}
          structured data from unstructured sources
        </li>
      </ul>
      <p>
        There are still significant hurdles that limit AI's ability to perform a Data Management role in full.{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://en.wikipedia.org/wiki/Hallucination_(artificial_intelligence)"
        >
          Hallucinations
        </a>{" "}
        threaten the reliable accuracy of data. Limited{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://autogpt.net/auto-gpt-understanding-its-constraints-and-limitations/"
        >
          strategic planning and autonomy
        </a>{" "}
        undermine the ability to make decisions and manage data across its lifecycle. There is a sizable{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.salesforce.com/news/stories/customer-engagement-research-2023/"
        >
          trust gap
        </a>{" "}
        between AI adoption and the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf"
        >
          <strong>60% of people</strong>
        </a>
        <strong> who feel current safeguards are insufficient</strong>.
      </p>

      <p>
        Given{" "}
        <a target="_blank" rel="noreferrer" href="https://epochai.org/trends">
          trends
        </a>{" "}
        in the advancement of AI capabilities, it's possible we're only one or two GPT releases away from machine
        learning tools that can overcome these barriers. Studies by the AI Policy Institute (
        <a target="_blank" rel="noreferrer" href="https://theaipi.org/ai-interactive-map/">
          AIPI
        </a>
        ) and{" "}
        <a target="_blank" rel="noreferrer" href="https://arxiv.org/abs/2303.10130">
          OpenAI
        </a>{" "}
        indicate that around <strong>20% of current US jobs and tasks</strong> could be "significantly exposed to AI
        automation in the near future." AI increasingly demonstrates the capacity to generate high-quality outputs
        across various domains.
      </p>
      <p>
        Yet even if AI can support or take on more and more Data Management tasks in the long term, the near-term demand
        for data practitioners is fueled by the increasing volume and significance of data as a source of value for the
        business.
      </p>

      <PostImage postName={POSTNAME} />

      <h2 id={contents[1].anchor}>{contents[1].text}</h2>
      <p>
        As we enter 2024, it's clear that the{" "}
        <strong>services of data-related professions are very much still in demand</strong>. The{" "}
        <a target="_blank" rel="noreferrer" href="https://www.weforum.org/publications/the-future-of-jobs-report-2023/">
          Future of Jobs Report 2023
        </a>{" "}
        from the World Economic Forum predicts a{" "}
        <strong>40% increase in the number of AI and machine learning specialists by 2027</strong>, a 30-35% rise in
        demand for roles like Data Analysts and Data Scientists, and a 31% increase in demand for Data Security
        professionals. Across these roles, the net growth totals <strong>2.6 million jobs</strong>.
      </p>

      <p>
        Over the next decade (2022-32), the{" "}
        <a href="https://www.bls.gov/" target="_blank" rel="noreferrer">
          US Bureau of Labor Statistics
        </a>{" "}
        estimates that:
      </p>

      <ul>
        <li>
          The profession of{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bls.gov/ooh/computer-and-information-technology/database-administrators.htm"
          >
            Database Administrators and Architects
          </a>{" "}
          will grow 8% (faster than average),
        </li>
        <li>
          The profession of{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bls.gov/ooh/management/computer-and-information-systems-managers.htm"
          >
            <strong>Computer and Information Systems Managers</strong>
          </a>{" "}
          will grow 15% (much faster than average), and
        </li>
        <li>
          The profession of{" "}
          <a target="_blank" rel="noreferrer" href="https://www.bls.gov/ooh/math/data-scientists.htm">
            Data Scientists
          </a>{" "}
          will grow 35% (much faster than average).
        </li>
      </ul>

      <h2 id={contents[2].anchor}>{contents[2].text}</h2>
      <p>
        Predictions about the rate and precise direction of AI capability development are highly uncertain. There are
        significant limitations in what job market projections can reliably tell us about the future of data-related
        jobs.
      </p>
      <p>
        The{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://assets.publishing.service.gov.uk/media/653bc393d10f3500139a6ac5/future-risks-of-frontier-ai-annex-a.pdf"
        >
          UK's Government Office for Science
        </a>{" "}
        highlights how meaningful gaps in{" "}
        <a target="_blank" href="https://www.interpretable.ai/interpretability/what/" rel="noreferrer">
          model interpretability
        </a>{" "}
        and our understanding of{" "}
        <a target="_blank" href="https://hai.stanford.edu/news/ai-benchmarks-hit-saturation" rel="noreferrer">
          how to measure progress
        </a>{" "}
        in artificial intelligence make it difficult to monitor the potential capabilities and impacts of new models.{" "}
        <a target="_blank" href="https://www.anthropic.com/" rel="noreferrer">
          <strong>Anthropic</strong>
        </a>
        , the creators of{" "}
        <a target="_blank" href="https://www.anthropic.com/index/introducing-claude" rel="noreferrer">
          <strong>Claude</strong>
        </a>
        , further highlight the added difficulty in predicting "qualitatively different, specific capabilities [which]
        can{" "}
        <a target="_blank" href="https://arxiv.org/pdf/2202.07785.pdf" rel="noreferrer">
          <strong>appear abruptly and discontinuously</strong>
        </a>
        ."
      </p>
      <p>
        It's possible the labor market projections we have discussed so far do not fully account for the impact AI is
        currently having on jobs that heavily involve{" "}
        <a target="_blank" rel="noreferrer" href="https://arxiv.org/pdf/2303.10130.pdf">
          writing and programming
        </a>
        . Already, <strong>generative AI is significantly impacting ways of working</strong> for data strategists,
        analysts, and engineers.
      </p>
      <p>
        Another limitation is that there seems to be a strong assumption at both{" "}
        <a href="https://www.weforum.org/publications/the-future-of-jobs-report-2023/" target="_blank" rel="noreferrer">
          World Economic Forum
        </a>{" "}
        and{" "}
        <a target="_blank" rel="noreferrer" href="https://www.bls.gov/ooh/fastest-growing.htm">
          US Bureau of Labor Statistics
        </a>{" "}
        that increases in demand for labor directly correspond to increases in the total amount of new human labor,
        which may not hold true over the long term.
      </p>

      <p>
        However, just because AI can perform an increasing number of different tasks doesn't mean that the job market
        for data practitioners won't simultaneously grow. We may see increased demand for roles that require human
        judgment, communication, and soft skills, or entirely new opportunities to work with data may spring up.
      </p>
      <p>
        Data Management practitioners can adapt to the dynamic job market by{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank" rel="noreferrer">
          studying best practices
        </Link>
        ,{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.iiba.org/business-analysis-blogs/how-ai-is-rewriting-the-rules-of-data-analysis/"
        >
          upskilling
        </a>
        , and{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/pulse/your-job-safe-from-ai-discover-how-adapt-yanyan-wang/"
        >
          staying aware
        </a>{" "}
        of developments in AI.
      </p>

      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <p>
        It's safe to say that the impact of generative AI is not just disruptive, but transformative. In the near term,
        AI creates new opportunities and productivity enhancements across activities such as drafting documents,
        conducting research, performing analytics, creating images, and planning.
      </p>
      <p>
        Despite the advancements in AI and its growing capability to assume more complex tasks, the demand for skilled
        Data Management professionals remains strong in 2024. The need for data practitioners is fueled by the
        increasing demand for{" "}
        <Link to="/resources/data-driven" target="_blank">
          data-driven decision-making
        </Link>
        , coupled with the understanding that Data Management is a critical foundation for other initiatives that
        require the use of data.
      </p>
      <p>
        The projections from the{" "}
        <a href="https://www.weforum.org/publications/the-future-of-jobs-report-2023/" target="_blank" rel="noreferrer">
          World Economic Forum
        </a>{" "}
        and the{" "}
        <a target="_blank" rel="noreferrer" href="https://www.bls.gov/ooh/fastest-growing.htm">
          US Bureau of Labor Statistics
        </a>{" "}
        depict a future where data-related roles continue to expand. The core tasks of a Data Management professional
        require qualities such as judgment, emotional intelligence, and the ability to take ethical considerations into
        account with dealing with sensitive data assets. The future of Data Management will entail learning new things
        and using the tools at our disposal to make the best use of our organizations' data assets.
      </p>
    </>
  );
};
