import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { simplifyDate, useScrollToLocation } from "../../helpers/utils";

const ByLine: React.FC<{
  link?: string;
  author: PostAuthor[];
  postDate: Date;
  updateDate: Date;
  resourcesPage?: boolean;
}> = ({ link = "", author, postDate, updateDate, resourcesPage = false }) => {
  const scroll = useScrollToLocation();

  const [value, setValue] = useState("");

  useEffect(() => {
    return scroll;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrolledRef = useRef(false);

  useEffect(() => {
    if (value) {
      const element = document.getElementById(value);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        scrolledRef.current = true;
      }
    }
    scrolledRef.current = false;
  }, [value]);

  return (
    <>
      {author && (
        <p
          style={{
            fontSize: "0.95em",
            marginTop: resourcesPage ? 2 : -12,
            marginBottom: resourcesPage ? 10 : "1.5em",
            lineHeight: 1.1,
          }}
        >
          <span style={{ fontVariantCaps: "small-caps", fontWeight: 900 }}>
            Author:<span>&nbsp;</span>
          </span>{" "}
          {resourcesPage ? (
            author.length === 1 ? (
              author[0]
            ) : (
              "Data Strategy Professionals team"
            )
          ) : link ? (
            <a href={link} target="_blank" rel="noreferrer" style={{ fontWeight: 400 }}>
              {author}
            </a>
          ) : (
            <Link to="/about/team" style={{ fontWeight: 400 }}>
              {author.length === 1 ? author[0] : "Data Strategy Professionals team"}
            </Link>
          )}{" "}
          <span>&nbsp;</span>|<span>&nbsp;</span>{" "}
          {!resourcesPage && (
            <>
              <span style={{ fontVariantCaps: "small-caps", fontWeight: 900 }}>Post Date:</span>
              <span>&nbsp;</span> {simplifyDate(postDate)} <span>&nbsp;</span>|<span>&nbsp;</span>{" "}
            </>
          )}
          <span style={{ fontVariantCaps: "small-caps", fontWeight: 900 }}>Last Update:</span>
          <span>&nbsp;</span> {simplifyDate(updateDate)}
          {!resourcesPage && (
            <>
              {" "}
              <span>&nbsp;</span>|<span>&nbsp;</span>{" "}
              <span
                className="fake-link"
                style={{ fontVariantCaps: "small-caps", fontWeight: 900 }}
                onClick={() => setValue("related-posts")}
              >
                Related Posts
              </span>
            </>
          )}
        </p>
      )}
    </>
  );
};

export default ByLine;
