import { Helmet } from "react-helmet";

const CustomHelmet: React.FC<{
  description: string;
  name: string;
  link: string;
  img: string;
  alt: string;
  author: string;
  canonical: string;
}> = ({ description, name, link, img, alt, author = "Data Strategy Professionals", canonical = false }) => {
  return (
    <Helmet>
      <title>{name}</title>
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      <meta property="og:title" property="og:description" content={name} />
      {/* OpenGraph tags */}
      <meta name="og:url" content={"https://www.datastrategypros.com" + link} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={img} />
      <meta name="og:image:alt" content={alt} />
      <meta name="og:type" content="website" />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={name} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={img} />
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default CustomHelmet;
