import Card from "../Card";
import Bills from "../../img/people/nicole_bills.webp";
import Saif from "../../img/people/saif_sheikh.webp";
import Carlos from "../../img/people/carlos_navarro.webp";
import John from "../../img/people/john_odonovan.webp";

const team: TeamMember[] = [
  {
    id: "Saif",
    img: Saif,
    name: "Saif Sheikh",
    // linkedin: "https://www.linkedin.com/in/sef-sheikh/",
    title: "Career Coach — Data Governance & Metadata Management",
    content: `Saif brings a thoughtful and energetic perspective to Career Coaching to help you overcome your doubts and achieve your goals.
      His advice is backed up by considerable experience working in Data Governance and studying Technology Management, Information Systems, and Business.
      He can help you develop an analytical problem solving approach in order to figure out the next step in your career.
      He's truly a pro when it comes to evaluating challenges from multiple perspectives and developing a research strategy to address challenges.
      He is recognized by DAMA as a <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>Certified Data Management Professional (CDMP)</a>.`,
  },
  {
    id: "Nicole",
    img: Bills,
    name: "Nicole Janeway Bills",
    linkedin: "https://www.linkedin.com/in/nicole-janeway-bills/",
    title: "Career Coach – Data Analytics & Data Science",
    content: `Nicole offers a proven track record of applying Data Strategy and related disciplines to solve clients' most pressing challenges.
      She has worked as a Data Scientist and Project Manager for federal and commercial consulting teams and has written 35+
      <a href='https://medium.com/@nicolejaneway' target='_blank' rel='noreferrer'>Medium articles</a> along the way.
      Her business experience includes natural language processing, cloud computing, statistical testing, pricing analysis, ETL processes, and web and application development. 
      She attained recognition from DAMA for a Master Level pass of the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.`,
  },
  {
    id: "Carlos",
    img: Carlos,
    name: "Carlos Navarro",
    linkedin: "https://www.linkedin.com/in/carlosnavarrocrossman/",
    title: "Career Coach – Data Governance & Executive Leadership",
    content: `With over 20 years of extensive experience, Carlos is recognized for his proficiency in designing, constructing,
    and enhancing data practices within diverse realms such as data strategy, management, and visualization, with a specialized
    focus on leading cloud data platforms. He is recognized by DAMA as a 
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>Certified Data Management Professional (CDMP)</a>.`,
  },
  {
    id: "John",
    img: John,
    name: "John O'Donovan",
    linkedin: "https://www.linkedin.com/in/johnpodonovan/",
    title: "Career Coach – Data Governance & Executive Leadership",
    content: `John is a Data Management professional with global experience leading end-to-end solution deployments,
    data governance, data strategy, and technology integration initiatives across various industries.
    He has led data-driven decision implementations with companies such as The Home Depot, Macy's, Procter & Gamble, Teradata, and GE-Plastics.
    He is recognized by DAMA for a Master-level passing score of the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.`,
  },
];

const styles = {
  box: { background: "#f9f9f9" },
  title: {
    fontSize: "1.2em",
    marginBottom: ".5em",
  },
};

const Coaches: React.FC<{ tutoring?: boolean }> = ({ tutoring = false }) => {
  return (
    <div className="white-box" style={styles.box}>
      <h4 style={styles.title}>
        Meet our <span>{tutoring ? "CDMP Tutors" : "Career Coaches"}</span>
      </h4>
      <Card person={team.find((person) => person.id === "Saif")} showLinkedIn={true} showTitle={!tutoring} />
      <Card person={team.find((person) => person.id === "Carlos")} showLinkedIn={true} showTitle={!tutoring} />
      <Card person={team.find((person) => person.id === "John")} showLinkedIn={true} showTitle={!tutoring} />
    </div>
  );
};

const Instructors: React.FC<{ instructors: Coaches[] }> = ({ instructors }) => {
  return (
    <div className="white-box" style={styles.box}>
      <h4 style={styles.title}>
        Meet your <span>Instructor{instructors.length > 1 && <span>s</span>}</span>
      </h4>
      {instructors.map((instructor) => (
        <Card person={team.find((person) => person.id === instructor)} showLinkedIn={true} showTitle={false} />
      ))}
    </div>
  );
};

export { Coaches, Instructors };
