import Carousel from "react-multi-carousel";
import { useMediaQuery } from "@mui/material";

import { relatedPostsCarousel } from "../../../helpers/carouselStyles";

import RelatedPostsCard from "./RelatedPostsCard";
import CustomButtons from "../../carousel/CustomButtons";

import posts from "../../../data/posts";

const RelatedPostsCarousel: React.VFC<{ postNicknames: string[] }> = ({ postNicknames }) => {
  const desktop = useMediaQuery("(min-width: 1224px)");

  const relatedPosts = [];

  postNicknames.forEach((post) => {
    relatedPosts.push(posts.find((p) => p.nickname === post));
  });

  return (
    <div
      className="carousel-container"
      style={{
        maxWidth: 1016,
        width: desktop ? 1014 : "calc(100% + 16px)",
        margin: "20px 0px",
        // border: "1px solid red",
        position: "relative",
        left: -8,
      }}
      id="related-posts"
    >
      <Carousel
        responsive={relatedPostsCarousel}
        swipeable={true}
        draggable={true}
        ssr={true}
        keyBoardControl={true}
        infinite={true}
        arrows={false}
        renderButtonGroupOutside={true}
        // @ts-ignore
        customButtonGroup={<CustomButtons />}
      >
        {relatedPosts.map((post, idx) => {
          return (
            <div style={{ margin: "0 8px", height: "100%" }} key={idx}>
              <RelatedPostsCard post={post} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default RelatedPostsCarousel;
