import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import CustomHelmet from "../../components/CustomHelmet";
import ByLine from "../../components/blog/ByLine";
import BestPracticeHeader from "./BestPracticeHeader";
import PostAuthorCard from "../../components/blog/PostAuthorCard";

import posts from "../../data/posts";
import { bestPractices, colors } from "../../data/bestPractices";

import { createRows } from "../../helpers/utils";

const post = posts.find((post) => post.nickname === "best-practices");

export default function BestPractices() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = createRows(bestPractices);

  return (
    <>
      <CustomHelmet
        description={post.subtitle}
        name={post.title}
        link={post.link}
        img={post.previewImage}
        alt={post.alt}
      />
      <div className="home-layout">
        <div className="blog">
          <h1>{post.title}</h1>
          <h3>{post.subtitle}</h3>
          <ByLine author={post.author} postDate={post.postDate} updateDate={post.updateDate} />
          <p>
            Data-driven decision-making has increasingly defined organizational strategy over the last decades. These
            best practices will help your organization use data to capitalize on comparative advantage.
          </p>

          <figure>
            <img src={post.image} alt={post.alt} />
          </figure>
          <h4>Contents</h4>
          <ul>
            <li>
              <a href="#objective">Objective</a>
            </li>
            <li>
              <a href="#best-practices">Best Practices</a>
            </li>
            <li>
              <a href="#additional-detail">Additional Detail</a>
            </li>
            <li>
              <a href="#methodology">Methodology</a>
            </li>
            <li>
              <a href="#limitations">Limitations</a>
            </li>
            <li>
              <a href="#next-steps">Next Steps</a>
            </li>
            <li>
              <a href="#conclusion">Conclusion</a>
            </li>
          </ul>
          <h2 id="objective">Objective</h2>
          <p>
            We've read many articles across a range of sources to collect this list of best practices from leading
            organizations and individuals working in Data Management. This post aims to identify and rank the most
            important Data Strategy best practices.
          </p>
          <h2 id="best-practices">Best Practices</h2>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#dee8e9" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 900, minWidth: 180 }}>Key Theme</TableCell>
                  <TableCell sx={{ fontWeight: 900 }}>Best Practice</TableCell>
                  <TableCell sx={{ fontWeight: 900 }}>Sources</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow
                      key={row.nickname}
                      sx={{
                        "&:nth-of-type(odd)": {
                          backgroundColor: "#efefef",
                        },
                      }}
                    >
                      <TableCell>
                        <span
                          style={{
                            backgroundColor: colors.find((item) => item.topic === row.topic).color,
                            borderRadius: "16px",
                            padding: "4px 8px",
                          }}
                        >
                          {row.topic}
                        </span>
                      </TableCell>
                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          fontSize: "15px",
                        }}
                      >
                        <p>
                          <span style={{ fontWeight: 900 }} dangerouslySetInnerHTML={{ __html: row.bestPractice }} />:
                          &nbsp;
                          <span dangerouslySetInnerHTML={{ __html: row.description }} />
                        </p>
                      </TableCell>
                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          fontSize: "15px",
                        }}
                      >
                        <p dangerouslySetInnerHTML={{ __html: row.sources }} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <h2 id="additional-detail">Additional Detail</h2>
          <BestPracticeHeader idx={0} />
          <p>
            Everything that we do as data practitioners should be in service of the business goals of our organization.
            There's no sense improving data quality or enhancing metadata for its own sake. Arguably, data only has
            value when used. Therefore, it's crucial to ensure that Data Strategy is aligned with Business Strategy.
          </p>
          <br />
          <BestPracticeHeader idx={1} />
          <p>
            Change management is a very important component of technological change that should be accounted for and
            acted upon from the very beginning of a Data Management project.{" "}
            <a href="https://www.anythingawesome.com/" target="_blank" rel="noreferrer">
              Peter Aiken
            </a>{" "}
            estimates that for every such project, there is a 1:4 ratio of technology spend to resources required for
            people, process, and culture changes. You may be interested in reading{" "}
            <Link to="/resources/data-driven-decision-making" target="_blank">
              this article
            </Link>{" "}
            about how to foster a data-driven culture.
          </p>
          <br />
          <BestPracticeHeader idx={2} />
          <p>
            Although Data Security is typically handled by a dedicated function, a Data Management practitioner should
            be aware of{" "}
            <a
              href="https://owasp.org/www-project-secure-coding-practices-quick-reference-guide/stable-en/"
              target="_blank"
              rel="noreferrer"
            >
              current best practices
            </a>{" "}
            in order to incorporate them into plans, policies, and standards.
          </p>
          <br />
          <BestPracticeHeader idx={3} />
          <p>
            Leadership buy-in is a key input to the success of a Data Management initiative. Consider strategies such as
            newsletters and Teams/Slack posts to communicate progress. Throw a party to celebrate a big win. You may
            want to bring key stakeholders and data leaders together at a regular cadence in order to share best
            practices and seek input for crucial decisions.
          </p>
          <br />
          <BestPracticeHeader idx={4} />
          <p>
            Good documentation is the foundation of an effective Data Management program. Supporting the work of data
            teams with standards and policies helps to promote consistency, sustainability, and reliability. Check out
            our <Link to="/products/document-checklist">Document Checklist</Link> for a comprehensive list of documents
            that you should consider developing in order to enhance your Data Management capabilities across all
            functions.
          </p>
          <br />
          <BestPracticeHeader idx={5} />
          <p>
            Improving the Data Management capabilities of your organization requires the development of a skilled
            workforce. As stated in the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            , "the best data stewards are found, not made."{" "}
            <Link to="/resources/data-steward" target="_blank">
              Data stewards
            </Link>{" "}
            are typically identified by interacting with data producers and consumers and noticing who is excited about
            working with the data, who has a good attention to detail and other skills required for Data Management.{" "}
            <Link to="/enterprise">Team training</Link> can play an important role in fostering a skilled and
            knowledgeable Data Management workforce.
          </p>
          <br />
          <BestPracticeHeader idx={6} />
          <p>
            <Link to="/resources/foundations-of-data-strategy#data-architecture" target="_blank">
              Data Architecture
            </Link>{" "}
            plays an important role in helping an organization to understand what data assets it has and how these data
            assets are structured. An Enterprise Data Model (EDM) provides a high-level view of data across an
            organization. A data flow diagram demonstrates how data is used and how it flows through the organization.
          </p>
          <br />
          <BestPracticeHeader idx={7} />
          <p>
            A{" "}
            <Link to="/resources/data-management-maturity-assessment-dmma" target="_blank">
              DMMA
            </Link>{" "}
            is a very important input into the overall understanding of Data Management capabilities. It can help your
            organization evaluate the realized, probable, and potential value of data capabilities.
          </p>
          <br />
          <BestPracticeHeader idx={8} />
          <p>
            Creating a Data Strategy roadmap is a useful way to guide current and future projects so they are more
            closely aligned with the objectives of the business. To learn about the Data Strategy roadmap and other
            supporting documents, check out our{" "}
            <Link to="/products/document-checklist">Data Strategy Document Checklist</Link>.
          </p>
          <br />
          <BestPracticeHeader idx={9} />
          <p>
            Data lakes are a cost-effective and scalable way to store and manage large volumes of data of different
            formats. Unlike{" "}
            <Link to="/resources/warehouse" target="_blank">
              traditional Data Warehousing
            </Link>
            , which requires data to be cleansed and structured beforehand, data lakes allow organizations to store data
            without extensive preprocessing. This characteristic of data lakes is useful for an organization that aims
            to ingest large amounts of data and use it for{" "}
            <Link to="/resources/data-science-for-leaders" target="_blank">
              advanced analytics
            </Link>
            .
          </p>
          <br />
          <BestPracticeHeader idx={10} />
          <p>
            Using key performance indicators (KPIs) is an important step toward a successful Data Management program. A
            metrics scorecard can help ensure that data is fit for purpose across the organization by making sure that
            data is accurate, complete, and up-to-date. Continuously monitoring and regularly reporting on KPIs can help
            to ensure that the organization's data is being used effectively.
          </p>
          <br />
          <BestPracticeHeader idx={11} />
          <p>
            <Link to="/resources/data-science-for-leaders" target="_blank">
              Machine learning
            </Link>{" "}
            has many uses that can benefit Data Management practitioners. For example, machine learning can be used to
            automate data processing tasks, generate a first draft of Data Governance documents, serve as a copilot to
            data engineers, predict the likelihood of data-related risks, provide structure to semi-structured
            information, suggest sensitivity classifications for datasets, identify duplicate data assets that should be
            deleted, summarize logs and produce actionable recommendations, perform Data Quality checks with minimal
            human intervention, and identify patterns in a dataset.
          </p>

          <h2 id="#methodology">Methodology</h2>
          <p>
            We explored an initial list of 27 articles based on keyword search. Not all sources or best practices were
            determined to be relevant to our research.
          </p>
          <p>
            An article was listed as a source only if it explicitly referenced a given best practice. However, many of
            the same best practices were explained in different ways across different articles.
          </p>
          <p>
            A total of twelve best practices were included in the final selection. Four key themes (Strategy, People,
            Process, and Technology) were then identified to categorize the best practices. The themes that we used to
            categorize these best practices are reflective of our opinions only and may not reflect the opinions of the
            author.
          </p>
          <h2 id="#limitations">Limitations</h2>
          <ul>
            <li>This list of best practices is not exhaustive</li>
            <li>
              Sources have not been assessed for their degree of independence (i.e., the extent to which to best
              practices were identified by one source without referencing other sources)
            </li>
            <li>
              Given the pace of advancement, it is important to consider the current relevance of best practices,
              especially those within the Technology theme
            </li>
          </ul>
          <h2 id="next-steps">Next Steps</h2>
          <p>In order to integrate these best practices at your organization, we recommend the following:</p>
          <ul>
            <li>Investigate how best practices interact with industry standards</li>
            <li>
              Take into account your organization's level of{" "}
              <Link to="/resources/data-management-maturity-assessment-dmma">Data Maturity</Link> when considering which
              best practices to implement
            </li>
            <li>
              Consult data leaders to better understand the potential impact on your organization's Data Management
              practice
            </li>
            <li>Conduct in-depth literature reviews for each of the most promising best practices</li>
            <li>Evaluate best practices in light of recent technological developments</li>
          </ul>
          <h2 id="#conclusions">Conclusion</h2>
          <p>
            This report is a starting point for understanding the best practices in Data Strategy. We hope that this
            list will help you to make informed decisions about how to effectively implement Data Management in your
            organization.
          </p>
          <p>
            For more help with Data Strategy best practices, we recommend reading this article on the{" "}
            <Link to="/resources/foundations-of-data-strategy" target="_blank">
              Foundations of Data Strategy
            </Link>{" "}
            and checking out our <Link to="/products/data-strategy-workbook">Data Strategy Workbook</Link> and{" "}
            <Link to="/products/document-checklist">Document Checklist</Link>.
          </p>
        </div>
        <PostAuthorCard postAuthor="Mac" />
        <PostAuthorCard postAuthor="Nicole" />
      </div>
    </>
  );
}
